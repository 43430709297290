import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IScore, SecButton, useParams, useScores } from '../../../../shared';
import './ScoreDetails.scss'

export const ScoreDetails = () => {
    const navigate = useNavigate();
    const {gameid} = useParams();
    const [searchParams] = useSearchParams();
    const {getScoresByGameIdAsync} = useScores();
    const goBack = () => navigate(-1);
    const [scores, setScores] = useState<IScore[]>([]);


    const mappedScores = useMemo(() => {
        const mapped = scores.map(score =>  <div key={score.id} >{score.teamName}</div>)
        mapped.splice(1, 0, <div key={1} className='vs'>vs</div>)
        return mapped;
    }, [scores])


    useEffect(() => {
        (async function() {
            if(searchParams) {
                console.log(searchParams);
            } else if(gameid) {
                const res = await getScoresByGameIdAsync(gameid);
                if(res.success) {
                    setScores(res.data as IScore[])
                }
            };
        }())
    }, [])

    
    return (
        <div className='score-details'>
            <div className='btn-wrapper'>
                <SecButton label='Go Back' clicked={goBack} />
            </div>
            {/* {scores.map((score, idx) => {
                return (
                    <Score key={idx} score={score} />
                )
            })} */}
            <h1>This is the score details page</h1>
            <div className='mapped-scores'>
                {mappedScores}
            </div>
            <div>
                {/* {getDayTimeStringFromTimestamp(scores[0].createdTime)} */}
                {/* {scores[0] && dateToTimeAgo(convertTimestampToDateTime(scores[0].createdTime))} */}
            </div>
        </div>
    );
}


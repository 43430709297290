// import { Route, Routes as RRDRoutes } from "react-router-dom"
import { AppRoutes, Route } from "../RoutingModules"
import { lazy, Suspense } from "react"
import { urls } from "../../menus";

import {Landing} from "../../../landing";
import { Layout } from "../../../Entry";
import { RequireAuth } from "./RequireAuth";
import { UnAuthOnlyRoute } from "./UnAuthOnlyRoute";
import { Square } from "../../../../square";
import { RequireData } from "./RequireData";
import { Addresses, ForgotPassword, ResetPassword, SignIn } from "../../../pages";
import { userRoles } from "../../../context";
import { DefaultSpinner } from "../../components";

function getComponent(comp: string) {
    return lazy(() => import('../../../pages').then((x:any) => {
        return  {default: x[comp]};
    }))
}

const Player = getComponent('Player');
const Dashboard = getComponent('Dashboard');
const PlayerScoreBoard = getComponent('PlayerScoreBoard');
const PlayerLeaderBoard = getComponent('PlayerLeaderBoard');
const Teams = getComponent('Teams');
const Players = getComponent('Players');
const Games = getComponent('Games');
const Standings = getComponent('Standings');

const GameRefereeing = getComponent('GameRefereeing');
const PlayerEdit = getComponent('PlayerEdit');



const TournamentPaid = getComponent('TournamentPaid');
const TournamentPayment = getComponent('TournamentPayment');


// const SignIn = getComponent('SignIn');
// const ForgotPassword = getComponent('ForgotPassword');
// const ResetPassword = getComponent('ResetPassword');
const UnAuthorized = getComponent('UnAuthorized');
const Redirection = getComponent('Redirection');
const PageNotFound = getComponent('PageNotFound');


const Profile = getComponent('Profile');
const ChangePasswordForm = getComponent('ChangePasswordForm');
const UserUpdateForm = getComponent('UserUpdateForm');
const UserEmergencyForm = getComponent('UserEmergencyForm');
const ChangeProfilePic = getComponent('ChangeProfilePic');
const DeleteAccount = getComponent('DeleteAccount');



const ScoreDetails = getComponent('ScoreDetails');
// const AdminLayout = getComponent('AdminLayout');
const Admin = getComponent('Admin');
const AdminDashboard = getComponent('AdminDashboard');
const AdminStandings = getComponent('AdminStandings');
const AdminScoreBoard = getComponent('AdminScoreBoard');
const AdminLeaderBoard = getComponent('AdminLeaderBoard');
const AdminScoreBoardMgt = getComponent('AdminScoreBoardMgt'); 
const Tournaments = getComponent('Tournaments');
const CreateTournament = getComponent('CreateTournament');
const AdminTeams = getComponent('AdminTeams');
const AdminGames = getComponent('AdminGames');
const RefToGame = getComponent('RefToGame');
const AdminCreateGame = getComponent('AdminCreateGame');
const GameRescheduling = getComponent('GameRescheduling');
const Referees = getComponent('Referees');
const CreateTeam = getComponent('CreateTeam');


const Referee = getComponent('Referee');
const RefDashboard = getComponent('RefDashboard');
const RefGames = getComponent('RefGames');


const PlayerRegistration = getComponent('PlayerRegistration');
const PlayerPasscode = getComponent('PlayerPasscode');
const PlayerWaiver = getComponent('PlayerWaiver');
const PlayerInfo = getComponent('FakePlayerInfo');
const PlayerPayment = getComponent('FakePlayerPayment');
const PlayerPicture = getComponent('PlayerPicture');


export const Routes = () => {
    
    return (
        <Suspense fallback={<DefaultSpinner />}>
            <AppRoutes>
                <Route path="/" element={<Layout />}>
                    <Route path={'/square'} element={<Square />} />
                    
                    {/* Unauthorized routes  */}
                    <Route element={<UnAuthOnlyRoute />}>
                        <Route path={urls.home} element={<Landing/>} />
                        <Route path={urls.signin} element={<SignIn />} />
                        <Route path={urls.forgotpassword} element={<ForgotPassword />} />
                        <Route path={urls.resetpassword} element={<ResetPassword />} />
                    </Route>

                    
                    {/* Player registration */}
                    <Route element={<RequireData />}>
                        <Route path={urls.playerregistration.base} element={<PlayerRegistration />}>
                            {/* <Route index element={<PlayerPasscode />} /> */}
                            {/* <Route path={urls.playerregistration.passcode} element={<PlayerPasscode />} /> */}
                            <Route index element={<PlayerWaiver />} />
                            <Route path={urls.playerregistration.waiver} element={<PlayerWaiver />} />
                            <Route path={urls.playerregistration.info} element={<PlayerInfo />} />
                            {/* <Route path={urls.playerregistration.payment} element={<PlayerPayment />} />
                            <Route path={urls.playerregistration.picture} element={<PlayerPicture />} /> */}
                        </Route>
                    </Route>
                    
                    
                    {/* Player Routes  */}
                    <Route element={<RequireAuth allowedRoles={[userRoles.player]} />}>
                        <Route path={urls.player.base} element={<Player />}>
                            <Route index element={<Dashboard />} />
                            <Route path={urls.player.dashboard} element={<Dashboard />} />
                            <Route path={urls.player.teams} element={<Teams />} />
                            <Route path={urls.player.games} element={<Games />} />
                            <Route path={urls.player.scoreboard} element={<PlayerScoreBoard />} />
                            <Route path={urls.player.leaderboard} element={<PlayerLeaderBoard />} />
                            <Route path={urls.player.standings} element={<Standings />} />
                        </Route>
                    </Route>


                    {/* Profile routes  */}
                    <Route element={<RequireAuth allowedRoles={[
                            userRoles.player, 
                            userRoles.coach, 
                            userRoles.manager,
                            userRoles.admin,
                            userRoles.ref,
                            userRoles.ar
                        ]} />}>
                        <Route path={urls.tournamentpaid} element={<TournamentPaid />} />
                        <Route path={urls.tournamentpayment} element={<TournamentPayment />} />
                        <Route path={urls.profile.base} element={<Profile />}>
                            <Route index element={<UserUpdateForm />} />
                            <Route path={urls.profile.info} element={<UserUpdateForm />} />
                            <Route path={urls.profile.emergency} element={<UserEmergencyForm />} />
                            {/* <Route path={urls.profile.password} element={<ChangePasswordForm />} /> */}
                            {/* <Route index element={<ChangeProfilePic />} /> */}
                            <Route path={urls.profile.changeprofilepic} element={<ChangeProfilePic />} />
                            {/* <Route path={urls.profile.deleteaccount} element={<DeleteAccount />} /> */}
                        </Route>
                    </Route>



                    {/* Admin Routes  */}
                    <Route element={<RequireAuth allowedRoles={[userRoles.admin]} />}>
                        <Route path={urls.admin.base} element={<Admin />} >
                            <Route index element={<AdminDashboard />} />
                            <Route path={urls.admin.dashboard} element={<AdminDashboard />} />
                            <Route path={urls.admin.teams.base} element={<AdminTeams />} />
                            <Route path={urls.admin.teams.create} element={<CreateTeam />} />


                            <Route path={urls.admin.players} element={<Players />}>
                                <Route path={urls.admin.players + "/:playerid"} element={<PlayerEdit />} />
                            </Route>

                            <Route path={urls.admin.games.base} element={<AdminGames />} />
                            <Route path={urls.admin.games.create} element={<AdminCreateGame />} />
                            <Route path={urls.admin.games.reschedule} element={<GameRescheduling />} />


                            <Route path={urls.admin.games.refs} element={<RefToGame />} />

                            <Route path={urls.admin.referees} element={<Referees />} />


                            <Route path={urls.admin.tournaments.base} element={<Tournaments />} />
                            <Route path={urls.admin.tournaments.create} element={<CreateTournament />} />

                            <Route path={urls.admin.addresses.base} element={<Addresses />} />


                            <Route path={urls.admin.scoremgt} element={<AdminScoreBoardMgt />}>
                                <Route path={urls.admin.updatescore} element={<GameRefereeing />} />
                            </Route>

                            <Route path={urls.admin.scoreboard} element={<AdminScoreBoard />}>
                                <Route path={urls.admin.scoreboardDetails} element={<ScoreDetails />} />
                            </Route>

                            <Route path={urls.admin.standings} element={<AdminStandings />} />
                            <Route path={urls.admin.leaderboard} element={<AdminLeaderBoard />} />
                        </Route>
                    </Route>

                    {/* Referee Routes  */}
                    <Route element={<RequireAuth allowedRoles={[userRoles.ref, userRoles.ar]} />} >
                        <Route path={urls.referee.base} element={<Referee />} >
                            <Route index element={<RefDashboard />} />
                            <Route path={urls.referee.dashboard} element={<RefDashboard />} />
                            <Route path={urls.referee.games} element={<RefGames />} />
                            <Route path={urls.referee.scoregame} element={<GameRefereeing />} />
                        </Route>
                    </Route>



                    
                    {/* Default Routes  */}
                    <Route path={urls.unauthorized} element={<UnAuthorized />} />
                    <Route path={urls.redirection} element={<Redirection />} />
                    <Route path={'*'} element={<PageNotFound />} />
                </Route> 
                {/* app routes ends here */}

            </AppRoutes>
        </Suspense>
    )
}
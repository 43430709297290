import { collection, query, QueryConstraint, where } from "firebase/firestore";
import { firestore } from "../../../../../firebase-config";
import { IUser, useAuth } from "../../../../context";
import { registrationPages } from "../../../../pages";
import { IFilter, IObject, IPlayer, IPlayerGameStat, IResponse, UIPlayer } from "../../../InterfacesOrTypes";
import { fsPaths, useFirestore } from "../../firestore-repos";

// const playerList: UIPlayer[] = [
//     {
//         name: 'Kofi Doe',
//         name_normalized: 'kofidoe',
//         completed: false,
//         completedStage: "info",
//         createdBy: "self",
//         dob: new Date(),
//         email: "terry.ntow@gmail.com",
//         firstName: "Kofi",
//         id: "pqoy4oobrJdaa1YQc4fLZwR1ey92",
//         isAdmin: false,
//         isCoach: false,
//         isPaid: false,
//         isWaived: false,
//         lastName: "Doe",
//         mobile: "(808) 282-6434",
//         preferredName: "Kwame",
//         profileImageUrl: "https://images.all-free-download.com/images/graphiclarge/baby_profile_193202.jpg",
//         roles: ['player'],
//         teamId: "GMBtyYPyp7fIHKLWNv59",
//         tournamentId: "O0PuPQxP9pRcmkeGy0io",
//         uid: "1",
//         waiverSigned: true
//     },
//     {
//         completed: false,
//         completedStage: "info",
//         createdBy: "self",
//         dob: new Date(),
//         email: "terry.ntow@gmail.com",
//         firstName: "Messi",
//         id: "pqoy4oobrJaa1YQc4fLZwR1ey92",
//         isAdmin: false,
//         isCoach: false,
//         isPaid: false,
//         isWaived: false,
//         lastName: "Loco",
//         mobile: "(808) 282-6434",
//         preferredName: "Kwame",
//         profileImageUrl: "https://images.all-free-download.com/images/graphiclarge/baby_profile_193202.jpg",
//         roles: ['player'],
//         teamId: "GMBtyYPyp7fIHKLWNv59",
//         tournamentId: "O0PuPQxP9pRcmkeGy0io",
//         uid: "99",
//         waiverSigned: true,
//         hasRedCard: true
//     },
//     {
//         completed: false,
//         completedStage: "info",
//         createdBy: "self",
//         dob: new Date(),
//         email: "terry.ntow@gmail.com",
//         firstName: "John",
//         id: "pqo4oobrJdaa1YQc4fLZwR1ey92",
//         isAdmin: false,
//         isCoach: false,
//         isPaid: false,
//         isWaived: false,
//         lastName: "Smith",
//         mobile: "(808) 282-6434",
//         preferredName: "Kwame",
//         profileImageUrl: "https://images.all-free-download.com/images/graphiclarge/baby_profile_193202.jpg",
//         roles: ['player'],
//         teamId: "GMBtyYPyp7fIHKLWNv59",
//         tournamentId: "O0PuPQxP9pRcmkeGy0io",
//         uid: "2",
//         waiverSigned: true
//     },
//     {
//         completed: false,
//         completedStage: "info",
//         createdBy: "self",
//         dob: new Date(),
//         email: "terry.ntow@gmail.com",
//         firstName: "Lord",
//         id: "pqoy4oobrJdaa1Qc4fLZwR1ey92",
//         isAdmin: false,
//         isCoach: false,
//         isPaid: false,
//         isWaived: false,
//         lastName: "Kenya",
//         mobile: "(808) 282-6434",
//         preferredName: "Kwame",
//         profileImageUrl: "https://images.all-free-download.com/images/graphiclarge/baby_profile_193202.jpg", 
//         roles: ['player'],
//         teamId: "VsRPN18ttDiRK65ot9II",
//         tournamentId: "O0PuPQxP9pRcmkeGy0io",
//         uid: "3",
//         waiverSigned: true
//     },
//     {
//         completed: false,
//         completedStage: "info",
//         createdBy: "self",
//         dob: new Date(),
//         email: "terry.ntow@gmail.com",
//         firstName: "Kwame",
//         id: "pqoy4oobrJdaa1YQc4fLZwR1ey92",
//         isAdmin: false,
//         isCoach: false,
//         isPaid: false,
//         isWaived: false,
//         lastName: "Ayisi",
//         mobile: "(808) 282-6434",
//         preferredName: "Kwame",
//         profileImageUrl: "https://images.all-free-download.com/images/graphiclarge/baby_profile_193202.jpg", 
//         roles: ['player'],
//         teamId: "VsRPN18ttDiRK65ot9II",
//         tournamentId: "O0PuPQxP9pRcmkeGy0io",
//         uid: "4",
//         waiverSigned: true
//     },
//     {
//         completed: false,
//         completedStage: "info",
//         createdBy: "self",
//         dob: new Date(),
//         email: "terry.ntow@gmail.com",
//         firstName: "Patricia",
//         id: "pqoy4oobrJdaa1YQc4fLZwR1ey92",
//         isAdmin: false,
//         isCoach: false,
//         isPaid: false,
//         isWaived: false,
//         lastName: "Amponsah",
//         mobile: "(808) 282-6434",
//         preferredName: "Kwame",
//         profileImageUrl: "https://images.all-free-download.com/images/graphiclarge/baby_profile_193202.jpg", 
//         roles: ['player'],
//         teamId: "VsRPN18ttDiRK65ot9II",
//         tournamentId: "O0PuPQxP9pRcmkeGy0io",
//         uid: "90",
//         waiverSigned: true
//     }
// ]

const playerPositions = {
    
}

export const usePlayer = () => {
    const { user: currentUser } = useAuth();
    // const {tournament, postWithCustomIDAsync, readAsync} = useFirestore();
    const { postWithCustomIDAsync, readAsync, getByQueryAsync, postAllAsync, updateDocAsync, updateAllAsync, getDocsFromSnapshot} = useFirestore();


    const getPendingPlayersAsync = async (tournamentId: string) => {
        const filters: IFilter[] = [{name: 'isPaid', comparer: '==', value: false}, {name: 'isWaived', comparer: '==', value: false}];
        return await searchPlayersAsync(tournamentId, filters);
    }


    const searchPlayersAsync = async (tournamentId: string, filters: IFilter[]) => {
        const qc: QueryConstraint[] =[where('tournamentId', '==', tournamentId)];
        filters.forEach(filter => {
            qc.push(where(filter.name, filter.comparer, filter.value));
        })
        const q = query(collection(firestore, fsPaths.players), ...qc);
        const res = await getByQueryAsync<IPlayer[]>(q);
        return res;
    }

    const validatePlayer = (player: IPlayer) => {
        delete player.teamName;
        return player;
    }


    const getPlayerId = (tournamentId: string, uid?: string) => uid ? `${tournamentId}${uid}` : `${tournamentId}${currentUser.uid}`;

    const readPlayerAsync = async (tournamentId: string): Promise<IResponse<IPlayer>> => {
        const res:IResponse<IPlayer> = {success: false, exists: false};
        const id = getPlayerId(tournamentId);
        const o = await readAsync<IPlayer>(fsPaths.players, id);
        res.success = o.success;
        res.data = (o.data as IPlayer);
        
        return res;
    }
    const postPlayerAsync = async (tournamentId: string, teamId: string, user?: IUser): Promise<IResponse<IPlayer>> => {
        // const output: IResponse<IPlayer> = {success: false, exists: false}
       const uid =  !!user && user.uid || currentUser.uid;
       const firstName = !!user ? user.firstName : currentUser.firstName;
       const lastName =  !!user ? user.lastName : currentUser.lastName;
       const position = 'Unknown';
       
        const player: IPlayer = {
            uid: uid,
            firstName: firstName,
            lasttName: lastName,
            position: position,
            name_normalized: `${firstName}${lastName}`.normalize(),
            teamId: teamId,
            tournamentId: tournamentId,
            completed: false,
            completedStage: registrationPages.info,
            isPaid: false,
            isWaived: false
        }
        const id = getPlayerId(tournamentId, user?.uid);
        
       return await postWithCustomIDAsync<IPlayer>(fsPaths.players, id, validatePlayer(player));
    }

    const updatePlayerAsync = async (tournamentId: string, data: IObject) => {
        return await updateDocAsync(fsPaths.players, getPlayerId(tournamentId), validatePlayer(data as any));
    }


    const getAllPlayersInATeamAsync = async (tournamentId: string, teamId: string): Promise<IResponse<UIPlayer[]>> => {
        // const fake: IResponse<UIPlayer[]> = {success: false, exists: false, data: playerList.filter(p => p.teamId == teamId)}
        // return fake;

        const q = query(collection(firestore, fsPaths.players), where('tournamentId', '==', tournamentId), where('teamId', '==', teamId));
        const res = await getByQueryAsync<IPlayer[]>(q);
        const uiPlayers: UIPlayer[] =  [];
        const output: IResponse<UIPlayer[]> = {success: false, exists: false, data: uiPlayers}
        if(!res.success) {output.message = res.message; return output}

        const promises: Promise<IResponse<IUser | null>>[] = [];
        const players = (res.data as IPlayer[]);

        players.forEach(p => {
            promises.push(readAsync<IUser>(fsPaths.users, p.uid));
        })
        
        try {
            const allUserResponse  = await Promise.all(promises);
            allUserResponse.forEach(uRes => {
                if(uRes.success) {
                    const curP = players.filter(p => p.uid == uRes.data?.uid);
                    const uip = {} as UIPlayer;
                    uiPlayers.push(Object.assign(uip, curP[0], uRes.data));
                }
            })
            output.success = output.exists = true;
            output.data = uiPlayers;
        } catch (error) {
            output.message = (error as any).message;
        }
        
        return output;
    }

    

    
    const getGameStatsWhereClause = (tournamentId: string, teamId: string, gameId: string): QueryConstraint[] => {
        return [where('tournamentId', '==', tournamentId), 
            where('teamId', '==', teamId), 
            where('gameId', '==', gameId)]
    }
    // all stats for a game
    const getAllPlayerStatsAsync = async (tournamentId: string, homeTeamId: string, guestTeamId: string, gameId: string) => {
        const colRef = collection(firestore, fsPaths.playerStats);
        const q = query(colRef, 
            where('tournamentId', '==', tournamentId), 
            where('gameId', '==', gameId),
            where('teamId', 'in', [homeTeamId, guestTeamId])
            )
        const res = await getByQueryAsync<IPlayerGameStat[]>(q);
        const data:{[teamId: string]: IPlayerGameStat[]} = {};
        if(res.success && res.exists) {
            res.data?.forEach(stat => {
                (data[stat.teamId] ? data[stat.teamId] : data[stat.teamId] = []).push(stat);
            })
        }
        // if there is no data then set default
        if(!res.data?.length) {
            data[homeTeamId] = [];
            data[guestTeamId] = [];
        }

        const output: IResponse<{[teamId: string]: IPlayerGameStat[]}> = {
            success: res.success, 
            exists: res.exists, 
            data: data,
            message: res.message
        }
        return output;
    }
    // Only for a particular team
    const getPlayersStatsAsync = async (tournamentId: string, teamId: string, gameId: string) => {
        const colRef = collection(firestore, fsPaths.playerStats);
        const q = query(colRef, ...getGameStatsWhereClause(tournamentId, teamId, gameId))
        const res = await getByQueryAsync<IPlayerGameStat[]>(q);
        let data:IPlayerGameStat[] = [];

        if(res.success && res.data && !res.data.length) {
            // initial player signin should not have any stats
            // this is when to run the red flag before player
            // is or is not allowed to signin
            // the moment there's is data when don't want to keep running this
            // expensive query
            const {success, data} = await checkForPreviousRedCards(tournamentId, teamId, gameId);
            if(success) {
                res.data = data;
            }
        }
        
        if(res.success && res.exists) {
           data = res.data || [];
        }
        
        const output: IResponse<IPlayerGameStat[]> = {success: res.success, exists: res.exists, data: data}
        return output;
    }
    

    const postPlayersStatsAsync = async (playerStats: IPlayerGameStat[]) => {
        const res = await postAllAsync(playerStats, fsPaths.playerStats);
        return res.success;
    }

    const updatePlayerStatsAsync = async (data: IPlayerGameStat) => {
        return await updateDocAsync(fsPaths.playerStats, data.id as string, data);
    }

    const checkForPreviousRedCards = (tournamentId: string, teamId: string, gameId: string) => {
        return {success: true, data: []}
    }
   

    // const redFlagPlayersNextGameAsync = async (stat: IPlayerGameStat) => {
    //     const gRes = await readAsync<IGame>(fsPaths.games, stat.gameId)
    //     if(gRes.success) {
    //         const game = gRes.data as IGame;
    //         const colRef = collection(firestore, fsPaths.games);// games collection
            
    //         const q1 = query(colRef, 
    //             where('tournamentId', '==', stat.tournamentId),
    //             where('gameDate', ">", game.gameDate),
    //             where('homeTeamId', '==', stat.teamId),  // its either home team or guest team hence 2 queries
    //             orderBy('gameDate'),
    //             limit(1));
    //         const q2 = query(colRef, 
    //             where('tournamentId', '==', stat.tournamentId),
    //             where('gameDate', ">", game.gameDate),
    //             where('guestTeamId', '==', stat.teamId),  
    //             orderBy('gameDate'),
    //             limit(1));

    //         try {
    //             const [home, guest] = await Promise.all([getDocs(q1), getDocs(q2)]);
    //             const homeDocs = getDocsFromSnapshot(home);
    //             const guestDocs = getDocsFromSnapshot(guest);
    //             const nextGames:IGame[] = (homeDocs.concat(guestDocs));
    //             if(nextGames && nextGames[0]) {
    //                 const nextGame = nextGames[0];
    //                 console.log(nextGame);
    //             } else {
    //                 // is means that this game is their last game 
    //                 // either in the group stages or finals
    //                 // Updates to this should be
    //                 // adding a condition to check for next game in the same tournament
    //                 // even when there is none currently present
    //                 on sign up for the next game check the UIPlayer list against previous game stats to see if 
    //                 anyone had a red card from the previous game instead of doing it here
    //             }

    //         } catch (error) {
                
    //         }
    //     }
    // }

    const updatePlayersStatsAsync = async (data: IPlayerGameStat[]) => {
       return await updateAllAsync<IPlayerGameStat>(fsPaths.playerStats, data);
    }


    const getLeaderBoardAsync = async (tournamentId: string) => {
        const colRef = collection(firestore, fsPaths.playerStats);
        const q = query(colRef, where('tournamentId', '==', tournamentId), where('count', '>', 0))
        const res = await getByQueryAsync<IPlayerGameStat[]>(q);
        if(!res.success) {
            res.data = [];
            return res;
        }

        const stats = res.data as IPlayerGameStat[];

        const uniqueUIDs: string[] = [];
        stats.forEach(d => {
            if(!uniqueUIDs.includes(d.uid)) {
                uniqueUIDs.push(d.uid);
            }
        })
        
        // I have all the unique uids
        const board:IPlayerGameStat[]  = [];
        uniqueUIDs.forEach(uid => {
            board.push(stats.filter(s => s.uid === uid).reduce((acc, cur) => {
                acc.count = acc.count + cur.count;
                return acc;
            }))
        })

        // Sorting to figure out ties
        board.sort((a, b) => {
            const x = b.count - a.count;
            if(!x) {
                // meaning its tied
                b.tie = true;
                b.tiedWith = a.uid;
                a.tie = true;
                a.tiedWith = b.uid;
            }
            return x;
        })

        // start ranking base on ties
        for (let i=0; i < board.length; i++) {
            board[i].rank = i + 1;// initial ranking
            //change ranking if there are any ties
            // - 1 on the looper to avoid null values at the end of the loop
            // also making sure that i is truthy to make sur the condition starts from index 1
            if(i) {
               const cur = board[i];
               const prev = board[i - 1];
                if(cur.tie === prev.tie && prev.tiedWith === cur.uid) {
                    board[i].rank = prev.rank;
                }
            }
        }

        res.data =  board;
        return res;
    }


    return { 
        readPlayerAsync, 
        searchPlayersAsync,
        getPendingPlayersAsync,
        postPlayerAsync, 
        updatePlayerAsync,
        getAllPlayersInATeamAsync, 
        getLeaderBoardAsync, 
        getAllPlayerStatsAsync, 
        getPlayersStatsAsync, 
        postPlayersStatsAsync, 
        updatePlayerStatsAsync, 
        updatePlayersStatsAsync
    }
};
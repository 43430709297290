import { ProfilePageImg } from "./ProfileImg";
import styles from "./profile.module.scss";
import { notificationLocations, notificationTypes, useAppSettings, useAuth } from "../../context";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ALink, convertTimestampToDateString, formatDate, getAge, IObject, PgNotification, urls } from "../../shared";
import { UserInfo } from "firebase/auth";
import { useEffect, useMemo } from "react";

const states = {
   display: 'display',
   edit: 'edit',
   save: 'save'
}

const asideLinks = [
   {route: 'info', label: 'User Info'},
   {route: 'emergency', label: 'Emergency'},
   // {route: 'password', label: 'Password'},
   {route: 'changeprofilepic', label: 'Update Pic'},
   // {route: 'deleteaccount', label: 'Delete Account'}
]
export const Profile = () => {
   const { user } = useAuth();
   const {notification: {location: loc}, handleNotification} = useAppSettings();
   const location = useLocation();
   const pData = (user.providerData as UserInfo[]);
   const isPasswordProvider = pData[0] && pData[0].providerId === 'password';

   const profileLinks = useMemo(() => {
      return isPasswordProvider ? asideLinks : asideLinks.filter(l => (urls.profile as any)[l.route] !== urls.profile.password);
   }, [isPasswordProvider])

 
   const onUpdate = (values: IObject) => {
      const nUser = {...user, ...values};
      handleNotification({msg: '', location: notificationLocations.main, isNotify: false});
      runUpdates(nUser);
   }

   const runUpdates = (nUser: IObject) => {
      if(nUser && (!nUser.firstName || !nUser.lastName )) {
         handleNotification({msg: 'Please update all the',
         link: <ALink url={urls.profile.info} >required info!</ALink>,
         canClose: false, location: notificationLocations.profile})
      }

      if(nUser && (!nUser.emergencyContactMobile || !nUser.emergencyContactName)) {
         handleNotification({msg: 'Please update your',
         link: <ALink url={urls.profile.info} >emergency contact info!</ALink>,
         canClose: false, location: notificationLocations.profile})
      }
      if(nUser && !nUser.profileImageUrl) {
         handleNotification({msg: 'Please update ',
         link: <ALink url={urls.profile.changeprofilepic} >your profile picture for id verification.</ALink>,
          type: notificationTypes.warning, canClose: false, location: notificationLocations.main})
      }
   }
   useEffect(() => {
      runUpdates(user);
   }, [user])


   if(!isPasswordProvider && location.pathname === urls.profile.password) {
      return <Navigate to={urls.pagenotfound} replace={true} />
   }


   return (
      <div className={styles.Profile}>
         <div className={styles.Header}>
            <div className={styles.LeftProfile}>
               <div className={styles.HeaderTitle}>
                  <h2>User Profile</h2>
               </div>
            </div>
            <div className={styles.RightProfile}>
               <div className={styles.ImgHeaderWrapper}>
                  <ProfilePageImg />
                 {!!user.lastName && <div className="info">
                     <h3>{user.firstName} {user.lastName} {user.preferredName && <>({user.preferredName})</>}</h3>
                     <small style={{opacity: '0.5', fontSize: '12px'}}>
                     <p><small>{convertTimestampToDateString(user.dob)} ({getAge(user.dob)})</small></p>
                     <p><small>{(user.mobile as any).phone()}</small></p>
                     </small>
                  </div>}
               </div>
            </div>
         </div>

         <div className={styles.Body}>
            <div className={styles.LeftProfile}>
               <div className={styles.ProfileNavs}>
                  {profileLinks.map((item, idx) => {
                     return (
                        <div key={idx}>
                           {(urls.profile as any)[item.route] === urls.profile.info && urls.profile.base === location.pathname ? <ALink className={'active'} url={(urls.profile as any)[item.route]}>{item.label}</ALink> 
                           : <ALink url={(urls.profile as any)[item.route]}>{item.label}</ALink>}
                        </div>
                     )
                  })}
               </div>
            </div>

            <div className={styles.BodyContent}>
               {loc === notificationLocations.profile && <PgNotification />}
               <div style={{color: 'orange'}}>
                  For security reasons you may be required to provide your credentials before updating info.
               </div>
               <Outlet context={{ onUpdate }} />
            </div>
         </div>
         
      </div>
   );
}
import { useEffect, useState } from 'react';
import { useAuth } from '../../context';
import { ALink, IPageLink, urls, useLocation } from '../../shared';
import { FourContentLayout } from '../../shared/components/layouts';
// import './Referee.scss';


export const Referee = () => {
   const {user} = useAuth();
   const location = useLocation();
   const [links, setLinks] = useState<IPageLink[]>([
      {name: 'Dashboard', route: urls.referee.dashboard}
   ]);


   
   const link = links.filter(l => l.route === location.pathname)[0];
   const [header, setHeader] = useState(
      location.pathname === urls.admin.base 
      ? "Dashboard" 
      : link ? link.name : ""
   );

   useEffect(()=> {
      if(user.isAuthenticated) {
         if(user.firstName && user.lastName && user.profileImageUrl) {
            setLinks(state => ([...state.filter(l => l.name !== 'Games'), {name: 'Games', route: urls.referee.games}]));
         }
      }
   }, [user])
   
    return (
        <FourContentLayout >
            <div>Left Header</div>
            <h1>{header}</h1>
            <>
               {user.isAuthenticated && links.map((item, idx) => {
                  return (
                     <div key={idx} onClick={() => setHeader(item.name)}>
                        {location.pathname === urls.admin.base && item.route === urls.admin.dashboard 
                        ? <ALink className='active' url={item.route}>{item.name}</ALink>
                        : <ALink url={item.route}>{item.name}</ALink>}
                     </div>
                  )
               })}
            </>
         </FourContentLayout>
    );
}
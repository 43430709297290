import { useIncompleteAccount } from '../../../shared';
import './RefDashboard.scss';

export const RefDashboard = () => {
    const { routes, Comp } = useIncompleteAccount();

    return (
        <div>
            <Comp routes={routes} />

        </div>
    );
}

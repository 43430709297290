import { useOutletContext } from 'react-router-dom';
import { useAppSettings } from '../../../context';
import { ALink, IPlayer, ShadowCard, urls, useIncompleteAccount } from '../../../shared';
import './Dashboard.scss';

export const Dashboard = () => {
    const { player } = useOutletContext<{player: IPlayer;}>();
    const {tournament} = useAppSettings();
    // const navigate = useNavigate();
    const { routes, Comp } = useIncompleteAccount();

    return (
        <div>
            {routes.length > 0 &&
                <ShadowCard>
                    <h2>Incomplete Account</h2>
                    <Comp routes={routes} />
                </ShadowCard>
            }

            {player && <>
                {player.isPaid || player.isWaived ? <></> : player.markedAsPaid ? (
                    <ShadowCard>
                        <p>We will start working on your payment shortly!</p>
                    </ShadowCard>
                ) : (
                    <ShadowCard>
                        <h2>Payment Required</h2>
                        {tournament && <ALink url={urls.tournamentpayment}>Pay your player fee of ${tournament.playerFee}</ALink>}
                    </ShadowCard>
                )}
            </>}

            <div>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, velit animi! Odit molestiae sapiente ea necessitatibus, labore vitae id molestias atque quae quidem pariatur, autem dignissimos, corrupti corporis voluptate incidunt.
                Autem magni deleniti, iste est at necessitatibus tenetur quia ea quasi rerum voluptas et cum eum magnam perspiciatis amet dicta obcaecati? Provident sequi error esse quasi consequuntur minus corporis nobis.
                Facilis voluptatibus sit sunt distinctio in natus dignissimos, ex dicta aspernatur minus minima, nulla adipisci consequuntur nobis, maxime quos impedit. Vel consequatur veniam adipisci nisi? Veniam cupiditate nesciunt omnis hic?
                Magnam repellat reiciendis libero molestias in, maxime neque voluptate, fugit, laborum tenetur quisquam incidunt voluptas eum saepe id eaque deserunt reprehenderit hic. Sed hic architecto expedita vitae quos quod consectetur.
                Adipisci, nulla earum est, eum omnis ab ullam quas maiores, voluptatibus tenetur autem. Id consectetur perferendis quod sunt, magnam maiores inventore nobis non repellat recusandae voluptates. Accusamus, vel dolor? Voluptas!
                Asperiores officia at voluptas, minima praesentium ab culpa expedita similique consequuntur ea ratione fuga laboriosam sed repudiandae reprehenderit minus. Ullam rem tenetur eos sunt et sed officia, nam veniam culpa!
                Aut neque est quos. Eaque, tempora repudiandae dolore eligendi pariatur sint blanditiis quasi, recusandae optio nisi unde fuga, commodi corporis quibusdam quae vero ducimus doloremque tempore molestias voluptatum. Aliquam, vitae.
                Sint aut quas quam eligendi. Asperiores doloribus illo ipsam quaerat. Cumque eius sapiente, mollitia modi magni iusto, enim cupiditate alias nisi odio facere vero! Ab deleniti modi repudiandae totam laudantium.
                Dolore porro iusto quia inventore quas incidunt quos maxime, tenetur corrupti animi error adipisci, quae laborum voluptas mollitia est illum excepturi dolorum recusandae praesentium sit nihil! Amet quaerat corrupti sit?
                Voluptatum dolore nobis soluta eligendi laudantium, saepe ea error eius ex earum iusto animi laborum delectus non? Obcaecati ullam odio asperiores ipsa, repellat accusantium doloremque velit molestias perferendis ad labore!
                Provident quis, voluptate suscipit vitae placeat qui rerum fugit sit consequatur dignissimos adipisci consequuntur pariatur quisquam ipsa consectetur repellat iste non hic delectus accusantium blanditiis ipsam recusandae nisi cupiditate? Aut.
                Soluta eos id eius tenetur sapiente reiciendis placeat est a officiis ex eum dolore ut quam ab quos, aut amet voluptates fuga repellat aliquid nihil voluptatum at praesentium? Dolor, repellat!
                Quo consequuntur illum quia culpa minima aliquam dolore rem velit, perferendis qui nulla. Minima officiis eligendi aliquam veritatis tempore, ad, blanditiis nulla, similique dolorem qui sit labore. Commodi, laborum repellat!
                Error quidem provident amet perferendis, ducimus nam non laudantium nesciunt labore omnis voluptates dignissimos enim libero sint veniam, et corrupti. Sit repellat aperiam cupiditate omnis, totam doloribus quis ex dolore!
                Veniam illo saepe tenetur vero odio modi velit odit earum repudiandae soluta, sunt praesentium? Sit, odit enim est facilis, ipsam quas ex ab vero eius, cumque dignissimos suscipit incidunt. Provident?
                Error quos officiis, nisi, illum maiores ipsam officia beatae saepe cumque quo rerum! Consectetur soluta, expedita vero impedit quisquam aut adipisci commodi maxime quibusdam itaque laborum dicta ea. Vero, ratione!
                Voluptates obcaecati laudantium molestiae alias blanditiis repellendus qui harum voluptas dolorem tempora quibusdam, adipisci fugiat, ea commodi, maiores suscipit modi amet repudiandae fugit ducimus nam. Quibusdam eius omnis doloribus quod!
                Quae, vero sed exercitationem reiciendis ducimus cumque maxime asperiores maiores repellat repellendus beatae assumenda eveniet labore nulla suscipit qui ipsam at dolorum modi iure sequi in culpa libero? Ratione, delectus!
                Accusantium architecto laudantium sint tenetur possimus quis saepe deleniti rem, harum cumque, voluptate dolorum quibusdam mollitia consectetur, beatae quae? Facilis quod quos totam minima molestias nam ut ex dicta explicabo?
                Culpa veritatis ab odio odit obcaecati mollitia voluptas, maiores dicta sit officia molestias amet sed beatae inventore neque, impedit consequuntur qui porro, praesentium labore vero! Numquam nisi impedit eaque explicabo.
                Veniam ad nam mollitia ipsa! Ipsa iste incidunt fugiat dolores laborum, molestias repellendus similique dignissimos nobis et autem provident tenetur quos voluptates doloribus ut eum deserunt, nisi nulla nam rerum.
                Quisquam pariatur ipsum eligendi libero hic voluptate magni, consequatur, exercitationem nemo a molestiae! Pariatur vitae animi quos iusto est consectetur totam officia possimus unde accusantium, obcaecati iure laudantium numquam? Optio.
                Consequuntur fugiat eos officia dolore id vero, reiciendis nihil nemo iusto cupiditate modi. Esse ipsum explicabo cumque quidem dolore dolorem tempora minus beatae autem velit adipisci eaque neque, rem nesciunt.
                Sint error beatae dolorem eum praesentium ipsum, nemo exercitationem quidem, suscipit pariatur, quam corrupti consequuntur? Placeat, nihil accusamus? Incidunt, in. Porro corrupti, laborum quo perspiciatis est itaque eaque asperiores repellat?
                Reiciendis magni ullam voluptate doloribus esse tempore laudantium rerum modi illo at vel facere impedit reprehenderit commodi blanditiis sed, corporis explicabo ratione officiis aperiam voluptates? Reiciendis veniam odit animi qui!
                Soluta quam ut alias sequi blanditiis inventore iste suscipit officia provident aspernatur eos vitae quas, voluptatibus repellat nostrum labore unde obcaecati ex minima a? Quod dolore sequi sit quaerat magni.
                Mollitia impedit dicta nisi tempore vitae, maiores inventore error atque possimus ipsam soluta velit quaerat aliquam magnam dolore voluptas ut porro voluptatum laudantium commodi harum eaque eligendi? Commodi, impedit ut?
                Similique, impedit odio iure, ab minus ad tenetur fugit provident, officia iusto at reiciendis corporis qui molestiae eos natus quod doloremque. Rerum, ipsam? Aperiam cum temporibus commodi nulla tempora dolores.
                Alias amet eveniet minus laborum, id quo eaque cumque sed ullam? Laboriosam nam optio minima laudantium labore autem officia reiciendis cum, a commodi quis delectus quidem minus soluta, alias mollitia?
                Dolorum praesentium exercitationem rerum eos omnis voluptate veniam? Illo maiores laborum natus tenetur, ullam sequi quasi dolore harum quos quia vel est, nulla officia, consectetur dolor ipsum esse? Animi, unde.
                Eveniet odit id ullam voluptate nostrum quidem quod reprehenderit qui, dolores natus expedita non iste quae. Veritatis reiciendis accusantium nostrum, molestias natus doloribus. Voluptates consectetur excepturi perferendis cumque, recusandae accusamus?
                Ullam magni deleniti incidunt velit ratione pariatur excepturi mollitia rem deserunt recusandae sequi blanditiis natus assumenda repellat, ex quod sint expedita officiis. Repudiandae nemo eos pariatur porro excepturi quam ratione?
                Eaque officia a recusandae architecto. Veritatis autem eligendi debitis dicta facere non laudantium odio quibusdam optio laboriosam ut, dignissimos, placeat aspernatur alias nesciunt tempore ullam? Totam error in accusantium ratione.
                Blanditiis itaque vero illo magnam. Reprehenderit similique quod necessitatibus sapiente recusandae. Expedita ratione facilis eligendi consectetur autem libero temporibus ex rem eum ut repudiandae ipsum, ipsam animi reiciendis! Porro, laudantium.
                Labore aperiam saepe velit ipsum doloremque nulla pariatur vel atque voluptas officia, cupiditate amet expedita eius quisquam molestiae eum placeat nemo. Sed architecto exercitationem optio omnis. Laudantium eos sequi blanditiis!
                Doloribus fugiat autem sit, unde eum voluptate non pariatur voluptatem porro laudantium tenetur molestiae modi nam. Dolore quia fugiat harum, nemo quae eum? Dignissimos at, quidem doloribus voluptatibus nobis consequatur.
                Aliquid illum assumenda omnis iure ipsa blanditiis sequi officiis neque cum natus magnam soluta repellat repudiandae id facere tenetur est, commodi hic quia obcaecati quidem non? Quis mollitia maxime dolore?
                Dicta, labore illum maxime inventore sit quasi officia numquam repellat obcaecati explicabo natus minus animi rem doloribus laudantium neque consequatur blanditiis! Aspernatur perspiciatis consequuntur necessitatibus cum ullam quaerat excepturi voluptates?
                Magni quae corrupti iusto nam, qui possimus? Modi repellendus commodi ab quis totam et dolorum, accusantium cupiditate aliquid ad assumenda facere nam, ex ducimus quod? Impedit veritatis nesciunt quaerat ea!
                Aliquid soluta provident, illum ex ab consequuntur modi mollitia est veritatis rerum nihil dolores, aliquam magnam minima necessitatibus doloremque ipsam. Error ipsa illum inventore quod fugit culpa nostrum repellendus blanditiis.
                Explicabo, incidunt obcaecati. Tempore quidem laudantium officiis, recusandae porro illo vel voluptatibus, quis doloremque minus ipsam enim et sit eaque placeat sequi dicta unde natus ipsum hic nisi corporis repellendus?
                Commodi, eveniet quos blanditiis veritatis dolor ducimus veniam tempore maxime adipisci quibusdam cum distinctio eligendi ea facere consequatur, aliquam harum atque nulla maiores provident, nihil esse. Sit officiis provident fuga?
                Deleniti vel fugiat atque, esse inventore ipsam deserunt nostrum perspiciatis at assumenda debitis expedita aperiam, sint nemo accusamus ut laboriosam alias ullam doloribus omnis veniam facilis quod eaque. Iure, sit.
                Velit earum ducimus enim provident, commodi, debitis impedit ex laborum nisi, id dolor sed beatae pariatur? Sapiente esse repellat perspiciatis quam. Ad dicta consequuntur quae at magnam repellendus facere rerum.
                Sapiente temporibus ipsam suscipit sequi excepturi aperiam vitae. Officia, hic nam obcaecati vel quibusdam sequi in. Laboriosam natus aspernatur commodi molestiae voluptate, blanditiis, provident et delectus aperiam est aliquid ipsa!
                Itaque optio ut accusamus odio quod eius, dolorum sequi quaerat, saepe officiis praesentium facilis nostrum aut. Quod tempora earum delectus nemo asperiores impedit incidunt libero, esse architecto sit est! Quos.
                Sequi a amet tenetur quis, distinctio explicabo quae magni, autem aliquam reiciendis quasi optio harum possimus magnam temporibus minus. Earum dicta non, eaque modi aperiam ab quas consequuntur aliquam animi.
                Repudiandae fugiat nesciunt omnis! Commodi nihil obcaecati, unde voluptatum fugit illum dolorem nam. Repellendus temporibus ab eaque delectus harum fugiat, autem officiis facere similique inventore velit accusantium accusamus rerum quod!
                Ducimus officia illum aut in sit, maxime vel vitae beatae quisquam. Nulla dolorum veritatis aliquid, libero rem magnam. Voluptatem temporibus suscipit voluptate asperiores ab quaerat dolor natus quia adipisci non.
                Ab, magni ipsum ut consequatur, autem corporis explicabo quo et dolores natus labore a perferendis possimus? Nemo, aspernatur perferendis velit modi beatae qui sapiente saepe. Itaque nisi ipsam consequatur recusandae?
                Aspernatur illum, mollitia cumque harum minima reprehenderit quis repudiandae, eos natus ipsum ducimus aut recusandae inventore omnis provident cupiditate, ad incidunt neque velit distinctio. Impedit iure necessitatibus ratione temporibus eaque.
                Consectetur veritatis ad, tempora perspiciatis numquam laborum maiores sunt assumenda blanditiis obcaecati magni? Fugiat libero, nam vel quae nemo minus laboriosam impedit voluptatibus soluta veniam, officiis ea architecto, alias aperiam!
                Ipsa esse corrupti ipsum repellendus, voluptates recusandae aut maxime, accusantium quaerat sint molestias culpa, possimus consectetur natus dolorem earum eum? Quasi quae iste eum sint ipsam, tempora ab libero velit?
                Natus porro et nobis tempora. Quas, adipisci recusandae! Voluptates quas iste, atque nisi in, saepe praesentium aspernatur ducimus eligendi nemo repellendus dolore sed accusamus molestiae perferendis consequuntur totam officiis optio.
                Itaque iusto excepturi odio, cupiditate dolorem tenetur accusamus illo eveniet harum atque similique, reiciendis exercitationem omnis necessitatibus ea ullam! Quidem ea id quaerat nobis officiis consectetur corrupti velit repellendus totam?
                Laborum aperiam, molestiae repellendus neque reprehenderit blanditiis optio voluptate vero. Asperiores voluptate optio provident eveniet aliquid minus, quasi soluta distinctio ducimus. Est possimus amet placeat similique cum atque officia mollitia.
                Quia dicta accusantium cum nihil, vitae distinctio ullam! Quae cupiditate, esse quisquam aspernatur nulla pariatur? Dicta eos hic, quam ea et vitae illo sint. Voluptates at quasi accusamus ad vel.
                Unde fugit quidem maxime eveniet laudantium praesentium beatae deleniti vitae, repellat reprehenderit tenetur ad officia error accusantium labore repellendus soluta, id aut iure itaque optio doloribus debitis sequi iste. Odio.
                Magni corporis distinctio tempore aspernatur nam, debitis voluptas animi illum dolorem cupiditate quis accusamus tempora similique minus iste itaque nesciunt aliquam. Ea omnis nam et ipsum cupiditate nulla quas inventore.
                Illum dolorum adipisci repellat doloremque, ex aperiam itaque vero, veritatis fugit magnam odit minus amet. Amet quia exercitationem cumque sunt obcaecati eveniet corporis, atque consectetur iure expedita, ex natus deleniti.
                Iure obcaecati officia ad mollitia nihil reprehenderit numquam excepturi explicabo cum veritatis voluptatibus cupiditate accusantium suscipit, voluptatem sed unde dolor. Explicabo odit, consectetur ut aut culpa sunt facilis debitis voluptatum?
                Nostrum iste ipsa amet laudantium dicta facilis, optio et reiciendis iure adipisci? Perspiciatis eligendi modi esse vitae, facilis unde doloremque sit quibusdam nulla at? Veritatis minima velit voluptatem nisi eum?
                Ex molestias, alias porro maxime eaque iste animi cumque at molestiae voluptates, veniam, quia perspiciatis aliquam dignissimos expedita! Fuga reprehenderit nemo natus dolore explicabo, aperiam excepturi. Illo placeat dolore inventore.
                Fugit similique debitis sunt deleniti ab quibusdam, unde architecto, consectetur sint placeat recusandae, quam tenetur quasi amet officiis ea itaque consequatur? A deleniti, corporis sunt obcaecati quod at laborum cupiditate.
                Minima nobis ipsum harum voluptas sint animi quae aperiam hic voluptatem recusandae ipsam omnis a, corporis ea odio est similique voluptates. Totam, id. Amet necessitatibus alias odio voluptas sint molestiae.
                Earum magni adipisci deserunt amet quia enim eius. Qui cumque laudantium nisi reiciendis placeat repellendus totam vero, nobis quasi numquam illum iusto provident natus tenetur consequatur soluta, temporibus laboriosam? Libero!
                Dolor sequi laborum animi illum id officia a possimus velit odio optio, labore est magnam laudantium, nam molestias totam vel soluta quibusdam ex, at pariatur sint accusantium. Dolorem, fugit quae.
                Pariatur voluptatibus reiciendis, nemo id enim vel veniam facere adipisci labore fugit dolore sunt eligendi est cumque suscipit quasi distinctio reprehenderit voluptatem nesciunt! Repudiandae explicabo quis laudantium esse quos enim!
                Delectus aut odio quod rerum temporibus dolorem culpa, consectetur cupiditate velit cum? Fugiat nisi, recusandae esse harum culpa dignissimos, totam quaerat nam at unde earum rem, reprehenderit nostrum aperiam eligendi.
                Debitis accusamus id cum consequuntur voluptate! Eius excepturi commodi, nisi temporibus vero quasi iure. Odio numquam totam, repudiandae itaque deleniti dolorum alias eveniet commodi ut quasi non voluptatum quis quod!
                At, aut debitis earum sunt nihil nulla. Hic praesentium doloremque, veniam reprehenderit ab esse expedita sint voluptatibus et tempore eius tempora asperiores enim similique numquam voluptates ex consequuntur officia quisquam.
                Autem necessitatibus, voluptates repudiandae dolor recusandae quis sed quam accusamus suscipit minus velit aut, nostrum aspernatur natus ratione mollitia explicabo sint in aliquid. Odio nostrum reiciendis atque veniam quo id.
                Hic accusantium, iure excepturi alias illum earum maxime saepe minima totam. Ratione velit dolorem quidem accusamus ipsa expedita? Mollitia facere debitis iste provident quidem odio? Necessitatibus suscipit exercitationem dolorum in.
                Sint, obcaecati laboriosam repellendus a suscipit necessitatibus eum possimus soluta dolor asperiores minima accusamus ipsam facilis, itaque consequuntur odio excepturi, animi voluptates quas reiciendis libero tempora incidunt. Sapiente, ipsa facilis!
                Exercitationem tempore repudiandae deleniti facere numquam voluptates voluptatum ratione praesentium laboriosam, veniam magnam quia eius dolores eum optio aperiam dignissimos amet hic facilis aut illo? Illum repellendus odio laborum est.
                Odio est necessitatibus laboriosam eum, quaerat cumque possimus nemo officia! Eos tempora laudantium, minima ex, cupiditate rem quis eius minus id culpa commodi! Illo sequi impedit enim, sunt fuga dolorem?
                Nostrum, odio vero quisquam, perspiciatis impedit dignissimos cumque nam architecto inventore aliquam, possimus aut expedita. Natus culpa reprehenderit, dolores exercitationem aspernatur dolore. Ducimus ad tempora exercitationem perferendis magni, accusamus nemo!
                Illo optio facere voluptatibus vitae accusantium? Voluptatum officiis placeat necessitatibus suscipit temporibus dicta minus, quasi soluta veritatis consequuntur a labore odio corporis ullam incidunt esse tempora accusantium expedita repellendus consequatur?
                Similique veritatis aliquid consequuntur officiis! Pariatur quas nisi placeat omnis corrupti consequuntur. Nobis dolorem, fugiat cupiditate quae nulla minus, iste adipisci deserunt sapiente, distinctio consequuntur ipsam eius hic ducimus dolorum.
                Laudantium odit minima necessitatibus eaque. Maxime quam, iste, ut, et omnis officiis commodi rerum tempore fugiat dolores id ad architecto nesciunt earum. Voluptates itaque eaque doloremque modi saepe, voluptatum dicta?
                In et cum pariatur, voluptatum est modi. Excepturi rerum dignissimos enim provident, ipsa debitis minus corporis molestias alias ipsum, cupiditate, possimus modi beatae dolore fugiat velit reprehenderit reiciendis veniam iusto.
                Laborum, quia optio voluptas magnam illo dignissimos exercitationem. Deserunt amet rem hic dolores sapiente iure sunt, fugit, eveniet dignissimos officiis delectus facere, quo porro? Dolorem dolor porro assumenda ipsam inventore!
                Tempore cupiditate cum vel quia, est illum rerum fuga. Soluta dolor, eum debitis non sunt optio dolore laudantium minus perferendis magnam aperiam maiores facere tenetur rerum deserunt necessitatibus eaque explicabo.
                Voluptatum, dolorem. Odio pariatur, repellendus accusamus quia qui, hic fuga placeat nesciunt autem expedita ad natus officiis accusantium optio quas quo sed. Nulla nam, vero porro nemo nisi magnam ab!
                Nisi necessitatibus minus minima culpa aperiam? Obcaecati inventore praesentium nostrum alias quas hic pariatur eligendi ad. Maiores ipsum necessitatibus aliquid quidem nostrum ducimus, eveniet, architecto nulla quia, facere voluptatibus recusandae!
                Accusamus eum assumenda maiores eligendi deleniti facere iste enim veniam voluptatem vitae nesciunt, natus similique iure debitis amet id officia facilis sapiente earum eaque! Mollitia eius suscipit quia architecto laboriosam!
                Possimus nesciunt aut dolorum ab vitae eligendi reiciendis quasi minima. Doloribus tempore earum harum, corporis id, eveniet omnis neque temporibus illum inventore nisi beatae repudiandae ratione porro pariatur nobis voluptate.
                Autem magnam dolor minima voluptas, doloribus ducimus asperiores facere alias illum nihil corporis! Alias, sed, vitae aliquid corrupti voluptates facere repellendus magni temporibus, consequuntur cum exercitationem minus quo facilis dolorum!
                Eveniet eum voluptatum cum fugiat quibusdam recusandae neque vitae laudantium omnis reprehenderit quia ullam expedita quidem, sit earum maiores velit ratione cupiditate provident dolorem odio architecto. At facere ab tenetur!
                Quo est fugiat dolorem corrupti earum modi praesentium nobis debitis iste quidem tempore ipsa, dolor libero explicabo aspernatur voluptatibus cum non autem. Dolor magni laborum commodi perferendis sed porro recusandae!
                Necessitatibus cumque impedit sint! Vero quidem, alias iusto culpa cupiditate voluptate libero earum et animi exercitationem, harum mollitia minus ipsa quasi, omnis officia impedit placeat aut perspiciatis? Vitae, eos consectetur!
                Nesciunt, qui tenetur quo facilis nam expedita assumenda cumque sunt quis, nobis magnam officiis tempora? Sequi ea adipisci illo, dolorum vel eum quibusdam in eius architecto delectus quisquam non facere?
                Exercitationem mollitia sit nam assumenda odit, deserunt expedita perferendis ullam atque iusto nostrum tenetur fuga, vero enim. Necessitatibus eos accusamus illo cumque iure consequatur excepturi nobis rem porro? Nesciunt, ab?
                Maiores architecto tenetur sint odit inventore aspernatur maxime, neque ad blanditiis quam fugiat ratione distinctio unde dolorum rerum sapiente ipsam repudiandae vitae. Quae dicta velit, et facilis reprehenderit enim debitis?
                Dolores vero inventore in pariatur. Necessitatibus in nihil cupiditate, ut est esse hic dolorum autem quas labore nulla sit, eveniet ipsa odit ipsam quam atque vitae? Iste dolorem facilis accusamus.
                Laudantium quasi commodi facere explicabo rem ducimus laboriosam ea est? Accusamus architecto laborum culpa expedita iusto similique accusantium, atque rem inventore ipsum impedit earum cupiditate error corporis quisquam consequatur suscipit?
                Cumque tenetur ipsam, suscipit repellat assumenda, eveniet soluta nesciunt quasi aut eaque consectetur quaerat mollitia asperiores voluptas quibusdam totam fugit culpa error tempore inventore voluptatum! Sequi culpa commodi fuga ut?
                Voluptatem tenetur corrupti eaque dicta eligendi numquam tempora nesciunt? Animi, autem! Fugit eligendi odit ipsa voluptates aperiam numquam soluta similique. Quisquam corporis voluptate nihil accusantium quam eum praesentium autem laborum.
                Suscipit exercitationem hic eum at neque! Voluptatibus, voluptates expedita? Adipisci, nisi provident temporibus a sapiente voluptatum odio inventore quis perferendis sequi quia illo laudantium commodi maiores quas aut! In, numquam.
                Suscipit temporibus aliquam, laboriosam nemo delectus laborum obcaecati, qui rem nihil libero id itaque! Aperiam quasi quas numquam, consectetur debitis rem fuga aut natus odio? Optio itaque aliquam voluptates quidem?
            </div>

        </div>
    );
}




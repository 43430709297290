import { IUser } from '../../context';
import { castReturnValue, fsPaths, IDeletedUser, IGame, IPlayer, IResponse, useFirestore, useGames } from '../../shared';

export const useAdminRoles = () => {
    const {getRefGamesAsync, updateGamesAsync} = useGames();
    const {postWithoutTimestampAsync, deleteDocAsync, readAsync, deleteProfilePicWithIUserAsync} = useFirestore();
    
    const deleteUser = async (user: IUser) => {
        const dRes = await deleteDocAsync(fsPaths.users, user.uid);
            if(dRes.success) {
                // Create a deleteduser
                const duo: IDeletedUser = {
                    uid: user.uid,
                    email: user.email
                }
                const duoRes = await postWithoutTimestampAsync(fsPaths.deletedusers, duo);
                dRes.success = duoRes.success;
                dRes.exists = duoRes.exists;
                dRes.message = duoRes.message;
            } 
            return dRes;
    }
    const deleteUserAccountAsync = async (user: IUser): Promise<IResponse<boolean>> => {
        // Get storage pictures
        const ppRes = await deleteProfilePicWithIUserAsync(user);
        
        if(ppRes.message?.includes('picture') || ppRes.success) {
            return deleteUser(user);
        } 
        return ppRes;

    }

    const deletePlayerAccountAsync = async (player: IPlayer): Promise<IResponse<boolean>> => {
        const res = await removePlayerFromTournamentAsync(player.id as string);
        if(res.success) {
            const uRes = await readAsync<IUser>(fsPaths.users, player.uid);
            if(uRes.success && uRes.data) {
                const dUARes = await deleteUserAccountAsync(uRes.data);
                return dUARes;
            }
            return castReturnValue<IResponse<boolean>>(uRes, false);
        }
        return res;
    }

    const removePlayerFromTournamentAsync = async (playerId: string) => {
        return await deleteDocAsync(fsPaths.players, playerId);
    }

    const deleteRefAccountAsync = async (user: IUser): Promise<{success: boolean; msg: string;}> => {
        // get all the games not played that he is assigned to
        return new Promise(async (resolve, _) => {
            try {
                // Get storage pictures
                const ppRes = await deleteProfilePicWithIUserAsync(user);
                
                if(ppRes.message?.includes('picture') || ppRes.success) {
                    const res = await getRefGamesAsync(user.uid);
                    // Get games to be played
                    let gamesToBePlayed: IGame[] = [];
                    if(res.data) {  
                        gamesToBePlayed = res.data.filter(g => !g.isPlayed);
                    }
                    if(gamesToBePlayed.length) {
                        gamesToBePlayed.forEach(g => {
                            g.refIds = g.refIds.filter(id => id !== user.uid);
                        })

                        const res = await updateGamesAsync(gamesToBePlayed);

                        if(!res.success) return resolve({success: false, msg: res.message as string});
                    }
                    
                    const refRes = await deleteDocAsync(fsPaths.referees, user.refId as string);
                    if(refRes.success) {
                        // Delete IUser and save in deletedusers collection
                        // const dRes = await deleteDocAsync(fsPaths.users, user.uid);
                        // if(dRes.success) {
                        //     // Create a deleteduser
                        //     const duo: IDeletedUser = {
                        //         uid: user.uid,
                        //         email: user.email
                        //     }
                        //     const duoRes = await postWithoutTimestampAsync(fsPaths.deletedusers, duo);
                        //     if(duoRes.success) {
                        //         resolve({success: true, msg: 'Ref deleted successfully!'});
                        //     } else {
                        //         resolve({success: false, msg: duoRes.message as string});
                        //     }
                        // } else {
                        //     resolve({success: false, msg: dRes.message as string});
                        // }
                        const res = await deleteUser(user);
                        if(res.success) {
                            resolve({success: true, msg: 'Ref deleted successfully!'});
                        } else {
                            resolve({success: false, msg: res.message as string});
                        }
                    } else {
                        resolve({success: false, msg: `Deleting ref with Id of (${user.refId}) failed!`});
                    }

                } else {
                    resolve({success: false, msg: ppRes.message as string});
                }
            } catch (error) {
                const msg = (error as any).message;
                resolve({success: false, msg: msg});
            }
        })
    }

    
    return { 
        deleteRefAccountAsync,
        removePlayerFromTournamentAsync, 
        deletePlayerAccountAsync
   }
};

import React from 'react';
import { AccentButton, IPlayerGameStat, IScore, Pbutton } from '../../../../../../shared';
import { UpdateModal } from '../UpdateModal';
import { UpdateTeamScore } from './UpdateTeamScore';



interface IUpdateTeamScoreModal {
    score: IScore;
    playersStats: IPlayerGameStat[];
    onUpdate: (stats: IPlayerGameStat, isPositive: boolean) => void;
    // onSubmit: (v: IPlayerGameStat, close: ()=> void) => void;
    isHome?: boolean;
}
export const UpdateTeamScoreModal: React.FC<IUpdateTeamScoreModal> = ({score, playersStats, onUpdate, isHome = true}) => {
    
    const handler = (stat: IPlayerGameStat, isPositive: boolean) => {
        onUpdate(stat, isPositive);
    }
    const handleSubmit = async (close: () => void) => {
        close();
    }

    return (
        <UpdateModal 
            title={`Update '${score.teamName}' Scores`} 
            btnComponent={isHome ? 
                <AccentButton label={`${score.teamName}`} clicked={() => null} /> 
                : <Pbutton label={`${score.teamName}`} clicked={() => null} />}
            onSubmit={handleSubmit}> 
                <UpdateTeamScore playersStats={playersStats} onGoalScored={handler} />
        </UpdateModal>
    );
}
import {  useNavigate } from 'react-router-dom';
import { useAppSettings } from '../../../context';
import { Game, GameReadOnly, ITournament, Pbutton, ShadowCard, urls, useGamesRepo, WarnButton } from '../../../shared';
import './AdminGames.scss';

export const AdminGames = () => {
    const navigate = useNavigate();
    // const {gameid} = useParams();
    const { tournament } = useAppSettings();
    const { games } = useGamesRepo(tournament as ITournament);
    

    return (
        <div className='admin-games'>
           {
            // gameid ? <Outlet /> :
            <>
            <div className='top'>
                <WarnButton label='Assign Refs' clicked={() => navigate(urls.admin.games.refs, {state: {data: games}})} />
                <Pbutton label='Schedule' clicked={() => navigate(urls.admin.games.create)} />
            </div>

            <div>
                {games.map((game, idx) => {
                    const pps = {
                        key: idx,
                        className: 'scoreboargmgt',
                        game: game
                    }
                    return (
                        <ShadowCard key={idx}>
                            {game.isCancelled 
                            ? <Game key={idx} className="scoreboargmgt" game={game} onGameBoarding={() => console.log('gake')} /> 
                            : <GameReadOnly {...pps} />}
                            {/* <Game key={idx} className="admin-games" game={game} /> */}
                        </ShadowCard>
                    )
                })}
            </div>
            </>
            }
        </div>
    );
}



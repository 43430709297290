import { useState, useEffect } from 'react';
import { IGame, ITournament } from '../../../InterfacesOrTypes';
import { useGames } from './useGames';

export const useGamesRepo = (tournament: ITournament) => {
    const [games, setGames] = useState<IGame[]>([]);
    const {getAllGamesAsync} = useGames();
    useEffect(() => {
        (async function() {
            const res = await getAllGamesAsync();
            setGames(res.data || []);
        }())
    }, [tournament])
  return { games }
};
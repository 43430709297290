import { useEffect, useState } from 'react';
import { ALink, fsPaths, getDateMonthAndYrStringFromTimestamp, getDayTimeStringFromTimestamp, ITournament, ShadowCard, urls, useFirestore } from '../../../shared';
import './Tournaments.scss'

export const Tournaments = () => {
    const [tournaments, setTournaments] = useState<ITournament[]>([])
    const {readAllAsync} = useFirestore();

    useEffect(() => {
        (async function() {
            const res = await readAllAsync<ITournament>(fsPaths.tournaments);

            if(res.success) setTournaments(res.data || []);
        }())
    }, [])

    return (
        <div>
            <div className='header'>
            <ALink url={urls.admin.tournaments.create}>Add</ALink>
            </div>
            
            <div className="list">
                {tournaments.map((tournament, idx) => {
                    return (
                        <ShadowCard key={idx}>
                            <h2>{tournament.name}</h2>
                            <p>{getDayTimeStringFromTimestamp(tournament.startDate)}</p>
                            <p>{getDateMonthAndYrStringFromTimestamp(tournament.startDate)}</p>
                            <p><strong>${tournament.playerFee}</strong>/player</p>
                        </ShadowCard>
                    )
                })}
            </div>
        </div>
    );
}
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSettings } from '../../../../context';
import { AccentButton, DanButton, IGame, Pbutton, SecButton, urls, useGames } from '../../../../shared';
import { GamesScheduler } from './games-scheduler';

export const AdminCreateGame = () => {
    const navigate = useNavigate();
    const [games, setGames] = useState<IGame[]>([]);
    const [arrangedGames, setArrangedGames] = useState<IGame[]>([]);
    const { postAllGamesAsync, twoGamesFourTeams, allPlayAllSetup } = useGames();
    const { registeredTeams, remainingTeams} = useAppSettings();
    

    

    const setTwoGames = () => {
        const games = twoGamesFourTeams(remainingTeams);
        // const games = twoGamesFourTeams(registeredTeams);
        setGames(games);
    }

    const setAllPlayAll = () => {
        const games = allPlayAllSetup(remainingTeams);
        // const games = allPlayAllSetup(registeredTeams);
        setGames(games);
    }
    
    
    const clearArrangedGames = () => {
        setArrangedGames([]);
    }

    const selectClicked = (g: IGame) => {
        if(g.isSelected) {
            g.isSelected = false;
            setArrangedGames(state => {
                // All these games hasn't been saved yet so there is no 
                const idx = state.map(s => s.label).indexOf(g.label);
                return [...state.slice(0, idx), ...state.slice(idx + 1, state.length)]
            });
        } else {
            g.isSelected = true;
            setArrangedGames(state => ([...state, g]));
        }
    }
    
    const handleSubmit = async () => {
        let saveableGames = [...arrangedGames];
        saveableGames = saveableGames.map(x => {
            delete x.isSelected;// Not to be saved
            return x;
        })

        const res = await postAllGamesAsync(saveableGames);
        if(res.success) {
            navigate(urls.admin.games.base);
        }
    }


    const handleScheduleChange = (x: IGame[]) => {
        setArrangedGames(state => [...x]);
    }

    const show = (registeredTeams.length !== remainingTeams.length);
    return (
        <div>
            <div style={{color: show ? '#ccc' : 'inherit'}}>
                <h1>All Teams</h1>
                {registeredTeams.map((team, idx) => {
                    return (
                        <div key={idx}>
                            <h2>{idx + 1}. {team.name}</h2>
                        </div>
                    )
                })}
            </div>

            <div>
                {show && (
                    <>
                        <h1>Remaining Teams</h1>
                        {remainingTeams.map((team, idx) => {
                            return (
                                <div key={idx}>
                                    <h2>{idx + 1}. {team.name}</h2>
                                </div>
                            )
                        })}
                    </>
                )}
            </div>

            <div>
                <AccentButton label='2 Games Per Team' clicked={setTwoGames} />
                <Pbutton label='All Play All' clicked={setAllPlayAll} />
            </div>


            <div className="games">
                <h1>Proposed Games</h1>
                {games.map((game, idx) => {
                    return (
                        <div key={idx}>
                            <span style={{marginRight: '20px'}}>
                                { game.label }
                                {/* <p>{game.address?.name}</p> */}
                            </span>
                            {game.isSelected ? 
                            <SecButton label='Unselect' clicked={() => selectClicked(game)}/> :
                            <DanButton label="Select" clicked={() => selectClicked(game)} />}
                        </div>
                    )
                })}
            </div>

                
            <div>
                <h1>Game Scheduling</h1>
                
                {arrangedGames.length > 0 && (
                    <div className='game-scheduling'>
                        <GamesScheduler games={arrangedGames} onScheduleChange={handleScheduleChange} />

                        <div>
                            <SecButton label="clear" clicked={clearArrangedGames}/>
                            <Pbutton label="Submit" clicked={handleSubmit}/>
                        </div>

                    </div>
                )}

            </div>



            
        </div>
    );
}


import { useState } from "react";
import { AccentButton, IPlayerGameStat, SecButton } from "../../../../../../shared";

interface Props {
    playersStats: IPlayerGameStat[];
    onGoalScored: (stat: IPlayerGameStat, isPositive: boolean) => void;
}
export const UpdateTeamScore: React.FC<Props> = ({playersStats, onGoalScored}) => {
    const [stats, setStats] = useState<IPlayerGameStat[]>(playersStats.filter(s => s.isSignedIn && !s.redCard));
    
    const handleUpdate = (stat: IPlayerGameStat, index: number, isPositive: boolean = true) => {
        if(isPositive) stat.count += 1;
        if(!isPositive && stat.count > 0) stat.count -= 1;
        if(stat.count > -1) {
            setStats(state => ([...state.slice(0, index), stat, ...state.slice(index + 1, state.length)]))
            onGoalScored(stat, isPositive);
        };
    }

    return (
        <div>
            {stats && (<>
                <table className="aw-table update-team-score">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Goal(s)</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            stats.map((p, idx) => {
                                return (
                                <tr key={idx}>
                                    <td>
                                        {p.name}
                                    </td>
                                    <td>
                                        <strong>{p.count}</strong>
                                    </td>
                                    <td>
                                        <SecButton label="-1" clicked={() => handleUpdate(p, idx, false)} /> 
                                        <AccentButton label="+1" clicked={() => handleUpdate(p, idx)} />
                                    </td>
                                </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>)}
        </div>
    )
}
   
// import './TournamentPayment.scss'

import { useMemo } from "react";
import { IUser, useAppSettings } from "../../../context";
import { castReturnValue, IEmailForm, IObject, IPlayer, IResponse, useFirestore, usePlayer } from "../../../shared";
import { Payment } from "../Payment";

export const TournamentPayment = () => {
    const {tournament} = useAppSettings();
    // const {} = useFirestore();
    const { updatePlayerAsync } = usePlayer();

    const amount = useMemo(() => {
        return tournament?.playerFee || 0;
    }, [tournament]);

    const handle = async (v: IObject, user: IUser) => {
        const {method} = v;
        const values: IEmailForm = {
            user_name: `${user.firstName} ${user.lastName}`,
            user_email: `${user.email}`,
            message: `Payment made via ${method}`,
            subject:  `Tournament Payments`,
            payment_method: method
        }

        const res = await updatePlayerAsync(tournament?.id as string, {markedAsPaid: true} as IPlayer);
        return castReturnValue<IResponse<IEmailForm>>(res, values);
    }
    return (
        <div>
            <Payment amount={amount} handlePaymentAsync={handle} />
        </div>
    );
}

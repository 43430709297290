import { ALink } from '../../../shared';
// import './Paid.scss'

export const Paid = () => {
    return (
        <div>
            Payment

            <ALink url={`https://square.link/u/v9LaCO6F`}>Make Payment</ALink>
            <ALink url={`https://square.link/u/QUwW3YII`}>Make $1 Payment</ALink>
            
        </div>
    );
}
// import './RefGames.scss';
import { useEffect, useState } from "react";
import { useAppSettings, useAuth } from "../../../context";
import { IGame, useGames } from "../../../shared";
import { PrepGameForSignIn } from "../../components/games/PrepGameForSignIn";

export const RefGames = () => {
    const [games, setGames] = useState<IGame[]>([]);
    const {getRefGamesAsync} = useGames();
    const {user} = useAuth();
    const {tournament, handleLoading} = useAppSettings();

    // const handleGetStarted = (game: IGame) => {
    //     console.log(game);
    // }

    useEffect(() => {
        (async function(){
            if(user.isAuthenticated && tournament?.id) {
                const res = await getRefGamesAsync(user.uid);
                if(res.success && res.data) {
                    const currentGames = res.data.filter(g => !g.isPlayed);
                    setGames(currentGames);
                }
            }
        }())
        return () => {}
    }, [user, tournament])
    return (
        <div className="ref-games">
            <PrepGameForSignIn games={games} handleLoading={handleLoading} />
        </div>
    );
}

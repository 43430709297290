import { DanButton, IPlayerGameStat, useConfirm } from "../../../../../../../shared";
import { OffenseCard } from "../../OffenseCard";


interface Props {
    stats: IPlayerGameStat[];
    updateStats: (v: IPlayerGameStat[]) => void;
}
export const PlayerOffense: React.FC<Props> = ({stats, updateStats}) => {
    const getNStat = (stat: IPlayerGameStat) => ({...stat});
    const handleUpdate = (stat: IPlayerGameStat) => updateStats([stat]);

    const {getConfirmationAsync} = useConfirm();


    
    const handleRedCard = async (stat: IPlayerGameStat) => {
        if(await getConfirmationAsync('Are you sure its a red card offense?')) {
            const nStat = getNStat(stat);
            nStat.redCard = true;
            handleUpdate(nStat);
        }
    }


    const handleYellowCard = async (stat: IPlayerGameStat) => {
        if(await getConfirmationAsync('Are you sure its a yellow card offense?')) {
            const nStat = getNStat(stat);
            let yc = nStat.yellowCards || 0;
            yc += 1;
            nStat.yellowCards = yc;
            if(yc === 2) nStat.redCard = true;
            handleUpdate(nStat);
        }
    }
    
    return (
        <div>
            <table className="aw-table player-offfense">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Yellow Card</th>
                        <th>Red Card</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {stats && stats.filter(s => s.isSignedIn).map((stat, idx) => {
                        const yc = stat.yellowCards || 0;
                        return (
                            <tr key={idx}>
                                <td>{`${stat.name} (${stat.jerseyNumber})`}</td>
                                <td>
                                    <span  style={{
                                        display: 'flex', 
                                        justifyContent: 'space-evenly', 
                                        position: 'relative',
                                        top: '-2px'}}>
                                    {Array(yc).fill(null).map((_, idx) => <OffenseCard key={idx} />)}
                                    </span>
                                </td>
                                <td>
                                    {stat.redCard && <OffenseCard style={{background: 'red'}} />}
                                </td>
                                <td>
                                    {(yc < 2 && !stat.redCard) && <DanButton label="Yellow Card" style={{background: 'yellow', color: 'black', border: 'none'}} clicked={() => handleYellowCard(stat)} />}
                                    {!stat.redCard && <DanButton label="Red Card ?" style={{border: 'none'}} clicked={() => handleRedCard(stat)} />}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
import React from 'react';
import { notificationTypes } from '../../../../../context';
import { DanButton, fieldTypes, IGame, IPlayerGameStat, Pbutton, useAlert, useConfirm, useGames, useScores, useToastNotify, WarnButton } from '../../../../../shared';
import { ModalWithForm } from '../../../../admin/address/ModalWithForm';
import { useTardyTimer } from '../../game-refereeing/timer-hooks/useTardyTimer';
// import './GameButtons.scss'
interface Props {
    game: IGame;
    onUIGameUpdate: (g: IGame) => void;
    onLoading: (b: boolean) => void;
    onGameStarted:() => Promise<boolean>;
    onTardy: (teamId: string) => void;
    signInTeamPlayers: {
        [teamId: string]: IPlayerGameStat[];
    };
    goBack: () => void;
}
export const GameButtons: React.FC<Props> = ({ game, onUIGameUpdate, onLoading: handleLoading, onGameStarted, onTardy, signInTeamPlayers, goBack }) => {
    const { 
        cancelGameAsync, 
        endGameAsync, 
        markGameAsStartedAsync, 
        forfeitGameAsync, 
        resetGameForTestingAsync
    } = useGames();

    const { deleteScoresByGameIdAsync } = useScores();
    // did it
    useTardyTimer(game, signInTeamPlayers, onTardy);

    const {getConfirmationAsync} = useConfirm();
    const {handleToaster} = useToastNotify();
    const {alertUser} = useAlert();

    
    const handleCancelGame = async (v: {notes: string;} | any, close: () => void) => {
        handleLoading(true);
        const res = await cancelGameAsync(game.id as string, v.notes);
        handleLoading(false);
        if(res.success) {
            onUIGameUpdate({...game, ...res.data });
            close();
            handleToaster({msg: 'Game cancelled successfully!', type: notificationTypes.success});
            goBack();
        } else {
            handleToaster({msg: res.message as string, type: notificationTypes.error});
        }

    }

    const handleForfeitGame = async (v: {notes: string;} | any, close: () => void) => {
        handleLoading(true);
        const res = await forfeitGameAsync(game.id as string);
        handleLoading(false);
        if(res.success) {
            onUIGameUpdate({ ...game, ...res.data });
            close();
            handleToaster({msg: 'Game cancelled successfully!', type: notificationTypes.success});
            goBack();
        } else {
            handleToaster({msg: res.message as string, type: notificationTypes.error});
        }
    }
    

    const handleEndGame = async () => {
        // onUIGameUpdate({...game, isPlayed: true, endTime: new Date()})
        if(await getConfirmationAsync('Are you sure the game has ended?')) {
            handleLoading(true);
            const res = await endGameAsync(game.id as string);
            handleLoading(false);
            if(res.success) {
                onUIGameUpdate({...game, ...res.data})
                handleToaster({msg: 'Game ended successfully!', type: notificationTypes.success});
                return goBack();
            };
            handleToaster({msg: res.message as string, type: notificationTypes.error});
        }
    }

    const handleStartGame = async () => { 
        // onUIGameUpdate({...game, ...{isStarted: true, startTime: new Date()}});
        
        if(await getConfirmationAsync("Are you sure you have 'signed in' enough players for the game to start?")) {
            handleLoading(true);
            const canStart = await onGameStarted();// will set score (points) prop to
            if(canStart) {
                const res = await markGameAsStartedAsync(game.id as string);
                handleLoading(false);
                if(res.success) {
                    onUIGameUpdate({...game, ...res.data });
                    handleToaster({ msg: 'Good Luck!', type: notificationTypes.success});
                } else {
                    alertUser("Please ref the game manually if you try a couple of times and the game doesn't start. Sorry for the inconvenience :(")
                }
            } else {
                alertUser("Please refresh your page and try again. It seems the system is down!");
            }
            
            handleLoading(false);
        }
    }

    


    const handleReset = async () => {
        await deleteScoresByGameIdAsync(game.id as string);
        await resetGameForTestingAsync(game.id as string);
        goBack();
    }

    return (
        <div className='gbtns'>
            {game && !game?.isStarted && !game.isPlayed ? 
            <WarnButton label="Start Game" clicked={handleStartGame} /> 
            : <DanButton label="End Game" clicked={handleEndGame}  />}
            
            <div className='can-for'>
                <ModalWithForm 
                    title="Reason(s) For Cancellation" 
                    btnLabel="Cancel Game" 
                    onSubmit={handleCancelGame} 
                    fields={[{name: 'notes', type: fieldTypes.textarea }]} />

                <ModalWithForm 
                    title="Reason(s) For Forfeit" 
                    btnLabel="Forfeit" 
                    btnCompName={DanButton}
                    onSubmit={handleForfeitGame} 
                    fields={[{name: 'notes', type: fieldTypes.textarea }]} />
            </div>

            <Pbutton label='Restart' clicked={handleReset} />
        </div>
    );
}

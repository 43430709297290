import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IUser, notificationTypes, useAppSettings } from '../../../../context';
import { AwModal, DanButton, getDateMonthAndYrString, getDayTimeStringFromTimestamp, IGame, Pbutton, ShadowCard, useGames, useToastNotify } from '../../../../shared';
import { Dropdown } from '../../../../shared/forms/field/types';
import { ModalWithForm } from '../../address/ModalWithForm';
import { useReferees } from '../../referees/useReferees';
import './RefToGame.scss'

export const RefToGame = () => {
    const location = useLocation();
    const [games, setGames] = useState<IGame[]>([]);
    const [referees, setReferees] = useState<IUser[]>([]);
    const {handleLoading} = useAppSettings();
    const {handleToaster} = useToastNotify();
    const {getRefsAsync} = useReferees();
    const {updateGameAsync} = useGames();
    // const games: IGame[] = useMemo(() => {

    //     const { data: games } = (location.state as any);
    //     return games;
    // }, [location])

    
    const handleSubmit = (game: IGame) => {

    }

    const handleGameUpdateAsync = async (gameId: string, refIds: string[]) => {
        handleLoading(true)
        const obj = {refIds: refIds};
        const res = await updateGameAsync(gameId, obj);
        if(res.success) {
            handleToaster({msg: 'Game Ref record updated successfully!', type: notificationTypes.success});
        }
        handleLoading(false)
        if(res.success) return refIds;
        return null;
    }

    const handleAddRef = async (ref: IUser, game: IGame) => {
        let ids = [];
        if(game.refIds) {
            ids = game.refIds;
            ids.push(ref.uid);
        } else {
            ids.push(ref.uid);
        }

        if(await handleGameUpdateAsync(game.id as string, ids)) {
            game.refIds = ids;
        }
    }
    
    const handleRemoveRef = async (game: IGame, refUid: string) => {
        const ids = game.refIds.filter(id => id !== refUid);
        if(await handleGameUpdateAsync(game.id as string, ids)) {
            game.refIds = ids;
        }
    }


    useEffect(() => {

        (async function() {
            const res = await getRefsAsync();
            setReferees(res.data || []);
            const { data: games } = (location.state as any);
            setGames(games);
        }())

    }, [location])

    return (
        <div>
            {games.map((g, idx) => {
                return (
                    <ShadowCard key={idx}>
                        <div>
                            <div>{g.label}</div>
                            {/* <div>{getDayTimeStringFromTimestamp(g.gameDate)}</div> */}
                            <div>
                                <AwModal btnLabel='Add Ref' title='Add Ref to Game' onSubmit={() => handleSubmit(g)}>
                                    {(referees.filter(x => !(g.refIds || []).includes(x.uid))).map((ref, idx) => {
                                        return (
                                            <div key={idx}>
                                                <div>
                                                    <div>{ref.uid}</div>
                                                    <div>{ref.email}</div>
                                                </div>
                                                <div>
                                                    <Pbutton label='Add Ref' clicked={() => handleAddRef(ref, g)} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </AwModal>
                            </div>
                        </div>
                       <div>
                        {(g.refIds || []).map((uid, ind) => {
                            return <div key={ind}>{uid} <DanButton label='Remove' clicked={() => handleRemoveRef(g, uid)} /></div>
                        })}

                         {/* <span>
                            {g.refIds && <Dropdown vKey='uid' dKey='uid' list={g.refIds.map((uid) => {
                            return {uid: uid}
                            })} onSelected={v => console.log(v)} />}
                        </span> */}

                       </div>

                    </ShadowCard>
                )
            })}
        </div>
    );
}

import { onAuthStateChanged, User } from 'firebase/auth';
import { useState, useEffect, useRef, useMemo } from 'react';
import { auth } from '../../firebase-config';
import { fsPaths, IObject, urls, useFirestore } from '../shared';
import { IUser, userRoles } from './AuthContext';

export const useInitialAuthState = () => {
  const fbUserRef = useRef<User>();
  let initUser = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [manualAuth, setManualAuth] = useState<boolean>(false);
  const [rootUrl, setRootUrl] = useState<string>(urls.home);
  const [user, setUser] = useState<IUser>({isAuthenticated: false} as IUser);
  const { readAsync } = useFirestore();
//   useEffect(() => {
    
//     return () => {
//     };
//   });

// new Promise((resolve) => {
//     const unsubcribed = onAuthStateChanged(auth, async (currentUser) => {
//         if(currentUser) {
//             console.log(currentUser);
//             // setUser(currentUser);
//             await ru(currentUser);
//             resolve(currentUser);
//         }
//         resolve(null);
//         unsubcribed();
//     })
// }

// let initAuthPromise = useMemo(() => {
//     console.log('getting called')
//     return new Promise((resolve) => {
//         const unsubcribed = onAuthStateChanged(auth, async (currentUser) => {
//             if(currentUser) {
//                 await readUser(currentUser);
//                 initUser.current = (currentUser as any);
//                 resolve(currentUser);
//             }

//             unsubcribed();
//             resolve(null);
//         })
//     })
// }, [])


    useEffect(() => {
        const unsubcribed = onAuthStateChanged(auth, async (currentUser) => {
            const cu = fbUserRef.current;
            const exist = Object.keys(cu || {}).length > 1;
            if(!manualAuth) {
                if(!!currentUser ) {
                    fbUserRef.current = currentUser;
                    if(!user.isAuthenticated) {
                        await readUser(currentUser);
                    }
                }
                setManualAuth(true);
            }
            if(loading) {
                setLoading(false);
            }
        })
    
        return () => {
            unsubcribed();
        }
    }, [])
    



const handleSetLoggedInUser = (data: IObject, isLoggedIn: boolean = true) => {
    setUser(state => ({...state, isAuthenticated: isLoggedIn,  ...(data as any)}));
}

const readUser = async (u: User) => {
    const cu = await readLoggedInUser(u.uid, {
        profileImageUrl: (u.photoURL as any),
        providerData: u.providerData
    });
    return cu;
}

const readLoggedInUser = async (uid: string, uiphotoObj: {}) => {
    const uRes = await readAsync<IUser>(fsPaths.users, uid);
    if(uRes.success && uRes.data) {
        const cu = uRes.data as IUser;
        setRootUrl(cu.isAdmin ? 
            urls.admin.base 
            : (cu.roles.includes(userRoles.ref) || cu.roles.includes(userRoles.ar)) ? 
            urls.referee.dashboard 
            : (cu.roles.includes(userRoles.player)) ? 
            urls.player.dashboard : 
            urls.pagenotfound);
        handleSetLoggedInUser(Object.assign((uRes.data as any), uiphotoObj));
    }
    return uRes.data;
}

 

  return { initUser, user, fbUserRef, rootUrl, loading, setUser, readUser, setRootUrl, handleSetLoggedInUser }
};


// const promise = (ru: any) => new Promise((resolve) => {
//     const unsubcribed = onAuthStateChanged(auth, async (currentUser) => {
//         if(currentUser) {
//             console.log(currentUser);
//             // setUser(currentUser);
//             await ru(currentUser);
//             resolve(currentUser);
//         }
//         resolve(null);
//         unsubcribed();
//     })
// })
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DropdownField, fieldTypes, Form, fsPaths, generateRandomNumber, IField, ITeam, ITournamentTeam, teamClassification, urls, useAlert, useFirestore } from '../../../../shared';
import './CreateTeam.scss'


const options =  [
    {name:"guest"},
    {name: "home"}
    // {name: teamClassification.guest},
    // {name: teamClassification.home}
]

const cField: IField = {
    name: 'classified', 
    label: 'Classified As',
    required: true,
    dropdown: {
        dKey: 'name',
        vKey: 'name',
        options: options
    }
}
    

export const CreateTeam = () => {

const fields: IField[] = [
    {name: 'name', required: true},
    {name: 'coach', required: true},
    {name: 'mobile', required: true, type: fieldTypes.phone},
    {name: 'jerseyColor', required: false}
];
    const {state} = useLocation();
    
    const tournament = (state as any)?.data;
    const [values, setValues] = useState({});
    const { postAsync } = useFirestore();
    const navigate = useNavigate();
    const {alertUser} = useAlert();

    const updater = (field: any) => {
        setValues(state => ({...state, [field.name]: field.value}));
    }

    const handleSubmit = async (form: any) => {
        const x = {...form, ...values};
        x['name_normalized'] = x.name.normalize();
        if(!x['jerseyColor']) x['jerseyColor'] = 'unknown';
        const res = await postAsync(fsPaths.teams, x);
        if(res.success) {
            // team got created successfully
            // add tournamentteams with - teamid, tournamentid, passcode
            
            
            const tteam: ITournamentTeam = {
                teamId: res.data || '', 
                tournamentId: tournament.id, 
                eliminated: false,
                passcode: (generateRandomNumber() as any).toString(),
                name_normalized: `${tournament.name}`.normalize()
            }
            const tRes = await postAsync(fsPaths.tournamentTeams, tteam);
            if(tRes.success) {
                // navigate away
                alertUser('Team added successfully!');
                return navigate(urls.admin.teams.base);
            }
        }
    }

    return (
        <div>
            
            <Form formName='create-team' fields={fields} onSubmit={handleSubmit}>
                <DropdownField formName='create-team' options={options} field={cField} changeHandler={updater} />
            </Form>
        </div>
    );
}
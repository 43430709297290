import { insertHTMLPositions } from "../../../utils";

export const usePrompt = () => {
    const root = document.getElementById('content-root');
    // Get users input
    const getInputAsync = (header = "Please Enter a text") => {
        let prompt: any;
        root?.insertAdjacentHTML(insertHTMLPositions.firstChildOfAnEle, `
        <div class='alert-wrapper'>
            <div class='alert prompt'>
                <h2>${header}</h2>
                <label>Are you sure you want to proceed?</label>
                <input id='input-field'/>
                <div class="btns">
                    <button class='btn-secondary'>Cancel</button>
                    <button class='btn-danger'>Proceed</button>
                </div>
            </div>

            <style>
                    .alert-wrapper {
                        position: fixed;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0, 0.3);
                        z-index: 1000;
                    }
                    .alert {
                        user-select: none;
                        min-width: 350px;
                        max-width: 400px;
                        background: #fff;
                        border-radius: 6px;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        padding: 30px 30px;
                        box-shadow: 0 2px 5px rgba(0,0,0, 0.2);


                        z-index: 1000;
                    }
                    .alert img {
                        width: 100px;
                        margin-top: -80px;
                        box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
                        border-radius: 50%;
                    }
                    .alert label {
                        margin: 20px 0;
                        display: block;
                    }
                    .alert .btns {
                        display: flex;
                    }
                    .alert button {
                        width: 100%;
                        margin-top: 50px;
                        border: 0;
                        outline: none;
                        color: #fff;
                        // background: #6fd649;
                        padding: 10px 0;
                        cursor: pointer;
                
                        box-shadow: 0 5px 5px rgba(0,0,0, 0.09);
                    }

                    .alert button:first-child {
                        margin-right: 20px;
                        color: black;
                    }
                </style>
        </div>
        `)
        prompt = root?.firstElementChild;
        const btnsWrapper = prompt.querySelector('.btns');
        const cancel = btnsWrapper.firstElementChild;
        const proceed = btnsWrapper.lastElementChild;
        const input:HTMLElement | any = document.getElementById('input-field');
        return new Promise((resolve, reject) => {
            cancel.addEventListener('click', () => {
                resolve(null);
                prompt.remove();
            });
            proceed.addEventListener('click', () => {
                if(input?.value) {
                    resolve(input?.value);
                    prompt.remove();
                }
            });
        })
    }

    return { getInputAsync }
}

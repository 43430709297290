import React, { useRef, } from 'react';
import { FormModal, Pbutton } from '../../../../../shared';
// import './ModalWithForm.scss'

interface IUpdateModal {
    title: string;
    btnLabel?: string;
    btnComponent?: any;
    children: any;
    onSubmit: (close: ()=> void) => void;
}
export const UpdateModal: React.FC<IUpdateModal> = ({
    title, 
    btnLabel = "Submit", 
    btnComponent = <Pbutton label={btnLabel} clicked={() => alert('great')} />, children, onSubmit}) => {
    const ref = useRef();
    const openModal = () => {
        (ref.current as any).open();
    }

    const handleSubmit = async () => {
        onSubmit((ref.current as any).close);
    }
    
    return (
        <div>
            {React.cloneElement(btnComponent, {clicked: openModal})}
            <FormModal ref={ref} title={title} onSubmit={handleSubmit} settings={{onBkdrop: false}} >
                {children}
            </FormModal>
        </div>
    )
}

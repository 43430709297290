import React, { useState } from 'react';
import { AccentButton, AwError, IInputValue, IObject, IPlayerGameStat, IScore, Pbutton, TypeObject, UIPlayer } from '../../../../../../../shared';
import { UpdateModal } from '../../UpdateModal';
import { PlayerSignin } from './PlayerSignin';


interface IPlayerSigninModal {
    score: IScore;
    playersStats: IPlayerGameStat[];
    uiPlayers: UIPlayer[];
    onUpdate: (v: IPlayerGameStat[]) => void;
    onSubmit: (v: IPlayerGameStat[], close: ()=> void) => void;
    isHome?: boolean;
}

export const PlayerSigninModal: React.FC<IPlayerSigninModal> = ({score, playersStats, uiPlayers, onSubmit, onUpdate, isHome = true}) => {
    const [error, setError] = useState<string>("");
    const [values, setValues] = useState<IObject>({});
    const [statsToUpdate, setStatsToUpdate] = useState<TypeObject<IPlayerGameStat>>({})

    const handleSubmit = async (close: () => void) => {
        setError('');
        // newSignIns might have changes from old sign ins so check before saving
        // this is to help prevent saving duplicate stats
        const newSignIns: IPlayerGameStat[] = Object.values(values);
        // console.log(newSignIns);
        // const savedStats = playersStats;
        // const changedStats = newSignIns.filter(s => !!(
        //     savedStats.filter(d => d.uid == s.uid && d.jerseyNumber !== s.jerseyNumber
        //     )[0]
        //     ) 
        // )

        // console.log(changedStats);
        // return;
        const oldStats = Object.values(statsToUpdate);
        if(oldStats.length) onUpdate(oldStats);
        if(newSignIns.length) return onSubmit(newSignIns, () => close());

        if(!oldStats.length && !newSignIns.length) return setError('Please make the needed changes before submitting the form.');
        close();
    }
    
    const getStatModel = (player: UIPlayer, v: IInputValue) => {
        const model: IPlayerGameStat = {
            name: `${player.firstName} ${player.lastName}`,
            teamName: score.teamName,
            isSignedIn: true,
            jerseyNumber: v.value,
            tournamentId: score.tournamentId,
            teamId: score.teamId,
            uid: player.uid,
            gameId: score.gameId,
            count: 0
        }
        return model;
    }

    const handler = (player: UIPlayer, v: IInputValue) => {
        // setValues(x => ({...x, [v.name]: v.value}));
        setValues(x => ({...x, [player.uid]: getStatModel(player, v)}));
    }
    
    const handleStatsChanges = (player: UIPlayer, stat: IPlayerGameStat, isDelete: boolean = false) => {
        if(isDelete) return delete statsToUpdate[player.uid];
        setStatsToUpdate(state => ({...state, [player.uid]: stat}));
    }

    return (
        <UpdateModal 
            title={`${score.teamName} Player Sign In`} 
            btnComponent={isHome ? 
                <AccentButton label={`${score.teamName}`} clicked={() => null} /> 
                : <Pbutton label={`${score.teamName}`} clicked={() => null} />}
            onSubmit={handleSubmit}> 
                <AwError error={error} />
                <PlayerSignin stats={playersStats} data={uiPlayers} handleInputChange={handler} handleStatsChanges={handleStatsChanges} />
        </UpdateModal>
    )
}
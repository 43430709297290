import { insertHTMLPositions } from "../../utils";

export const useShowLargeImg = () => {
    const root = document.getElementById('content-root');
    // Get users input
    const showImage = (src: string) => {
        let prompt: any;
        root?.insertAdjacentHTML(insertHTMLPositions.firstChildOfAnEle, `
        <div class='alert-wrapper'>
            <div class='alert'>
                <img src=${src} alt="player picture" />
                <div class="btns">
                    <button class='btn-danger'>Close</button>
                </div>
            </div>

            <style>
                    .alert-wrapper {
                        position: fixed;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0, 0.3);
                        z-index: 1000;
                    }
                    .alert {
                        user-select: none;
                        min-width: 350px;
                        max-width: 400px;
                        background: #fff;
                        border-radius: 6px;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        padding: 15px;
                        box-shadow: 0 2px 5px rgba(0,0,0, 0.2);


                        z-index: 1000;
                    }
                    .alert img {
                        width: 100%;
                        height: 100%
                        box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
                    }
                    .alert label {
                        margin: 20px 0;
                        display: block;
                    }
                    .alert .btns {
                        display: flex;
                    }
                    .alert button {
                        width: 100%;
                        margin-top: 10px;
                        border: 0;
                        outline: none;
                        color: #fff;
                        padding: 10px 0;
                        cursor: pointer;
                
                        box-shadow: 0 5px 5px rgba(0,0,0, 0.09);
                    }

                </style>
        </div>
        `)
        prompt = root?.firstElementChild;
        const btnsWrapper = prompt.querySelector('.btns');
        const close = btnsWrapper.firstElementChild;
        const input:HTMLElement | any = document.getElementById('input-field');
        return new Promise((resolve, reject) => {
            close.addEventListener('click', () => {
                resolve(null);
                prompt.remove();
            });
        })
    }

    return { showImage }
}

import React from 'react';
import { AccentButton, IPlayerGameStat, IScore, Pbutton } from '../../../../../../../shared';
import { UpdateModal } from '../../UpdateModal';
import { PlayerOffense } from './PlayerOffense';

// import './PlayerOffenseModal.scss'
interface Props {
    stats: IPlayerGameStat[];
    score: IScore;
    onOffense: (v: IPlayerGameStat[], isRedCard?: boolean) => void;
    isHome?: boolean;
}

export const OffenseModal: React.FC<Props>  = ({stats, score, onOffense, isHome = true}) => {
    const handleSubmit = (close: () => void) => {
        // everything got submitted right after the change 
        close();
    }
    const handleOffense = (statArr: IPlayerGameStat[]) => {
        // statArr - its basically just one stat that has been put in an array
        onOffense(statArr, statArr[0].redCard);
    }
    return (
        <UpdateModal 
            title={`${score.teamName} Player Offense`} 
            btnComponent={isHome ? 
                <AccentButton label={`${score.teamName}`} clicked={() => null} /> 
                : <Pbutton label={`${score.teamName}`} clicked={() => null} />}
            onSubmit={handleSubmit}> 
                {/* <AwError error={error} /> */}
                <PlayerOffense stats={stats} updateStats={handleOffense}  />
        </UpdateModal>
    );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSettings } from "../../../../context";
import { hasGoals, IGame, IPlayerGameStat, IResponse, IScore, SecButton, ShadowCard, UIPlayer, useAlert, useGames, useParams, usePlayer, useScores } from "../../../../shared";
import { useGameTimer } from "./timer-hooks";

import './GameRefereeing.scss';
import { GameButtons, RecordOffense, SigninPlayers, UpdateTeamScoreModal } from "./components";


export const GameRefereeing = () => {
    const navigate = useNavigate();
    const {alertUser} = useAlert();
    const {gameid} = useParams();
    const { getGameByIdAsync } = useGames();
    const {
        getAllPlayersInATeamAsync, 
        getPlayersStatsAsync, 
        postPlayersStatsAsync, 
        getAllPlayerStatsAsync,
        updatePlayerStatsAsync,
        updatePlayersStatsAsync,
        updatePlayerAsync } = usePlayer();
    const {getScoresByGameIdAsync, adjustScoresAsync, updateScoresAsync} = useScores();
    const {handleLoading} = useAppSettings();
    const goBack = () => navigate(-1);
    const [scores, setScores] = useState<IScore[]>([]);
    const [teamUIPlayers, setTeamUIPlayers] = useState<{[teamId: string]: UIPlayer[]}>({});
    const [stats, setStats] = useState<{[teamId:string]: IPlayerGameStat[]}>({});
    const [game, setGame] = useState<IGame>();
    const {timer} = useGameTimer(game as IGame);
    

    const handleGameStarted = async () => {
        // when game starts set points NOT goals to 1 pt each;
        const hasRecords = hasGoals(scores);
        if(!hasRecords) {
            const oris = scores.map(s => {
                s.points = 1;
                return s;
            });
            const res = await updateScoresAsync(oris);
            if(res.success && res.data) {
                setScores(res.data);
            }
            return res.success;
        }
        return hasRecords;
    }


    const handleTardy = async (teamId: string) => {
        const index = scores.map(s => s.teamId).indexOf(teamId);
        if(index > -1) {
            const res = await updateScoreAsync(scores, index, true);
            if(res.success) {
                alertUser(`${scores[index].teamName} got a 'goal' for waiting 5 minutes.`);
            }
        }
    }


    const updateScoreAsync = async (scores: IScore[], index: number, isPositive: boolean) => {
        const sRes = await adjustScoresAsync(scores, index, isPositive)
        setScores(sRes.data || []);
        return sRes;
    }


    const handleScoreUpdate = async (score: IScore, index: number, isPositive: boolean, playerStat: IPlayerGameStat) => {
        
        handleLoading(true);
        const res = await updatePlayerStatsAsync(playerStat);
        if(res.success) {
            const sRes = await updateScoreAsync(scores, index, isPositive)
            if(!sRes.success) {
                isPositive ? playerStat.count -= 1 : playerStat.count += 1;
                await updatePlayerStatsAsync(playerStat);
            }
        }
        handleLoading(false);
    }
    

    const getStatsAsync = async (tournamentId: string, teamId: string, gameId: string) => {
        const res = await getPlayersStatsAsync(tournamentId, teamId, gameId);
        setStats(state => ({...state, [teamId]: res.data || []}));
    }
    
    const handleSignIn = async (score:IScore, gameStats: IPlayerGameStat[], close: () => void) => {
        const success = await postPlayersStatsAsync(gameStats);
        if(success) {
            await getStatsAsync(score.tournamentId, score.teamId, score.gameId);
            close();
        } else {
            // there was an error so stats needs to be saved again
            alertUser('Submission failed! If this continues please consult your system manager!')
        }
    }

    const handleUIGameUpdate = (nGame: IGame) => {
        setGame(nGame);
    }
    
    const handleStatsUpdate = async (stats: IPlayerGameStat[], isRedCard: boolean = false) => {
        if(isRedCard) {
            // Any stats from offense or score has only one item in the array is why i'm using [0]
            const stat = stats[0];
            const allTeamPlayers = teamUIPlayers[stat.teamId];
            const uiPlayer = allTeamPlayers.filter(p => p.uid === stat.uid)[0];
            const uRes = await updatePlayerAsync(uiPlayer.id as string, {hasRedCard: isRedCard});
            // update this to use uRes for something in case of update fail
        }

        const res = await updatePlayersStatsAsync(stats);
        if(res.success) await getStatsAsync(stats[0].tournamentId, stats[0].teamId, stats[0].gameId);
    }

    const getStatModel = (player: UIPlayer, score: IScore) => {
        const model: IPlayerGameStat = {
            name: `${player.firstName} ${player.lastName}`,
            teamName: score.teamName,
            isSignedIn: false,
            redCard: true,
            jerseyNumber: 0,
            tournamentId: score.tournamentId,
            teamId: score.teamId,
            uid: player.uid,
            gameId: score.gameId,
            count: 0
        }
        return model;
    }
    const flagRedCards = async (uiPlayers: UIPlayer[], score: IScore) => {
        const stats  = uiPlayers.map(up => getStatModel(up, score));
        await postPlayersStatsAsync(stats);
        try {
            const promises: Promise<IResponse<boolean>>[] = []
            uiPlayers.forEach(uip => {
                promises.push(updatePlayerAsync(uip.id as string, {hasRedCard: false}));
            })
            await Promise.all(promises);
        } catch (error) {
            // update function to do something in case of error
        }
    }

    useEffect(() => {
        (async function() {
            if(gameid) {
                const gRes = await getGameByIdAsync(gameid);
                if(gRes.success) setGame(gRes.data as IGame);
                const res = await getScoresByGameIdAsync(gameid);

                if(res.success) {
                    const scoreArray = res.data as IScore[];
                    setScores(scoreArray);
                    // Get players for Signing
                    const tournamentId = scoreArray[0].tournamentId;
                    const homePlayersRes = await getAllPlayersInATeamAsync(tournamentId, scoreArray[0].teamId);
                    const homePlayers = homePlayersRes.data || [];
                    const hpFlags = homePlayers.filter(p => !!p.hasRedCard);
                    const guestPlayersRes = await getAllPlayersInATeamAsync(tournamentId, scoreArray[1].teamId);
                    const guestPlayers = guestPlayersRes.data || [];
                    const gpFlags = guestPlayers.filter(p => !!p.hasRedCard);
                    // The above code check for red flags and creates stats accordingly

                    setTeamUIPlayers({[scoreArray[0].teamId]: homePlayers, [scoreArray[1].teamId]: guestPlayers})

                    // Get Player stats
                    const sRes = await getAllPlayerStatsAsync(tournamentId, scoreArray[0].teamId, scoreArray[1].teamId, scoreArray[1].gameId);
                    // running the flags here because I want to make sure that there is no stats
                    // before running it. 
                    // If there are stats the game has already begun or players has already been signed in
                    const data = sRes.data || {};
                    const homeScore = scoreArray[0];
                    const guestScore = scoreArray[1];
                    if(hpFlags.length && !data[homeScore.teamId].length) await flagRedCards(hpFlags, homeScore);
                    if(gpFlags.length && !data[guestScore.teamId].length) await flagRedCards(gpFlags, guestScore);
                    
                    if(sRes) setStats(data);
                }
            };
        }())
    }, [])

    useEffect(() => {
        if(timer.des.toLowerCase().includes("game")) {
            alertUser('Please stop the game!');
        }
    }, [timer.des])


    return (
        <div className='game-refereeing'>
            <div className='btn-wrapper' style={{textAlign: 'right'}}>
                <SecButton label='Go Back' clicked={goBack} />
            </div>


            <ShadowCard className="section game-scores">
                <h1>Score Updates</h1>
                <div>
                    <p><strong>{timer.time}</strong></p>
                    <p >{timer.des}</p>
                </div>
                    {scores.map((score, id) => {
                    return (
                        <div key={id} className="team-score">
                            <span> {score.teamName}: {score.goalsScored} </span>
                            {stats[score.teamId] && !!stats[score.teamId].filter(p => !!p.isSignedIn).length && <div>
                                <UpdateTeamScoreModal 
                                    score={score} 
                                    playersStats={stats[score.teamId]}  
                                    onUpdate={(stats: IPlayerGameStat, isPositive: boolean) => handleScoreUpdate(score, id, isPositive, stats)}
                                    isHome={!id} />
                            </div>}
                        </div>
                    )
                })}

                {game && <GameButtons 
                            game={game} 
                            onUIGameUpdate={handleUIGameUpdate} 
                            signInTeamPlayers={stats} 
                            onLoading={handleLoading} 
                            onGameStarted={handleGameStarted}
                            onTardy={handleTardy}
                            goBack={goBack} />}

            </ShadowCard>
            
            {
                scores.length && <>
                    <SigninPlayers uiPlayers={teamUIPlayers} onSignIn={handleSignIn} stats={stats} scores={scores} onStatsUpdate={handleStatsUpdate} />

                    <RecordOffense stats={stats} scores={scores} onStatsUpdate={handleStatsUpdate}/>
                </>
            }
            
        </div>
    );
}

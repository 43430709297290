import { useEffect, useRef } from 'react';
import { CloseIcon } from '../../../../../assets';
import { useAppSettings, useAuth } from '../../../../context';
import './PgNotification.scss'

export const PgNotification = () => {
    const ref = useRef<any>();
    const { notification: {isNotify, type, msg, canClose, timeout, link}, handleNotification } = useAppSettings();
    const { user } = useAuth();
    const handleClose = () => {
        handleNotification({isNotify: false});
    }
    useEffect(() => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: 'end',
            inline: 'nearest'
        })
        let timer:NodeJS.Timeout;

        if(msg && canClose && timeout) {
            timer = setTimeout(() => {
                handleNotification({msg: '', isNotify: false});
            }, timeout)
        }
        return () => {if(timer) clearTimeout(timer);}
    }, [msg])

    
    // if(!msg) return null;

    return (
        <div className='pg-relative' ref={ref}>
           {isNotify && user.isAuthenticated && (
             <div className={`pg-notification-wrapper ${type}`} role="alert">
                {msg}
                {link}
                {canClose && <span onClick={handleClose}>
                    <CloseIcon />
                </span>}
            </div>
           )}
        </div>
    );
}

import { IPlayerGameStat, IScore, ShadowCard, UIPlayer } from "../../../../../../shared";
import { PlayerSigninModal } from "./components";

interface Props {
    stats: {
        [teamId: string]: IPlayerGameStat[];
    };
    uiPlayers: {
        [teamId: string]: UIPlayer[];
    };
    scores: IScore[];
    onStatsUpdate: (v: IPlayerGameStat[]) => void;
    onSignIn: (score: IScore, gameStats: IPlayerGameStat[], close: () => void) => Promise<void>;
}

export const SigninPlayers: React.FC<Props> = ({stats, scores, uiPlayers, onStatsUpdate, onSignIn}) => {
 return (
    <>
        <ShadowCard className="section">
            <h1>Player Sign In</h1>
            <div className="modal-btn">
                <PlayerSigninModal 
                            onUpdate={onStatsUpdate}
                            playersStats={stats[scores[0].teamId]}   uiPlayers={uiPlayers[scores[0].teamId]} score={scores[0]} onSubmit={(stats, close) => onSignIn(scores[0], stats, close)} />

                <PlayerSigninModal onUpdate={onStatsUpdate} playersStats={stats[scores[1].teamId]} uiPlayers={uiPlayers[scores[1].teamId]} score={scores[1]} onSubmit={(stats, close) => onSignIn(scores[1], stats, close)} isHome={false} />
            </div>
        </ShadowCard>

    </>
 );
}

import React from 'react';
// import './Square.scss'
import { SquarePaymentForm } from './SquarePaymentForm';

export const Square = () => {
 return (
    <div>
        <SquarePaymentForm />
    </div>
 );
}
import { Outlet } from 'react-router-dom';
import { notificationLocations, useAppSettings } from './context';
import './Entry.scss';
import { BackdropBouncyLoader, CoverContainer, MobileHeader, Routes, setExtensionMethods } from './shared';
import { PgNotification } from './shared/components/notifications/page/PgNotification';


export const Entry = () => {
    setExtensionMethods();

    return <EntryWithLoading><Routes /></EntryWithLoading>;
}

const EntryWithLoading = ({...props}) => {
    const { loading } = useAppSettings();
    return (
        <div className="entry" style={{ WebkitTapHighlightColor: 'transparent' }}>
            {loading && <BackdropBouncyLoader />}
            {props.children}
        </div>
    )
}



export const Layout = () => {
    const {notification: {location: loc}} = useAppSettings();
    return (
        <>
            <MobileHeader />
            <CoverContainer {...{id: 'layout'}} className='layout'>
                {loc === notificationLocations.main && <PgNotification />}
                <Outlet />
            </CoverContainer>
        </>
    )
}

import { User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { IUser, notificationTypes, useAppSettings, userRoles } from '../../../context';
import { DanButton, fieldTypes, IField, ShadowCard, useToastNotify } from '../../../shared';
import { ModalWithForm } from '../address/ModalWithForm';
import { useAdminRoles } from '../useAdminRoles';
import './Referees.scss';
import { useReferees } from './useReferees';



export const Referees = () => {
    const {tournament, handleLoading} = useAppSettings();
    const {deleteRefAccountAsync} = useAdminRoles();
    const { pssRequired, getRefsAsync, getTournamentRefsAsync, reAuthenticateAsync, createAuthRef, createStoreRef, addRefToTournamentAsync} = useReferees(); 
    const [referees, setReferees] = useState<IUser[]>([]);
    const [tournamentRefs, setTournamentRefs] = useState<IUser[]>([]);
    const { handleToaster } = useToastNotify();




    const handleRefAccountDeletion = async (ref: IUser) => {
        if(await reAuthenticateAsync()) {
            // Delete the ref
            const res = await deleteRefAccountAsync(ref);
            
            if(res.success) {
                setTournamentRefs(state => ([...state.filter(x => x.uid !== ref.uid)]));
                handleToaster({msg: res.msg, type: notificationTypes.success});
            } else {
                handleToaster({msg: res.msg, type: notificationTypes.error})
            }
        }
    }
  
    const handleSubmit = async (v: any, close: () => void) => {
        if(await reAuthenticateAsync()) {
            // this means pss auth(not doing google auth at this point) succeeded
            handleLoading(true);
            const {email, mobile} = v;
            const res: any = await createAuthRef(email);
            if(res.created) {
                const createdUser = res.user as User;
                await createStoreRef(createdUser, [v.ar ? userRoles.ar : userRoles.ref], email, mobile, close);

                if(!!v.addToTournament) {
                    const refRes = await addRefToTournamentAsync(tournament?.id as string, createdUser);
                    if(refRes.success && refRes.data) {
                        setTournamentRefs(state => ([{...createdUser, mobile, refId: refRes.data as string} as any, ...state]));
                    }
                }
            }
            handleLoading(false);

        }

    }

    const getTRefs = async () => {
       const res = await getTournamentRefsAsync(tournament?.id as string);

       if(res.success && res.exists) {
            setTournamentRefs(res.data as IUser[]);
       }
    }

    

    useEffect(() => {
        if(tournament) getTRefs();
    }, [tournament])


    return (
        <div className="referees">
            {/* <CreateRefModal /> */}
            <div style={{textAlign: 'right'}}>
            <ModalWithForm 
                title='Add Referee' 
                btnLabel='Create' 
                onSubmit={handleSubmit} 
                fields={[
                    {name: 'email', type: fieldTypes.email, required: true}, 
                    {name: 'mobile', type: fieldTypes.phone, required: true},
                    {name: 'ar', label: "Select this for assistant refs", type: fieldTypes.radio},
                    {name: 'addToTournament', label: "Add ref to current tournament?", type: fieldTypes.radio},
                ] as IField[]} >
                    {pssRequired.is ? <p style={{color: 'orange'}}>
                    For security reasons you will be asked to re-signin with your email and password when you submit this form.
                  </p> :  <p style={{color: 'orange'}}>
                    For security reasons you will be asked to re-signin with google when you submit this form.
                  </p> }
                </ModalWithForm>
            </div>

            <div>
                <h1> Tournament Refs </h1>
                {tournamentRefs.map((ref, idx) => {
                    return (
                        <ShadowCard key={idx}>
                            <div>
                            <div>{ref.firstName} {ref.lastName}</div>
                            <small>{ref.email} - {(ref.mobile as any).phone()}</small>
                            </div>
                            <div>
                                <DanButton label='Delete Account' clicked={() => handleRefAccountDeletion(ref)}/>
                            </div>
                        </ShadowCard>
                    )
                })}
            </div>
{/* 
            <div>
                <h1>All Referees</h1>
                {referees.map((user, idx) => {

                    return (
                        <div key={idx}>
                            {user.mobile} 
                            <Pbutton label='add' clicked={() => addRefToTournamentAsync(tournament?.id as string, user as any)}/>
                        </div>
                    )
                })}
            </div> */}
        </div>
    );
}






// const useDeleteAccount = (deleteAsync: any, setStateState: any) => {
//     const [pssRequired, setPssRequired] = useState({is: false, pss: ''});  
//     const reAuthenticate = useRef<any>(null);


//     const resetAuth = () => reAuthenticate.current = null;
//     const getAuth = (checkGoogleAuth?: boolean) => {
//         if(reAuthenticate.current) return reAuthenticate.current;
//         reAuthenticate.current = reAuthAsync(checkGoogleAuth);
//         return reAuthenticate.current;
//     }

//     const handleRefAccountDeletion = async (ref: IUser) => {
//         const authenticateAsync = getAuth();
//         if(await authenticateAsync()) {
//             // Delete the ref
//             const res = await deleteRefAccountAsync(ref);
            
//             if(res.success) {
//                 setStateState(state => ([...state.filter(x => x.uid !== ref.uid)]));
//                 handleToaster({msg: res.msg, type: notificationTypes.success});
//             } else {
//                 handleToaster({msg: res.msg, type: notificationTypes.error})
//             }
//         }
//         resetAuth();
//     }


//     const reAuthAsync =  (checkGoogleAuth = true) => {
//         let counter = 3;// give user 3 attempts before logging him out on 3 failed attempts
//         return async function attempts(): Promise<any> {
//             if(counter) {
//                 if(pssRequired.is) {
//                     const pss: any = await getInputAsync("Enter your password");
//                     if(!pss) {
//                         counter -= 1;
//                         if(counter) await alertAttemptsAsync(counter);
//                         return attempts();
//                     };
//                     const pssRes = await emailPasswordReauthAsync(pss);
//                     if(!pssRes.success) {
//                         counter -= 1;
//                         if(counter) await alertAttemptsAsync(counter);
//                         return attempts();
//                     }
//                     setPssRequired(state => ({...state, pss: pss}));
//                     return true;
//                 } else {
//                     // returning true for google authentication
//                     if(checkGoogleAuth) {
//                         const gRes = await googleReauthAsync();
//                         if(!gRes.success) {
//                             counter -= 1;
//                             if(counter) await alertAttemptsAsync(counter);
//                             return attempts();
//                         }
//                     }
//                     return true; 
//                 }
//             } else {
//                 if(logoutAsync) await logoutAsync();
//             }
//         }
//     }

//     const deleteAccountAsync = async (user: IUser) => {
//         handleRefAccountDeletion(user);
//     }

//     return { deleteAccountAsync }
// }
import { IPlayerGameStat, IScore, ShadowCard } from '../../../../../../shared';
import { OffenseModal } from './components';


interface Props {
    stats: {
        [teamId: string]: IPlayerGameStat[];
    };
    scores: IScore[];
    onStatsUpdate: (v: IPlayerGameStat[]) => void;
}
export const RecordOffense: React.FC<Props> = ({stats, scores, onStatsUpdate}) => {
    return (
        <>
        {stats[scores[0].teamId] && !!stats[scores[0].teamId].filter(p => !!p.isSignedIn).length 
        && stats[scores[1].teamId] && !!stats[scores[1].teamId].filter(p => !!p.isSignedIn).length &&
        (
            <ShadowCard className="section">
                <h1>Player Offense Cards</h1>
                <div className="modal-btn">
                    <OffenseModal stats={stats[scores[0].teamId]} score={scores[0]}  onOffense={onStatsUpdate} />
                    <OffenseModal stats={stats[scores[1].teamId]} score={scores[1]} isHome={false} onOffense={onStatsUpdate} />
                </div>
            </ShadowCard>
        )
        }
        </>
    );
}
import { Outlet, useParams } from 'react-router-dom';
import { ScoreBoardMgt } from '../../../shared';
// import './AdminScoreBoardMgt.scss'

export const AdminScoreBoardMgt = () => {
    const {gameid} = useParams();
    return (
        <div>
            <Outlet />
            { !gameid && <ScoreBoardMgt />}
        </div>
    );
}

import { Standing } from '../../../shared';
import './AdminStandings.scss';

export const AdminStandings = () => {
    // query for all the scores in the tournament and move it around
 return (
    <div className="admin-standing">
        <p>Add a button that eliminates teams.</p>
        <p>Tournamentteams already has eliminated prop to make it easy.</p>
        <strong>Implement</strong>
        <Standing />
    </div>
 );
}
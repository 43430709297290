import { fsPaths, IField, IObject, useFirestore } from "../../../shared";
import { ModalWithForm } from "./ModalWithForm";

const addressFields: IField[] = [
    {name: 'name', required: false},
    {name: 'street', required: true},
    {name: 'aptSunit', required: false},
    {name: 'city', required: true},
    {name: 'state', required: true},
    {name: 'zipcode', required: true},
]
export const AddressFormModal = ({...props}) => {
    const { postAsync } = useFirestore();
    
    const handleSubmit = async (values: IObject, close: () => void) => {
        const res = await postAsync(fsPaths.addresses, values);
        if(res.success) {
            close();
        }
    }
    
    
    return (
        <ModalWithForm title="Address Form" btnLabel={props.btnLabel || "Add"} fields={addressFields} onSubmit={handleSubmit}/>
    )
}

import { User } from "firebase/auth";
import { collection, getDocs, getDoc, addDoc, doc, updateDoc, deleteDoc, serverTimestamp, CollectionReference, DocumentData, QuerySnapshot, Query, where, query, setDoc, DocumentReference, QueryConstraint } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { fbStorage, firestore } from "../../../../firebase-config";
import { IUser } from "../../../context";
import { IObject, IResponse } from "../../InterfacesOrTypes";
import { fbStoragePaths } from "../storage-repos";


// import { useEffect } from 'react';
export const fsPaths = {
    users: 'users',
    deletedusers: 'deletedusers',
    players: 'players',
    referees: 'referees',
    addresses: 'addresses',
    gameTypes: 'gametypes',
    games: 'games',
    playerStats: 'playerstats',
    scores: 'scores',
    tournaments: 'tournaments',
    teams: 'teams',
    tournamentTeams: 'tournamentteams'
}


export const useFirestore = () => {
  const navigate = useNavigate();
  const timestamp = serverTimestamp;

  const getDocsFromSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
    return (snapshot.docs.map(doc => ({id: doc.id, ...doc.data()})) as any[]);
  }

  async function getByQueryAsync<T>(query: Query<DocumentData>, all: boolean = true): Promise<IResponse<T>> {
      const output: any = {success: false, exists: false};

      if(all){ (output as IResponse<T[]>)} else { (output as IResponse<T>)}

      try {
          const snapshot = await getDocs(query);
          const data: T[] = getDocsFromSnapshot(snapshot);
          if(all) {
              output.data = data
              output.exists = !!data && data.length > 0;
          } else {
              output.data = data[0];
              output.exists = !!data[0] && Object.keys(data[0]).length;
          }
          output.success = true;
      } catch (error) {
          const msg = (error as any).message;
          output.message = msg;
          output.exist = false;
      }
      return output;
  }

  async function getByColAsync<T>(col: CollectionReference<DocumentData>, all: boolean = true): Promise<IResponse<T>> {
      const output: any = {success: false, exists: true};

      if(all){ (output as IResponse<T[]>)} else { (output as IResponse<T>)}

      try {
          const snapshot = await getDocs(col);
          const data: T[] = (snapshot.docs.map(doc => ({id: doc.id, ...doc.data()})) as any[]);
          if(all) {
              output.data = data
          } else {
              output.data = data[0];
          }
          output.success = true;
      } catch (error) {
          const msg = (error as any).message;
          output.message = msg;
      }
      return output;
  }


  // const readAllAsync = async (path: string): IResponse<T> => {
  //   try {
  //     const data = await getDocs(collection(firestore, path));
  //     const v: = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  //   } catch (error) {
  //     error.message
  //   }
  // }
   

  
  async function readAllAsync<T>(path: string): Promise<IResponse<T[]>> {
    const colRef = collection(firestore, path);
    return await getByColAsync(colRef);
  }

  async function readAsync<T>(path: string, id: string): Promise<IResponse<T | null>> {
    let output:IResponse<T > = {success: false, exists: true};
    try {
      const docRef = doc(firestore, path, id);
      const docSnap = await getDoc(docRef);
      let data;
      const exists = docSnap.exists();
      if (exists) {
        data = {...docSnap.data(), id: docSnap.id};
      } 
      output.success = true;
      output.exists = exists;
      output.data = (data as any);
      return output;
    } catch (error) {
      output.message = (error as any).message;
      return output;
    }



  }


  async function postAsync(path: string, obj: IObject): Promise<IResponse<string>> {
    let output:IResponse<string> = {success: false, exists: true};
    try {
      (obj as any)['createdTime'] = timestamp();
      const re = await addDoc(collection(firestore, path), obj);
      output.data = re.id;
      output.success = true;
      return output;
    } catch (error) {
      output.message = (error as any).message;
      return output;
    }
  }

  async function postWithoutTimestampAsync(path: string, obj: IObject): Promise<IResponse<string>> {
    let output:IResponse<string> = {success: false, exists: true};
    try {
      const re = await addDoc(collection(firestore, path), obj);
      output.data = re.id;
      output.success = true;
      return output;
    } catch (error) {
      output.message = (error as any).message;
      return output;
    }
  }


  async function postAndReadAsync<T>(path: string, obj: IObject): Promise<IResponse<T | null>> {
    let output:IResponse<T> = {success: false, exists: true};
    try {
      (obj as any)['createdTime'] = timestamp();
      const re = await addDoc(collection(firestore, path), obj);
      const readReponse = await readAsync<T>(path, re.id);
      if(readReponse.success) {
        return readReponse;
      } else {
        readReponse.message = "Record was added successfully but we couldn't retrieve it. Please reload your page.";
        return readReponse;
      }
    } catch (error) {
      output.message = (error as any).message;
      return output;
    }
  }


  async function postAllAsync<T>(records: T[], path: string): Promise<IResponse<T[]>> {
      return new Promise(async (resolve, reject) => {
          let output:IResponse<T[]> = {success: false, exists: false};
          try {
            const promises: Promise<DocumentReference>[] = [];
            records.forEach(record => {
              const docRef = collection(firestore, path);
              promises.push(addDoc(docRef, (record as any)));
            })
    

            const res = await Promise.all(promises);
            // const results = [];
            // res.forEach(f => {
            //   results.push(f);
            // })
            output.success = true;
            output.exists = true;
            output.data = (res as any);
            resolve(output);
          } catch (error) {
            const msg = (error as any).message;
            output.message = msg;
            reject(output);
          }
      })
  }

  async function postWithCustomIDAsync<T>(path: string, id: string, obj: IObject): Promise<IResponse<T>> {
    let output:IResponse<T> = {success: false, exists: true};
    try {
      (obj as any)['createdTime'] = timestamp();
      await setDoc(doc(firestore, path, id), obj);
      output.success = true;
      Object.assign(obj, {id: id})// adds id value to the original object
      output.data = (obj as T);
    } catch (error) {
      output.message = (error as any).message;
    }
    return output
  }
  async function updateAllAsync<T>(path: string, records: T[]): Promise<IResponse<boolean>> {
    return new Promise(async (resolve, reject) => {
      let output: IResponse<boolean> = {success: false, exists: false}
      try {
        const promises: Promise<void>[] = [];
        records.forEach((record: any) => {
          const docRef = doc(firestore, path, record.id);
          promises.push(updateDoc(docRef, record));
        })
        await Promise.all(promises);
        output.success = true;
        output.data = true;
        resolve(output);
      } catch (error) {
        output.message = (error as any).message;
        reject(output);
      }
    })
  }
  const updateDocAsync = async (path: string, id: string, props: IObject) : Promise<IResponse<boolean>> => {
    let output: IResponse<boolean> = {success: false, exists: true}
    try {
      const docRef = doc(firestore, path, id);
      await updateDoc(docRef, props);// returns nothing
      output.success = true;
      output.data = true;
      return output;
    } catch (error) {
      output.message = (error as any).message;
      return output;
    }
  }

  const deleteDocAsync = async (path: string, id: string) : Promise<IResponse<boolean>> => {
      let output: IResponse<boolean> = {success: false, exists: true}
      try {
        const docRef = doc(firestore, path, id);
        await deleteDoc(docRef); // returns nothing
        output.data = true;
        output.success = true;
        return output;
      } catch (error) {
        output.message = (error as any).message;
        return output;
      }
  }

  const deleteByWhereConstraintsAsync = (path: string, ...whereClauses: QueryConstraint[]): Promise<IResponse<boolean>> => {
    return new Promise(async (resolve, reject) => {
        let output: IResponse<boolean> = {success: false, exists: false}
        const q = query(collection(firestore, path), ...whereClauses);
        try {
          const snapshots = await getDocs(q);
          const promises:Promise<void>[] = [];

          snapshots.forEach(document => {
            const docRef = doc(firestore, path, document.id);
            promises.push(deleteDoc(docRef));
          })

          await Promise.all(promises);
          output.data = true;
          output.success = true;
          resolve(output);
        } catch (error) {
          output.message = (error as any).message;
          reject(output);
        }
    })
  }



  const updateUserRecordsAsync = async (path: string,uid: string,  updateObject: IObject): Promise<IResponse<boolean>> => {
    return new Promise( async (resolve, reject) => {
        let output: IResponse<boolean> = {success: false, exists: true}
        // get all collections of the user
        const q = query(collection(firestore, path), where('uid', '==', uid))
        try {
          const snapshots = await getDocs(q);
          const updatePromises: Promise<void>[] = [];
          snapshots.forEach(document => {
            const docRef = doc(firestore, path, document.id);
            updatePromises.push(updateDoc(docRef, updateObject))
          })
          await Promise.all(updatePromises);
          output.success = true;
          output.data = true;
          resolve(output);
        } catch (error) {
          const msg = (error as any).message;
          output.data = false;
          output.exists = false;
          output.message = msg;
          reject(output);
        }
    })
  }




  const deleteAllUserRecordsAsync = (path: string, user: User) => {
    return new Promise(async (resolve, reject) => {
        const q = query(collection(firestore, path), where('uid', '==', user.uid));
        try {
          const snapshots = await getDocs(q);
          const storePromises:Promise<void>[] = [];
          const storagePromises:Promise<void>[] = [];

          snapshots.forEach(document => {
            const docRef = doc(firestore, path, document.id);
            storePromises.push(deleteDoc(docRef));

            // user doesn't have lots of pictures except the profile pic
            // hence we will run the below outside of the for each
            const imgRef = ref(fbStorage, path);
            storagePromises.push( deleteObject(imgRef));
          })

          // const profilePath = `${fbStoragePaths.profiles}/${user.uid}/${image name will be needed}`
          // const imgRef = ref(fbStorage, path);
          // await deleteObject(imgRef);

          await Promise.all(storePromises);
          await Promise.all(storagePromises);

          // For now we dont care about the return value
          await deleteProfilePicWithUserAsync(user);

          resolve(true);

        } catch (error) {
          reject(error);
        }
    })
  }


  const deleteProfilePicWithUserAsync = async (user: User):  Promise<IResponse<boolean>> => {
    if(!user.photoURL) return {success: true, exists: false, msg: 'There is nothing to delete!'} as IResponse<boolean>;
    return await deleteProfilePic(user.uid, user.photoURL);
  }
  const deleteProfilePicWithIUserAsync = async (user: IUser): Promise<IResponse<boolean>> => {
    if(!user.profileImageUrl) return {success: true, exists: false, msg: 'There is nothing to delete!'} as IResponse<boolean>;
    return await deleteProfilePic(user.uid, user.profileImageUrl);
  }

  const deleteProfilePic = async (uid: string, imgUrl: string) => {
    const photoName = imgUrl.split(`${uid}%F`)[1]?.split('?')[0];
    if(photoName) {
      // using try catch because if the photoUrl is that of google and not in our storage
      // then the system will through an error and we dont want to stop the flow of operations
      try {
        const profilePath = `${fbStoragePaths.profiles}/${uid}/${photoName}`;
        const imgRef = ref(fbStorage, profilePath);
        await deleteObject(imgRef)
        return {success: true, exists: true, message: 'Image deleted successfully!'} as IResponse<boolean>;
      } catch (error) {
        const msg = (error as any).message;
        return {success: false, exists: false, message: msg};
      }
    }

    return {success: false, exists: false, message: "No picture found!"};
  }


  return { 
    deleteProfilePicWithIUserAsync,
    deleteAllUserRecordsAsync,
    navigate,
    readAllAsync, 
    readAsync, 
    postAsync, 
    postWithoutTimestampAsync,
    postAllAsync,
    postAndReadAsync, 
    postWithCustomIDAsync,
    updateDocAsync, 
    updateAllAsync,
    deleteDocAsync, 
    deleteByWhereConstraintsAsync,
    getByQueryAsync, 
    getByColAsync, 
    getDocsFromSnapshot 
  }
}
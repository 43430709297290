// interface IString {

import { Timestamp } from "firebase/firestore";
import { userRoles } from "../context";
import { IField } from "./forms";
import { IObject, IScore, ITournament } from "./InterfacesOrTypes";
import emailjs, {EmailJSResponseStatus} from '@emailjs/browser';

export interface IEmailForm {
    user_name: string;
    user_email: string;
    subject: string;
    message: string;
    payment_method?: string;
}
const sendEmail = async (funcAsync: any, values: any) => {
    try {
        const res: EmailJSResponseStatus =  await funcAsync(
            `${process.env.REACT_APP_TOURNAMENT_PAYMENT_EMAIL_SERVICE}`, 
            `${process.env.REACT_APP_TOURNAMENT_PAYMENT_EMAIL_TEMPLATE_SERVICE}`, 
            values,
            `${process.env.REACT_APP_EMAIL_SERVICE_PUBLIC_KEY}`);
            
        if(res.status < 400) {
            return {success: true, message: ''}
        } else {
            return {success: false, message: 'Email sending failure! Please try again later!'}
        }
    } catch (error: any) {
        return {success: false, message: error || 'Email sending failure! Please try again later!'}
    }
};

export const sendFormEmailAsync = async (form: HTMLFormElement) => { 
    return await sendEmail(emailjs.sendForm, form);
}
export const sendEmailAsync = async (values: IEmailForm) => {
    return await sendEmail(emailjs.send, values);
};


export const canEditScores = (roles: string[]): boolean => {
    const scoreEditRoles = [userRoles.admin, userRoles.ref, userRoles.ar];
    return !!(roles.find(role => scoreEditRoles.includes(role)));
}

export const hasGoals = (scores: IScore[]) => {

    const hasGoals = scores.some(s => s.goalsScored);
    const samePoints = scores[0].goalsScored === scores[1].goalsScored;
    return hasGoals;
}


export function castReturnValue<T>(res: any, data: any): T {
    const nRes: T = res;
    (nRes as any).data = data;
    return nRes;
} 


interface IPosition<T> {
    beforeAnEle: T;
    firstChildOfAnEle: T;
    lastChildOfAnEle: T;
    afterAnEle: T;
}
export const insertHTMLPositions: IPosition<any> = {
    beforeAnEle: 'beforebegin',
    firstChildOfAnEle: 'afterbegin',
    lastChildOfAnEle: 'beforeend',
    afterAnEle: 'afterend'
}

export const setExtensionMethods = () => {

    const type = (String.prototype as any);

    type.isEmpty = function () {
        return this.length === 0;
    };

    type.normalize = function() {
        return this.split(' ').join('').toLowerCase();
    }

    type.lowerCase = function() {
        return this.toLowerCase();
    }


    type.capitalizeFirstLetter = function() {
        if (this.isEmpty()) return '';
        return `${this[0].toUpperCase()}${this.slice(1).toLowerCase()}`
    }

    type.splitCamelCaseWordToCommaSeparatedString = function() {
        return this
            .split(/(?=[A-Z])/)
            .map((p: any) => {
            return p[0].toUpperCase() + p.slice(1);
            })
            .join(' ');
    }

    type.phone = function() {
        if(this.isEmpty()) return '';
        if(this.length === 3 || this.length < 4) return this;
        let phone = this;
        phone = phone.replace(/\D/g, '');
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            phone = `(${match[1]}${match[2] ? '' : ''}) ${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        }
        
        return phone
    }

    // String to numbers
    type.int = function() {
        return parseInt(this, 10) // +this;
    }
    type.float = function() {
        return parseFloat(this); // +this
    }

    type.number = function() {
        return +this;
    }

    // Number type
    const numType = (Number as any)

    numType.string = function(): string {
        return this.toString();
    }
}

// const splitCamelCaseWordToCommaSeparatedString = (str: string) => {
//     return str
//         .split(/(?=[A-Z])/)
//         .map((p) => {
//         return p[0].toUpperCase() + p.slice(1);
//         })
//         .join(' ');
// }

const validateEmail = (email: string) => {
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    return regex.test(email);
}

const checkPasswordValidation = (password: string) => {
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(password)) {
      return "Password must not contain Whitespaces.";
    }


    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(password)) {
      return "Password must have at least one Uppercase Character.";
    }


    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(password)) {
      return "Password must have at least one Lowercase Character.";
    }


    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(password)) {
      return "Password must contain at least one Digit.";
    }


    const isContainsSymbol =
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
    if (!isContainsSymbol.test(password)) {
      return "Password must contain at least one Special Symbol.";
    }


    const isValidLength = /^.{8,16}$/;
    if (!isValidLength.test(password)) {
      return "Password must be 8-16 Characters Long.";
    }

    return null;
}

const validatePasswords = (password: string, confirmPassword: string): any => {
    if(password !== confirmPassword) {
        return `Passwords do not match!`;
    }
    return checkPasswordValidation(password);
}
const noValue = (fieldName: string) => {
    return `Please provide a value in the (${fieldName}) field!`;
}
export const checkFormRequiredValidity = (values: {[x: string]: any;}, fields: IField[]) => {
    let output:string[] = [];
    for (let i = 0; i < fields.length; i++) {
        if(fields[i].required) {
            // if(!values[fields[i].name.toLowerCase()]) {
            const fieldValue = values[fields[i].name];
            const fieldName = fields[i].name;
            const type = fields[i].type || 'text';
            if(fields.length === 2 && 
                ((fieldName as any).lowerCase() === 'email' 
                    || (fieldName as any).lowerCase() === 'password')) {
                    if(type === 'password' && !!fields[i + 1]) {
                        const res: string | null = validatePasswords(fieldValue, values[fields[i + 1].name]);
                        if(res) {
                            output.push(res);
                            return output;
                        }
                    } else {
                        if(!fieldValue) {
                            output.push(noValue(fieldName));
                            return output;
                        }
                    }
                    
            } else if(fieldName === 'email') {
                if(!validateEmail(fieldValue)) {
                    output.push('Please provide a valid email address!');
                    return output;
                }
            } else if(fieldName === 'password') {
                const res: string | null = validatePasswords(fieldValue, values[fields[i + 1].name]);
                if(res){
                    output.push(res);
                    return output;
                }
            } else {
                if(typeof fieldValue == "boolean") {
                
                } else if(!fieldValue) {
                    output.push(noValue(fieldName));
                    return output;
                }
            }

        }
    }
    return output;
}

export const checkAccountValidity = (values: {[x: string]: any;}, fields: IField[]) => {
    let output:string[] = [];
    const passwordField = fields.find(f => (f.name as any).lowerCase() === 'password');
    const confirmPasswordField = fields.find(f => (f.name as any).lowerCase() === 'confirmpassword');

    const password = values[passwordField?.name || ''];
    const confirmPassword = values[confirmPasswordField?.name || ''];

    // console.log('password', passwordField, password)
    // console.log('confirmPassword', confirmPasswordField, confirmPassword)
    if(password !== confirmPassword) {
        output.push(`Passwords do not match!`);
        return output;
    }

    for (let i = 0; i < fields.length; i++) {
        if(fields[i].required) {
            // if(!values[fields[i].name.toLowerCase()]) {
            const fieldValue = values[fields[i].name];
            const fieldName = fields[i].name;
            if(typeof fieldValue == "boolean") {
                
            } else if(!fieldValue) {
                 output.push(`Please correct the value in (${fieldName}) field!`);
                 return output;
            }
        }
    }
    return output; 
}

export const getFormLabel = (name: any): string => {
    if(name.length < 5) return `${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`;
    return name.match(/[A-Z][a-z]+[0-9]+/g).join(" ") || "";
}

// export const createFormFields = (keys: string[], fieldKeyType?: IObject = {}) => {
//     return keys.map((str, i) => {
//         let key = { name: str, label: getFormLabel(), type: fieldTypes.text}
//     })
// }

// Dates 

function padTo2Digits(num: any) {
    return num.toString().padStart(2, '0');
  }
  
  function formatTime(milliseconds: number) {
    const seconds = Math.floor(milliseconds/1000);
    const s = Math.floor(seconds%60);
    const m = Math.floor((seconds%(60 * 60)) / 60);
    const h = Math.floor((seconds%(60 * 60 * 24))/(60*60));
    let formattedTime = h.toString().padStart(2, '0');
    formattedTime += ":";
    formattedTime += m.toString().padStart(2, '0');
    formattedTime += ":";
    formattedTime += s.toString().padStart(2, '0');
    return formattedTime;
  }
export function getGameTimer(d: any) {
    const date = convertToDate(d);
    const startTime = date.getTime();
    return function updateTimer() {
        const now = new Date().getTime();
        return formatTime(now - startTime);
    }
}
export function formatDate(d: Date) {
    const date = convertToDate(d);
    return [
        padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
}
export function convertTimestampToDateString(date: Timestamp | any): string {
    return formatDate(date);
}
export function convertTimestampToDateTimeString(date: Timestamp | any): string {
    return convertToDate(date).toTimeString();
}
function getDTString(date: Date): string {
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var d = date;
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
        (min as any) = "0" + min;
    }
    var ampm = "am";
    if( hr > 12 ) {
        hr -= 12;
        ampm = "pm";
    }

    return day + ", " + hr + ":" + min + ampm;
}

export function getDayTimeString(date: Date): string {
    return getDTString(date);
}

export function getDayTimeStringFromTimestamp(timestamp: Timestamp | any): string {
    return getDTString(convertToDate(timestamp));
}
function getDateMonthYrStr(x: Date): string {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var d = x;
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    return date + " " + month + " " + year;
}


export function getDateMonthAndYrString(date: Date): string {
    return getDateMonthYrStr(date)
}
export function getDateMonthAndYrStringFromTimestamp(timestamp: Timestamp | any): string {
    return getDateMonthYrStr(convertToDate(timestamp))
}


// export function getDateTimeMonthAndYrStringFromTimestamp(timestamp: Timestamp | any): string {
//     var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
//     var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
//     var d = convertTimestampToDateTime(timestamp);
//     var day = days[d.getDay()];
//     var hr = d.getHours();
//     var min = d.getMinutes();
//     if (min < 10) {
//         (min as any) = "0" + min;
//     }
//     var ampm = "am";
//     if( hr > 12 ) {
//         hr -= 12;
//         ampm = "pm";
//     }
//     var date = d.getDate();
//     var month = months[d.getMonth()];
//     var year = d.getFullYear();
//     return day + " " + hr + ":" + min + ampm + " " + date + " " + month + " " + year;
// }

function convertToDate(dateOrTimestamp: Timestamp | Date | any): Date {
    // console.log(dateOrTimestamp);
    if(dateOrTimestamp.seconds) {
        // this is for timestamps
        let seconds = dateOrTimestamp?.seconds * 1000;
        if(dateOrTimestamp.nanoseconds) {
            // adding nanoseconds to seconds
            const nanoseconds = (dateOrTimestamp?.nanoseconds / (1000*1000*1000));
            seconds = (seconds + nanoseconds);
            // console.log('ori-------------seconds', dateOrTimestamp.seconds);
            // console.log('ori-------------nanoseconds', dateOrTimestamp?.nanoseconds);
            // console.log('new-------------nanoseconds', nanoseconds);
            // console.log('new-------------seconds', seconds);
            // console.log('-------------total', seconds);
        }
        // console.log(new Date(seconds).toDateString());
        return new Date(seconds);//.toDateString()
    } else {
        return new Date(dateOrTimestamp);
    }
    // try {
    //     console.log('this is the time stamp coming in', dateOrTimestamp);
    //     console.log(new Date(dateOrTimestamp?.seconds * 1000).toDateString())
    //     console.log(new Date(dateOrTimestamp?.nanoseconds * 1000).toDateString())
    //     return new Date((dateOrTimestamp as Timestamp).toDate());
    // } catch (error) {
    //     console.log((error as any).message);
    //     return new Date(dateOrTimestamp);
    // }
}
export function convertTimestampToDateTime(date: Timestamp | any): Date {
    // console.log(date);
    // console.log(typeof date);
    // console.log(convertToDate(date));
    return convertToDate(date);
}

export function getAge(date: Timestamp | any) {
    var today = new Date();
    var birthDate = convertToDate(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }
    return age;
}

export function dateToTimeAgo(date: Date): string {
	const now = new Date(Date.now());
	const difftime = now.getTime() - date.getTime();
	const diffDate = new Date(difftime - 5.5 * 60 * 60 * 1000);
	const [sec, min, hr, day, month] = [
		diffDate.getSeconds(),
		diffDate.getMinutes(),
		diffDate.getHours(),
		diffDate.getDate() - 1,
		diffDate.getMonth(),
	];
	const f = (property: any, end: any) =>{
		// console.log(property,end)
		return`${property} ${end}${property > 1 ? "s" : ""} ago`;
	}
	// console.log(diffDate.toLocaleString());
	return month >= 1
		? f(month, "month")
		: day >= 1
		? f(day, "day")
		: hr >= 1
		? f(hr, "hr")
		: min >= 1
		? f(min, "min")
		: day >= 1
		? f(sec, "sec")
		: "";


	throw new Error("Date To time ago not implmented");
}

//  Generate numbers
export function generateRandomNumber() {
    var minm = 100000;
    var maxm = 999999;
    return Math.floor(Math
    .random() * (maxm - minm + 1)) + minm;
}








const forfeits = (tournament: ITournament) => {
    const requiredPlayersOnATeam = Number(tournament.gameType[0]);
    // as long as a team has 75% of their players they can play the game
    // no need to forfeit
    // 11 players come - 8 players
    // 6 players come - 4 players
    return Math.floor(requiredPlayersOnATeam * 0.75);
    // switch (tournament.gameTime) {
    //     case 40:
    //         // 10 minutes is forfeit
    //         // 5 minutes is 1 goal
    //         break;
    //     case 60:
    //         // 15 minutes is forfeit
    //         // 7 minutes is a goal
    //     default:
    //         break;
    // }
 }

 const rules = [
    "All starting players must be dressed up and sign in with the ref before the start of the game. Please be at the field at least 20 minutes before the start time",
    "Every 5 minutes after the game start time will attract a goal for the opposing team",
    `10 minutes of lateness after the game start time qualifies as a forfeit and the 3 points will go to the waiting team. 
    The waiting team can decide to take the forfeit or not. The waiting team needs to communicate their decision to the refs.
    Both teams can decide to play for fun or not.`,
    "A win is 3 points and a draw is 1 point to each team",
    "No disrespect to the refs. Insults, threats or any form of harassment towards the refs could result in a yellow card or a red card",
    "Retaliation on a player could result in a red card so please play professionally",
    "Red card means a player will miss the next game."
 ]
import { collection, getDocs } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { firestore } from '../../../../../firebase-config';
import { useAlert } from '../../../components';
import { IAddress } from '../../../InterfacesOrTypes';
import { fsPaths } from '../../firestore-repos';

export const useAddressesRepo = () => {
    const [addresses, setAddresses] = useState<IAddress[]>([]);
    const { alertUser } = useAlert();

    useEffect(() => {
        (async function() {
            try {
                const col = collection(firestore, fsPaths.addresses);
                const snapshot = await getDocs(col);
                const data: IAddress[] = (snapshot.docs.map(doc => ({id: doc.id, ...doc.data()})) as any[]);
                setAddresses(data || []);
            } catch (error) {
                alertUser((error as any).message);
            }
        }())
    }, [])

  return { addresses }
};

import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
// import './FourContentLayout.scss'
import styles from "./layouts.module.scss";

export const FourContentLayout = ({...props}) => {
   // const linkRef = useRef<any>();
    const {children} = props;
    const isOutlet =  children[3] && children[3].type.name === 'Outlet';


   //  useEffect(() => {
   //    const header = document.querySelector('.mobile-header');
   //    console.log(header)
   //    const observer = new IntersectionObserver(([entry]) => {
   //       console.log(entry);
   //    }, {
   //       root: header,
   //       rootMargin: '-90px'
   //    })
   //    console.log(linkRef.current.offsetHeight)
   //    observer.observe(linkRef.current);
   //  }, [])
    return (
        <div className={styles.Layout} >
         <div className={styles.Header}>
            <div className={styles.LeftContents}>
               {/* <div className={styles.HeaderTitle}>
                  <h2>User Profile</h2>
               </div> */}
               <div className={styles.LeftHeader}>
               {children[0]}
               </div>
            </div>
            <div className={styles.RightContents}>
                {children[1]}
            </div>
         </div>

         <div className={styles.Body}>
            <div className={styles.LeftContents} >
               <div className={styles.BodyNavs}>
                  {/* {profileLinks.map((item, idx) => {
                     return (
                        <div key={idx}>
                           {(urls.profile as any)[item.route] === urls.profile.info && urls.profile.base === location.pathname ? <ALink className={'active'} url={(urls.profile as any)[item.route]}>{item.label}</ALink> 
                           : <ALink url={(urls.profile as any)[item.route]}>{item.label}</ALink>}
                        </div>
                     )
                  })} */}

                  {children[2]}
               </div>
            </div>

            <div className={styles.BodyContent}>
               { isOutlet ? children[3] : <Outlet /> }
            </div>
         </div>
         
      </div>
    );
}
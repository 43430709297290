import { useState } from 'react';
import { ScoreList } from '../../../pages';
import { useLiveScoresRepo } from '../../cus-hook';
import { IGame } from '../../InterfacesOrTypes';
// import './ScoreBoard.scss'


export const ScoreBoard = () => {
    const [games, setGames] = useState<IGame[]>([]);
    // const [gamesScores, setGamesScores] = useState<IScore[][]>([] as IScore[][]);
    // const {tournament}
    const { scores } = useLiveScoresRepo();
    
    return (
        <div>
            <div>
                {scores.length > 0 ? <h2>Ongoing Games!</h2> : <h2>No Ongoing Games!</h2>}
                
                
                <ScoreList gamesScores={scores} />
            </div>
        </div>
    );
}

import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IUser, useAuth, userRoles } from "../../../../context";
import { IGame } from "../../../InterfacesOrTypes";
import { canEditScores } from "../../../utils";
import { AccentButton, SecButton, WarnButton } from "../../btns";
import { GameReadOnly } from "./GameReadOnly";
import './GameReadOnly.scss';

interface Props {
    game: IGame;
    className: string;
    onGameBoarding: (game: IGame) => void;
}
export const Game: React.FC<Props> = ({game, className, onGameBoarding}) => {
    const {user} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    
    
    const mainPath = useMemo(() => {
        const seg =location.pathname.split("/");
        const mainPath = seg[seg.length - 2];
        return mainPath;
    }, [])

    const action = useMemo(() => {
       return getGameAction(game, user);
    }, [game])

    const handleClicked = () => {
        let navUrl = `/${mainPath}/scoremgt/${game.id}`;
        if(user.roles.some(str => [userRoles.ar, userRoles.ref].includes(str))) {
            navUrl = `${game.id}`;   
        }
        navigate(navUrl, {state: {data: game}});
    }

    const handleDMClick = () => {
        console.log(game);
        alert('Havent implemented yet!');
    }


    const handleReschedule = (game: IGame) => {
        const url = `/${mainPath}/games/${game.id}/rescheduling`;
        navigate(url, {state: {data: game}});
    }
    
    return (
            <div 
                className={`editable-game ${className}`} >
                <GameReadOnly game={game} className={''} />
                {
                    action === gameActionType.reschedule ? 
                    (
                        <div>
                            <WarnButton clicked={() => handleReschedule(game)} label="Reschedule" />
                        </div>
                    ) 
                    : action === gameActionType.start ?
                    (
                        <div>
                            <AccentButton label="Get Started" clicked={() => onGameBoarding(game)} />
                        </div>
                    )
                    : action === gameActionType.ref ?
                    (
                        <div className="ref-btn">
                            <WarnButton label="Ref" clicked={handleClicked} />
                        </div>
                    )
                    : action === gameActionType.deadMatch ?
                    (
                        <div className="ref-btn">
                            <SecButton label="Dead Match" clicked={handleDMClick} />
                        </div>
                    )
                    : <></>
                }
            </div>
    )
}
const gameActionType = {
    start: 'start',
    reschedule: 'reschedule',
    ref: 'ref',
    deadMatch: 'deadmatch'
}

const getGameAction = (game: IGame, user: IUser): string => {
    const rescheduleRoles = [userRoles.admin, userRoles.manager];
    const canReschedule = rescheduleRoles.some(rr => user.roles.includes(rr));
    if(game.isPlayed) {
        return gameActionType.deadMatch;
    } else if(game.isCancelled && canReschedule) {
        return gameActionType.reschedule;
    } else if(!game.isCancelled && !game.signInStarted && canEditScores(user.roles)) {
        return gameActionType.start;
    } else if(!game.isCancelled && game.signInStarted && canEditScores(user.roles)) {
        return gameActionType.ref;
    }

    return "";
}




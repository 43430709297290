import React, { useEffect, useState } from 'react';
interface Props {
    data: any;
    children?: any;
}
export const FilterHoC: React.FC<Props> = ({data: init, children}) => {
    const [data, setData] = useState(init);
    const [filteredData, setFiltered] = useState();
    const [term, setTerm] = useState('')
    const handleSearch = (e: any) => {
        setTerm(e.target.value);
    }

    const sortByProp = (objArray: any[] = [], name: string, isDesc: boolean = false) => {
        let output: any[] = [];
        const itemList: string[] = [];
    
        const list: any[] = [...objArray];
        const _ = list.forEach(item => {
            const nameValue = item[name];
            if(!itemList.includes(nameValue)) {
                itemList.push(nameValue);
            }
        })
    
        let sortedList = itemList.sort();
        if(isDesc) {
            sortedList.reverse();
        }
    
        sortedList.forEach(itemName => {
            output = [...output, ...list.filter(item => item[name] === itemName)];
        })
        
        return output;
    }
    
    const filterList = (objArray: any[] = [], name: string) => {
        if(!name) return objArray;
        let list = [...objArray];
        const searchableKeys = Object.keys(list[0]);
        return list.filter(item => searchableKeys.some(k => item[k].toString().toLowerCase().includes(name.toLowerCase())));
    }
    useEffect(() => {
        setData(init);
    },[init])

    useEffect(() => {
        setData(filterList(init, term));
    },[term])
    
    return (
        <>
            <div>
                <input placeholder='Search...' onChange={handleSearch} />
            </div>
            {children(data)}
        </>
    )
}

import { useAddressesRepo } from "../../../shared";
import { AddressFormModal } from "./AddressFormModal";
import './Addresses.scss'


export const Addresses = () => {
    const {addresses} = useAddressesRepo();
    return (
        <div className="addresses">
            <AddressFormModal btnLabel={'Add New Address'} />
            <div className="address-list">
                {addresses.map((address, idx) => {
                    return (
                        <address key={idx}>
                            <label>{address.name}</label>
                            <div>
                                <p>{address.street}</p>
                                <p>{address.city}</p>
                                <p>{address.state}, {address.zipcode}</p>
                            </div>
                        </address>
                    )
                })}
            </div>
        </div>
    );
}
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context";
import { Game, IGame, IScore, ShadowCard, useAlert, useGames, useScores, useToastNotify } from "../../../shared";

interface Props {
    games: IGame[];
    handleLoading: (v: boolean) => void;
}

export const PrepGameForSignIn: React.FC<Props> = ({games:initGames, handleLoading}) => {
    const navigate = useNavigate();
    const {user} = useAuth();
    const {startGameSignInAsync} = useGames();
    const {postScoreAsync, deleteScoresByGameIdAsync} = useScores();
    const [games, setGames] = useState(initGames);

    const {alertUser} = useAlert();
    const {handleToaster} = useToastNotify();
    

    const getScoreModel = (game: IGame, teamName: string, teamId: string) => {
        return { 
            uid: user.uid, 
            teamId: teamId, 
            tournamentId: game.tournamentId, 
            gameId: game.id || '', 
            teamName: teamName, 
            goalsScored: 0, 
            goalsConcede: 0, 
            points: 0
        }
    }

    const handleOnBoarding = async (game: IGame) => {
        if(game.id) {
            handleLoading(true);
            const homeTeamScore: IScore = getScoreModel(game, game.home, game.homeTeamId);
            const guestTeamScore: IScore = getScoreModel(game, game.guest, game.guestTeamId);
            const hRes = await postScoreAsync(homeTeamScore);
            const gRes = await postScoreAsync(guestTeamScore);
            const isStarted = hRes.success && gRes.success;
            if(isStarted) {
                homeTeamScore.id = hRes.data;
                guestTeamScore.id = gRes.data;
                // game needs to be marked as started
                // after scores has been added successfully
                const sRes = await startGameSignInAsync(game.id);
                
                // set the new game to update component
                setGames(state => {
                    const index = state.map(g => g.id).indexOf(game.id);
                    if(index > -1) {
                        const nGame = {...game, signInStarted: true};
                        const nGames = [...state.slice(0, index), nGame, ...state.slice(index + 1)];
                        return nGames;
                    }
                    return state;
                });


                if(sRes.success) navigate(`${game.id}`);
                handleLoading(false);
                return handleToaster({type: 'success', msg: 'You can begin signing in players!'});
            } else {
                await deleteScoresByGameIdAsync(game.id as string);
            }

            handleLoading(false);
        } else {
            alertUser('There might be a problem with this game. Please try again later or consult your system admin.');
        }
    }

    useEffect(() => {
        setGames(initGames);
    }, [initGames])
    
    return (
        <div>
            {games.map((game, idx) => {
                // return <GameRefering key={idx} className="scoreboargmgt" game={game} />
                return <ShadowCard key={idx}>
                    <Game className="scoreboargmgt" game={game} onGameBoarding={handleOnBoarding}  />
                </ShadowCard>
            })}
        </div>
    )
}
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { notificationTypes, useAppSettings } from '../../../../context';
import { DanButton, formatDate, fsPaths, ICurrentTarget, IObject, IPlayer, Pbutton, useAlert, useConfirm, useFirestore, useToastNotify, WarnButton } from '../../../../shared';
import { RadioInput } from '../../../../shared/forms/field/types';
import { useAdminRoles } from '../../../admin/useAdminRoles';
import { useReAuth } from '../../../profile/useReAuth';
// import './PlayerEdit.scss'
const styles = {
    display: 'flex',
    lineHeight: 1.8
}
export const PlayerEdit = () => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const { updateDocAsync } = useFirestore();
    const [values, setValues] = useState<IObject>({});
    const { handleLoading } = useAppSettings();
    const { alertUser } = useAlert();
    const { getConfirmationAsync } = useConfirm();
    const { handleToaster } = useToastNotify();
    const { deletePlayerAccountAsync, removePlayerFromTournamentAsync } = useAdminRoles();
    const {reAuthenticateAsync} = useReAuth();


    const {keys, player} = useMemo(() => {
        let p = {} as IPlayer;
        let keys: string[] = [];
        if(state) {
            const x = (state as any).data;
            p = {...x};
            keys =  Object.keys(p).filter(x => !(!x.toLowerCase().startsWith('is') && x.toLowerCase().endsWith('id')));
            keys.forEach(k => {
                if(k.toLowerCase().includes('time') || k.toLowerCase().includes('date')) {
                    (p as any)[k] = formatDate((p as any)[k]);
                }
            })
        }
        return { keys, player: p};
    }, [])

    const handleChange = (v: ICurrentTarget) => {
        const {name, value} = v.currentTarget;
        setValues(state => ({...state, [name]: value}));
    }

    const handleSubmit = async () => {
        const vkeys = Object.keys(values);
        const hasChanged = vkeys.some(k => values[k] !== (player as any)[k]);
        if(vkeys.length > 0 && hasChanged) {
            handleLoading(true);
            const res = await updateDocAsync(fsPaths.players, player.id as string, values);
            handleLoading(false);
            if(res.success) {
                handleToaster({msg: "Player's record has been updated successfully!", type: notificationTypes.success});
                navigate(-1);
            } else {
                alertUser(res.message as string);
            }
        } else {
            alertUser('Nothing needs saving.');
        }
    }

    const handleDeleteAccount = async () => {
        if(await getConfirmationAsync("This player's records will be deleted permanently! Also you will have to reauthenticate to delete this record.")) {
            if(!!await reAuthenticateAsync()) {
                const res = await deletePlayerAccountAsync(player);
                if(res.success) {
                    navigate(-1);
                } else {
                    alertUser(res.message);
                }
            }
            
        }
    }

    const handleRemovePlayer = async () => {
        if(await getConfirmationAsync("This player will be removed from the current ongoing tournament!")) {
            if(!!await reAuthenticateAsync()) {
                const res = await removePlayerFromTournamentAsync(player.id as string);
                if(res.success) {
                    navigate(-1);
                } else {
                    alertUser(res.message);
                }
            }
        }
    }

    if(!state) {
        return navigate(-1);
    }

    return (
        <div>
            <h2>Save Any Updates</h2>
            {keys.map((k, idx) => {
                return (
                    <div key={idx} style={{...styles}} >
                        <strong>{k}</strong>:  
                        {typeof (player as any)[k] === 'boolean' ? <RadioInput init={(player as any)[k]} name={k} onChange={handleChange} /> : `${(player as any)[k]}`}</div>
                )
            })}
            <div>
            <DanButton label='Delete Player' clicked={handleDeleteAccount} />
            <WarnButton label='Remove Player From Tournament' clicked={handleRemovePlayer} />
            <Pbutton label='Save Changes' clicked={handleSubmit} />
            </div>
        </div>
    );
}

import { User } from 'firebase/auth';
import { collection, query, where } from 'firebase/firestore';
import { useRef } from 'react';
import { firestore } from '../../../../firebase-config';
import { IUser, notificationTypes, useAuth, userRoles } from '../../../context';
import { fsPaths, IResponse, useFirestore, useToastNotify } from '../../../shared';
import { useReAuth } from '../../profile/useReAuth';
export interface IReferee {
    id?: string;
    tournamentId: string;
    uid: string;
}

export interface UIReferee extends IUser, IReferee {

}

export const useReferees = () => { 
  const {getByQueryAsync, readAsync, postWithoutTimestampAsync} = useFirestore();
  const {handleToaster} = useToastNotify();
  const { user, fbUser, createStoreUserAsync, createAuthUserAsync} = useAuth();
  const currentUser = useRef(fbUser).current;
  const {pssRequired, reAuthenticateAsync} = useReAuth();

  




  const getTournamentRefsAsync = async (tournamentId: string) => {
        const output: IResponse<IUser[]> = {success: false, exists: false, data: []};
       try {
        const q = query(collection(firestore, fsPaths.referees), where('tournamentId', '==', tournamentId));
        const res = await getByQueryAsync<IReferee[]>(q);
       const refs = (res.data || []);
        const promises: Promise<IResponse<IUser | null>>[] = [];
        refs.forEach(ref => {
           promises.push(readAsync<IUser>(fsPaths.users, ref.uid));
        })

        const allRes = await Promise.all(promises);

        const data: IUser[] = [];
        const _ = allRes.filter(x => {
           if(x.data) {
               const iUser = x.data as IUser;
               refs.forEach(ref => {
                   if(ref.uid === iUser.uid) {
                       iUser.refId = ref.id;
                   }
               })
               data.push(iUser);
           }
        });
         
        //  allRes.forEach(eRes => {
        //     if(eRes.success) {
        //         data.push(eRes.data as IUser);
        //     }
        //  })

         output.data = data;
         output.success = true;
         output.exists = (data.length > 0);
         return output;
       } catch (error) {
            output.message = (error as any).message;
           return output;
       }
  }

  const getRefsAsync = async () => {
    const q = query(collection(firestore, fsPaths.users), 
    where('roles', 'array-contains-any', [userRoles.ref, userRoles.ar])
    )
    const res = await getByQueryAsync<IUser[]>(q);
    return res;
  }

  const addRefToTournamentAsync = async (tournamentId: string, createdUser: User) => {
    const referee:IReferee = {
        tournamentId: tournamentId,
        uid: createdUser.uid
    }
    return await postWithoutTimestampAsync(fsPaths.referees, referee);
  }

  const createAuthRef = async (email: string): Promise<{created: boolean; user: User;}> => {
    if(createAuthUserAsync) {
         const isPassword = !! pssRequired.pss;
         const signInData: any = isPassword ? {user: fbUser, password:  pssRequired.pss} : JSON.parse(JSON.stringify(fbUser));
        const nUser = await createAuthUserAsync(email, user.defaultPss as string, isPassword, signInData);
        if(nUser) {
             return {created: true, user: nUser};
         } else {
             handleToaster({msg: 'AuthUser creation failed!', type: notificationTypes.error});
             return {created: false, user: nUser};
         }
    }
    return {created: false, user: null as any};
 }


 const createStoreRef = async (createdUser: User, roles: string[], email: string, mobile: string, closeModal: any) => {
     const user:IUser = {email: email, mobile: mobile} as IUser;
     user.uid = createdUser.uid;
     user.isAdmin = false;
     user.waiverSigned = false;
     user.createdBy = currentUser.uid;
     user.isCoach = false;
     user.roles = roles;
     if(createStoreUserAsync) {
         const res = await createStoreUserAsync(user);
         if(res.success) {
             closeModal();
             handleToaster({msg: 'StoreUser created successfully!', type: notificationTypes.success});
         } else {
             handleToaster({msg: res.message as string, type: notificationTypes.error})
         }
     }
 }

 
  
  return { 
    pssRequired,
    reAuthenticateAsync,
    addRefToTournamentAsync, 
    getTournamentRefsAsync, 
    getRefsAsync,
    createAuthRef, 
    createStoreRef 
};
};
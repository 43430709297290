import { EmailAuthProvider, GoogleAuthProvider, reauthenticateWithCredential, reauthenticateWithPopup } from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../context';
import { useAlert, usePrompt } from '../../shared';
type IReAuthAttempts = () => Promise<any>;
export const useReAuth = (checkGoogleAuth?: boolean) => {
    const [pssRequired, setPssRequired] = useState({is: false, pss: ''});  
    let reAuthenticateRef = useRef<IReAuthAttempts>(null);
    const {fbUser: user, setGoogleCredentials, logoutAsync} = useAuth();
    const { alertUser } = useAlert();
    const {getInputAsync} = usePrompt();

    const googleReauthAsync = async () => {
        try {
          const res = await reauthenticateWithPopup(user, new GoogleAuthProvider());
          setGoogleCredentials(res);
          return {success: true, message: null};
        } catch (error) {
          return {success: false, message: (error as any).message};
        }
    }

    const emailPasswordReauthAsync =  async (password: string) => {
      const credentials = EmailAuthProvider.credential(user.email || "", password);
        // After this returns with a success then you navigate to the right page for the user to make the changes required
        try {
          const res = await reauthenticateWithCredential(user, credentials);
          return {success: true, message: null};
        } catch (error) {
          return {success: false, message: (error as any).message};
        }
    }

    const alertAttemptsAsync = async (counter: number) => await alertUser(`You have ${counter} attempts left!`);

    const reAuthAsync =  (checkGoogleAuth = true) => {
        let counter = 3;// give user 3 attempts before logging him out on 3 failed attempts
        return async function attempts(): Promise<any> {
            if(user) {
              if(counter) {
                if(pssRequired.is) {
                    const pss: any = await getInputAsync("Enter your password");
                    if(!pss) {
                        counter -= 1;
                        if(counter) await alertAttemptsAsync(counter);
                        return attempts();
                    };
                    const pssRes = await emailPasswordReauthAsync(pss);
                    if(!pssRes.success) {
                        counter -= 1;
                        if(counter) await alertAttemptsAsync(counter);
                        return attempts();
                    }
                    setPssRequired(state => ({...state, pss: pss}));
                    return true;
                } else {
                    // returning true for google authentication
                    if(checkGoogleAuth) {
                        const gRes = await googleReauthAsync();
                        if(!gRes.success) {
                            counter -= 1;
                            if(counter) await alertAttemptsAsync(counter);
                            return attempts();
                        }
                    }
                    return true; 
                }
            } else {
                alertUser('For security reasons we logged you out of the account!');
                if(logoutAsync) await logoutAsync();
            }
          }
        }
    }

    useEffect(() => {
      (reAuthenticateRef as any).current = reAuthAsync(checkGoogleAuth);
    }, [])

    useEffect(() => {
        const isPassword = !!(user && user.providerData && user.providerData[0] && user.providerData[0].providerId === 'password');
        setPssRequired(state => ({...state, is: isPassword}));
    }, [user])




    return { pssRequired, reAuthenticateAsync: reAuthenticateRef.current as  IReAuthAttempts}
};

import { useNavigate } from 'react-router-dom';
import { Form, FormHeader, Upload, urls } from '../../../../shared';
// import './PlayerPasscode.scss'

export const PlayerPicture = () => {
   const navigate = useNavigate();
   const handleUpload = () => {
      // Image has been uploaded successfully
      
      navigate(urls.player.dashboard, { replace: true });
   }

    // This is to help prevent users from navigating through the address bar
   //  if(!team) return (<Navigate to={urls.home} replace />)
   return (
      <div>
         <Form fields={[]} formName="player picture" settings={{showBtn: false, btnLabel: 'Upload'}}>
            <FormHeader title='Player Picture' />
            <small>Please make sure to capture your face because we will use this picture as a form of identification before the actual game.</small>
            <Upload onUploadComplete={handleUpload} isProfile={true}/>
         </Form>
      </div>
   );
}

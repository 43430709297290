import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IPlayer, ITeam, ITournament, Pbutton, SecButton, urls, useConfirm } from "../../../../shared";
import { Payment } from "../../../payment";

const styles = {
    lineHeight: '2rem'
}
export const PlayerPayment = () => {
    const {team, tournament, player} = useOutletContext<{team: ITeam, tournament: ITournament, player?: IPlayer;}>()
    const navigate = useNavigate();
    const {getConfirmationAsync} = useConfirm();
    const [searchParams] = useSearchParams();
    console.log(searchParams);


    const handleSkip = async () => {
        if(await getConfirmationAsync('Please remember only paid members are allowed to play!')) {
            navigate(urls.playerregistration.picture, {state: {data: {team, tournament}}});
        }
    }
    
    const handlePaid = () => {
        navigate(urls.playerregistration.picture, {state: {data: {team, tournament}}});
    }

    return (
       <div style={styles}>
            <h1 style={{textAlign: 'center'}}>Player Payment</h1>
            <div>
                {/* <Payment amount={tournament.playerFee}/> */}
                <footer style={{textAlign: 'right'}}>
                    <small style={{display: 'block', textAlign: 'center', color: '#70d282'}}>Please remember you will only be added to the team or receive notification on games after payment has been made and your picture has been taken.</small>
                    <SecButton style={{background: 'transparent'}} label="Skip Now" clicked={handleSkip} />
                    <Pbutton label="Paid ?" clicked={handlePaid} />
                </footer> 
            </div>
       </div>
    );
}
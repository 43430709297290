import React from 'react';
import { useAppSettings } from '../../../../../context';
import { convertTimestampToDateTime, GameReadOnly, IAddress, IGame, IInputValue, useAddressesRepo } from '../../../../../shared';
import { Dropdown } from '../../../../../shared/forms/field/types';
import { DateTimeInput } from '../../../../../shared/forms/field/types/date';
import './GamesScheduler.scss'

interface Props {
    games: IGame[];
    onScheduleChange: (games: IGame[]) => void;
    children?: any;
}

export const GamesScheduler: React.FC<Props> = ({games, children, onScheduleChange}) => {
    const {addresses} = useAddressesRepo();
    const {tournament} = useAppSettings();

    const handleAddressChange = (game: IGame, index: number, address: IAddress) => {
        if(game.address || address.id !== tournament?.defaultParkId) {
            // above condition is to make sure that the address value is not set on the game
            // esp if the tournament object has the same address.
            if(address.id === tournament?.defaultParkId) {
                // this means game already has an address on it
                // remove that addresss and don't set any address
                // since the new address is one we already have on the tournament
                delete game.address;
            } else {
                Object.assign(game, {address});
            }
            games[index] = game;
            // delete address prop if its the same as the tournament address
            return onScheduleChange(games);
        }
    }

    const handleDateTimeChange = (game: IGame, index: number, v: IInputValue) => {
        Object.assign(game, {[v.name]: v.value});
        games[index] = game;
        onScheduleChange(games);
    }


    return (
        <div className="games-scheduler">
            {games.map((game, idx) => {
                return (
                    <div key={idx} className="game-editor">
                        <GameReadOnly className='scheduler' game={game} />
                        <div>
                            <DateTimeInput 
                                id={`${idx}`} 
                                name='gameDate' 
                                init={convertTimestampToDateTime(game.gameDate)} 
                                onChange={(v) => handleDateTimeChange(game, idx, v)} />
                                
                            {addresses.length && <Dropdown 
                                dKey={"name" || ''} 
                                vKey={"id" || ''} 
                                list={addresses} 
                                shouldSelectValue={false}
                                onSelected={(address) => handleAddressChange(game, idx, address)} />}

                                {children}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IGame, IScore, ShadowCard, useGames } from '../../../../shared';
import { useGameTimer } from '../game-refereeing/timer-hooks';
import './ScoreList.scss';

/// These components only show live scores and nothing else

interface SProps {
    gamesScores: IScore[][];
}
export const ScoreList: React.FC<SProps> = ({gamesScores}) => {
 return (
    <div className='list-of-scores'>
        {gamesScores.map((scores, idx) => {
            return <GameScore key={idx} scores={scores} />
        })}
    </div>
 );
}


interface GProps {
    scores: IScore[];
}
export const GameScore: React.FC<GProps> = ({scores}) => {
    const ref = useRef<any>();
    const [game, setGame] = useState<IGame>();
    const {timer} = useGameTimer(game as IGame);
    const {getGameByIdAsync} = useGames();

    const mappedScores = useMemo(() => {
        // const mapped = ['Home', 'Away'].map((team, idx) => <TeamName  key={idx} name={team} />)
        const mapped = scores.map(score => <TeamName  key={score.id} name={score.teamName} scores={scores} />)
        // const mapped = scores.map(score => <TeamName  key={score.id} name={score.teamName} />)
            mapped.splice(1, 0, (
                <div key={1} className='scoring'>
                    <div className="score">{scores[0].goalsScored}</div>
                        <div className="time">
                            <div className='time-wrapper'>
                            <div className='timer'>00:00:00</div>
                            <div className='time-des'>
                                Game Over
                            </div>
                            </div>
                        </div>
                    <div className="score">{scores[1].goalsScored}</div>
                </div>
        ))
        
        return mapped;
    }, [scores])

    const mappedTeamNames = useMemo(() => {
        const names = scores.map((score:IScore, idx: number) => {
            // check length and add empty letters
            let teamName = score.teamName;
            
            return <span key={score.id}>{teamName}</span>
        });
        names.splice(1,0, (
            <strong key={1}>
                <h2 style={{color:'red', display: 'inline-block', margin: '10px'}}> VS </h2>
            </strong>
        ));
        return names;
    }, [scores])



    useEffect(() => {

        (async function(){
            if(scores.length) {
                const gameId = scores[0].gameId;
                const res = await getGameByIdAsync(gameId);
                if(res.success) {
                    setGame(res.data as IGame);
                }
            }
        }())
    }, [])

    useEffect(() => {
        if(ref.current) {
            const ele = ref.current;
            const time = ele.querySelector('.timer');
            const des = ele.querySelector('.time-des');
            time.innerText = timer.time;
            des.innerText = timer.des;
        }
        
    }, [timer])



    return (
        <div ref={ref}>
            <ShadowCard className='game-score'>
                <div className="gd-wrapper">
                    <div className='detail-display'>{mappedTeamNames}</div>
                    <div className="game-display">
                        <span>
                            <div className='teams'>
                                {mappedScores}
                            </div>
                        </span>
                    </div>
                </div>
            </ShadowCard>
        </div>
    )
}


const getTeamABRV = (teamName: string) => {
                return teamName
                    .split(' ')
                    .filter((w: string) =>  w.toLowerCase() !== 'fc')
                    .map((w: string) => w[0])
                    .join('');
}
const TeamName = ({...props}) => {
    const {name: realName, scores} = props;
    
    const name = getTeamABRV(realName);
    
                    // if(idx) {
                    //     const prev = scores[idx - 1];
                    //     const len = prev.teamName.length;
                    // } else {
                    //     const next = scores[idx + 1];
                    //     const len = next.teamName.length;
                    // }
    const otherTeam = (scores as IScore[]).filter(s => s.teamName !== realName)[0].teamName;
    const otAbr = getTeamABRV(otherTeam);
    const len = otAbr.length - name.length;
    return (
        <div className="team-name">
            {len > 0 ? <span style={{padding: `0 ${len * 4}px`}}>{name}</span>
            : <span>{name}</span>}
        </div>
    )
}



// interface Props {
//     score: IScore;
// }
// export const Score: React.FC<Props> = ({score}) => {
//     return (
//         <div>
//             <h2>{score.teamName}</h2>
//             <strong> {score.goalsScored} </strong>
//         </div>
//     )
// }

import { useAppSettings } from '../../../context';
import { PrepGameForSignIn } from '../../../pages/components/games/PrepGameForSignIn';
import { useGamesRepo } from '../../cus-hook';
import { ITournament } from '../../InterfacesOrTypes';


export const ScoreBoardMgt = () => {
    const {tournament, handleLoading} = useAppSettings();
    const {games} = useGamesRepo(tournament as ITournament);
   
   return (
      <div>
        <h1>Games For Today</h1>
        <PrepGameForSignIn games={games} handleLoading={handleLoading}  />
      </div>
   );
}


import { useRef } from "react";
import { IInputValue, IPlayerGameStat, UIPlayer, useShowLargeImg } from "../../../../../../../shared";
import { NumberInput } from "../../../../../../../shared/forms/field/types";
import { OffenseCard } from "../../OffenseCard";

interface Props {
    data: UIPlayer[];
    stats: IPlayerGameStat[];
    handleInputChange: (player: UIPlayer, v:IInputValue) => void;
    handleStatsChanges: (player: UIPlayer, stat: IPlayerGameStat, isDelete?: boolean) => void;
}

export const PlayerSignin: React.FC<Props> = ({data: players, stats, handleInputChange, handleStatsChanges}) => {
    const imgRef = useRef<any>();
    const { showImage } = useShowLargeImg();
    const handleSignedInChange = (player:UIPlayer, stat: IPlayerGameStat, init: any, {value}: IInputValue) => {
        // all signed in player jersey changes go through this func
        // it it will only need to be updated and not created
        // console.log(stat, init, value)
        // it will remove stats from the list to be updated if init is same as value;
        const nStat = {...stat};
        nStat.jerseyNumber = value;
        handleStatsChanges(player, nStat, init === value);
    }

    const handleNewSignIn = (player:UIPlayer, v: IInputValue) => {
        handleInputChange(player, v)
    }


    const viewLargeImage = (img?: string) => {
        if(img) {
            showImage(img);
        }
    }

    return (
        <div>
            {players && <>
                <table className="aw-table player-signin">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Jersey No.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {players.map((p, idx) => {
                            const stat = stats.filter(s => s.uid == p.uid)[0];
                            const isSignedIn = !!stat;
                            const init = isSignedIn ? stat.jerseyNumber : '';
                            return (
                                <tr key={idx}>
                                    <td >
                                        <img 
                                            ref={imgRef}
                                            onClick={() => viewLargeImage(p.profileImageUrl)}
                                            src={p.profileImageUrl} />
                                    </td>
                                    <td>{p.firstName} {p.lastName}</td>
                                    <td>
                                        {isSignedIn && stat.redCard ? <p>Red  <OffenseCard style={{background: 'red'}} /> from previous game.</p>: 
                                            <NumberInput 
                                                id={p.email} 
                                                name="jerseyNumber" 
                                                onChange={(v) => isSignedIn ? handleSignedInChange(p, stat, init, v) : handleNewSignIn(p, v)} 
                                                init={init} />
                                        }
                                    </td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </table>
            
            </>}

        </div>
    )
}

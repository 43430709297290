import { useState, useEffect } from 'react';
import { useAppSettings } from '../../../../../context';
import { convertTimestampToDateTime, IGame, IPlayerGameStat, useAlert } from '../../../../../shared';

export const useTardyTimer = (game: IGame, signedInPlayers: 
    {[teamId: string]: IPlayerGameStat[]}, onTardy: (teamId: string) => void) => {

  const [stop, setStop] = useState(false);
  const { tournament } = useAppSettings();
  const { alertUser } = useAlert();

  // You can check the players on a fly
  // don't need it in the useeffect for all updates
  // the timer will be updates enough
  const isLate = (): string => {
    const teamIds = Object.keys(signedInPlayers);
    const records: {[x:string]: number;} = teamIds.reduce((prev, cur, idx) => {
        (prev as any)[cur] = idx ? signedInPlayers[cur].length : 4;
        return prev;
    }, {})

    const minNoOfPlayers = Math.floor(Number(((tournament as any).gameType as string)[0]) * 0.75);
    const late = teamIds.filter(id => records[id] < minNoOfPlayers)[0];
    const onTime = teamIds.filter(id => records[id] >= minNoOfPlayers)[0];
    if(late && onTime) return onTime;
    return "";
  }

  const handleInterval = (timeInGame: number) => {
    if(timeInGame >= 5 && timeInGame < 6) {
        // 1 goal to the waiting team - 5 minutes of wait
        
        const onTime = isLate();// teamId of the waiting team // No value means they are both late
        if(!!onTime) {
            // if there is a late value and on time value
            // then on time team needs to be get a goal
            onTardy(onTime);
            // console.log('One team gets the points');

        } 
        // else {
        //     console.log('No team gets the points');
        // }
    } else if(timeInGame >= 10 && timeInGame < 11) {
        // forfeit time
        const onTimeTeamId = isLate();
        if(onTimeTeamId) {  
            const teamName = signedInPlayers[onTimeTeamId][0].teamName;
            alertUser(`Please inform ${teamName} that they have the option to either take a forfeit or play for a win.`);
            // alertUser(`Please inform ${teamName} that they have been given 1 goal 
            // and they also have the option to either take a forfeit or play for a win.`);
        }
    }
  }


  useEffect(() => {
    let interval: any;
    
    if(game.startTime && !game.isCancelled && !game.isPlayed && tournament && !stop) {
        interval = setInterval(() => {
            const date = convertTimestampToDateTime(game.startTime);
            const startTime = date.getTime();// milliseconds
            const currentTime = new Date().getTime();// milliseconds
            const timeInGame = Math.floor((currentTime - startTime) / 60000);
            // a minute and 5 secs
            if(timeInGame < 11) {
                handleInterval(timeInGame);
            } else {
                if(interval) clearInterval(interval);
                setStop(true);
            }
        }, 65000)
    }

    return () => { 
        if(interval) clearInterval(interval);
    };
  }, [game, tournament, stop]);
  
};
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSettings, useAuth } from '../../../context';
import { fbStoragePaths, fieldTypes, FilterHoC, IField, IFilter, IPlayer, ShadowCard, useAlert, useFbStorage, usePlayer, WarnButton } from '../../../shared';
import { ModalWithForm } from '../../admin/address/ModalWithForm';
import './Players.scss';

export const Players = () => {
    const {fbUser} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { playerid } = useParams();
    const { searchPlayersAsync, getPendingPlayersAsync, readPlayerAsync } = usePlayer();
    const {registeredTeams = [], tournament} = useAppSettings();
    const [players, setPlayers] = useState<IPlayer[]>([]);
    const [filters, setFilters] = useState<IFilter[]>([
        // {name: 'name', comparer: '=='}
        // {name: 'name_normalized', comparer: ">=", value: searchTerm},
        // {name: 'name_normalized', comparer: "<=", value: searchTerm + "\uf8ff"}
    ]);

    // const { getAllFilesAsync } = useFbStorage();

    const { alertUser } = useAlert();
    // const handleTerms = (e: any) => {
    //     const {name, value} = e;
        
    //     setTerm(value);
    // }

    const handleSubmit = (sv: any, close: any) => {
        if(sv.firstName || sv.lastName) {
            // its searchable
            close();
        } else {
            alertUser('You need at least 2 search criteria. Either provide first name or last name.');
        }
    }



    const searchAsync = async () => {
        const res = await searchPlayersAsync(tournament?.id as string, filters);
        console.log(res);
    }

    const handleNavigate = (player: IPlayer) => {
        navigate(`${player.id}`, {state: {data: player, from: location}});
    }





    const getPendingPlayers = async (tournamentId: string) => {
        return await getPendingPlayersAsync(tournamentId);
    }

    useEffect(() => {
        (async function() {
            if(tournament && !playerid) {
                const res = await getPendingPlayers(tournament.id as string);
                if(res.success) {
                    const allPlayers = res.data as IPlayer[];
                    allPlayers.forEach(p => {
                        const team = registeredTeams.filter(t => t.id === p.teamId)[0];
                        if(team) p.teamName = team.name;
                    })
                    
                    setPlayers(allPlayers);
                }
            }
        }())
    },[tournament, registeredTeams, playerid])

    

    
    useEffect(() => {
        if(filters.length > 0) {
            searchAsync();
        }
    }, [filters])
    
    // const handleSet = async () => {
    //     console.log(fbUser.photoURL)
    //     await getAllFilesAsync(fbStoragePaths.profiles);
    // }
    return (
        <div>
            {/* <button onClick={handleSet}>Set All player image</button> */}
            {playerid ? <Outlet /> : 
               <>
               {/* <div className="search-wrapper" style={{textAlign: 'right'}}>
                    <ModalWithForm title='Player Search' btnLabel='Search' fields={[
                        {name: 'firstName', type: fieldTypes.text},
                        {name: 'lastName', type: fieldTypes.text},
                        {name: 'teamId', label: "Teams", type: fieldTypes.dropdown, dropdown: {
                            dKey: 'name',
                            vKey: 'id',
                            options: registeredTeams
                        }}
                    ] as IField[]} onSubmit={handleSubmit} />
                </div> */}
 
               <FilterHoC data={players}>
                {(data: IPlayer[]) => {
                     return (
                        <>
                            <div className="player-list">
                                <h1>Unpaid Players</h1>
                                {data.map((player, idx) => {
                                    return (
                                        <ShadowCard key={idx} className="player">
                                            <div>
                                            <h3>{player.firstName} {player.lasttName}</h3>
                                            <p> {player.teamName} </p>
                                            </div>
                                            <div>
                                                <WarnButton label='Edit' clicked={() => handleNavigate(player)} />
                                            </div>
                                        </ShadowCard>
                                    )
                                })}
                            </div>
                        </>
                    )
                }}
               </FilterHoC>
               </>
            }
        </div>
    );

}



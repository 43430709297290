import  { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context';
import { DropdownField, fieldTypes, Form, fsPaths, IAddress, IField, IObject, urls, useAlert, useFirestore } from '../../../../shared';
import { AddressFormModal } from '../../address';

import './CreateTournament.scss'

interface IGameType {
    name: string;
    id: string;
}

export const CreateTournament = () => {
const tFields: IField[] = [
    {name: 'name', required: true},
    {name: 'teamFee', required: false, type: fieldTypes.number},
    {name: 'playerFee', required: true, type: fieldTypes.number},
    {name: 'startDate', required: true, type: fieldTypes.datetime},
    {name: 'done', type: fieldTypes.radio, required: true},
    {name: 'inHouse', required: true, type: fieldTypes.radio, label: "Is Leapstep organizing this?"},
    {name: 'gamesPerTeam', required: true, type: fieldTypes.number},
]
const aField: IField = {name: 'defaultParkId', label: 'Address', required: true, type: fieldTypes.dropdown, dropdown: {
    dKey: 'name',
    vKey: 'id',
    options: []
}}
const gField: IField = {name: 'gameTypeId', label: 'Game Type', required: true, type: fieldTypes.dropdown, dropdown: {
    dKey: 'name',
    vKey: 'id',
    options: []
}}
    const navigate = useNavigate();
    const {alertUser} = useAlert();
    const [fields, setFields] = useState<IField[]>(tFields);
    const [otherFieldvalues, setOtherFieldValues] = useState({});
    const [addresses, setAddresses] = useState<IAddress[]>([]);
    const [gameTypes, setGameTypes] = useState<IGameType[]>([]);
    const { readAllAsync, postAsync } = useFirestore();
    const {user} = useAuth();

    const addOtherFields = (x: any) => {
        x['name_normalized'] = x.name.normalize();
        x['createdBy'] = user.preferredName || user.email;
        x['uid'] = user.uid;
        x['isRegistrationClosed'] = false;
        
        const game = gameTypes.find(g => {
            return g.id == (otherFieldvalues as any).gameTypeId;
        });
        
        if(game) x['gameType'] = game['name'];

        const address = addresses.find(a => a.id == (otherFieldvalues as any).defaultParkId);
        if(address) x['defaultParkName'] = address['name'];
        return {...x, ...otherFieldvalues};
    }

    const updateForm = (v: IObject) => {
        setOtherFieldValues(state => ({...state, [v.name]: v.value}));
    }

    const handleSubmit = async (values: any) => {
        const x = addOtherFields(values);
        const res = await postAsync(fsPaths.tournaments, x);
        if(res.success) {
            alertUser('Tournament has been added successfully');
            navigate(urls.admin.tournaments.base);
        }
    }

    const addAddressField = (x: IObject[]) => {
        console.log('just got called with x', x, fields);
        const field = {name: 'defaultParkId', label: 'Address', required: true, type: fieldTypes.dropdown, dropdown: {
            dKey: 'name',
            vKey: 'id',
            options: x
        }};

        setFields(state => ([field, ...state]));
    }
    
    useEffect(() => {
        (async function() {
            const res = await readAllAsync<IAddress>(fsPaths.addresses);
            if(res.success) setAddresses(res.data || []);

            const gRes = await readAllAsync<IGameType>(fsPaths.gameTypes);
            if(gRes) setGameTypes(gRes.data || [])
        }())
    }, [])
    console.log(otherFieldvalues);
 return (
    <div className='create-tournament'>
        <div className="add-address-div">
            <p>Need to add an address?  </p>
            <AddressFormModal btnLabel={'Add New Address'} />
        </div>
        {addresses.length < 1 && <small style={{color: 'red'}}>You need to add an address before you can add tournament.</small>}

        {addresses.length > 0 && <DropdownField field={aField} formName={'a_dropdown'} options={addresses} changeHandler={updateForm} />}
        
        {
            !!((otherFieldvalues as any)['defaultParkId']) && (
                <Form 
                    formName='tournament' 
                    fields={fields} 
                    onSubmit={handleSubmit}>
                        <DropdownField field={gField} formName={'g_dropdown'} options={gameTypes} changeHandler={updateForm} />
                    </Form>
            )
        }

    </div>
 );
}



    // @ServerTimestamp var createdTime: Timestamp?
    // var name: String
    // var name_normalized = ""
    // var teamFee: Int
    // var startDate: Date
    // var done: Bool = false
    // var inHouse: Bool = false
    // var defaultParkId: String?
    // var defaultPark: Address?
    // //MARK: This is guaranteed number of games before any playoff
    // //MARK: I will use this to determine how much subs have to pay
    // var gamesPerTeam: Int = 1
    // var playerFee: Int
    // var subFee: Int = 0
    // var createdBy: String = ""
    
    // var isRegistrationClosed: Bool {
    //     // Offerring 2 weeks(14days) for registration
    //     // After which registration closes
    //     if let createdTime = self.createdTime  {
    //         let closed = Calendar.current.date(byAdding: .day, value: 15, to: createdTime.dateValue())!
    //         return Date.now > closed
    //     }
    //     return false
    // }
    
    
    // private func getSubFee() -> Int {
    //     return Int(ceil(Float(self.playerFee)/Float(self.gamesPerTeam)))
    // }
    
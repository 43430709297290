import React, { useState } from 'react';
import { useAuth } from '../../../context';
import { AwError, Form, FormHeader, IObject, urls, userAccountFields } from '../../../shared';
// import './ChangePasswordForm.scss'

export const ChangePasswordForm = () => {
    const [error, setError] = useState<string>('');
    const { navigate, updatePasswordAsync } = useAuth();

    const handleSubmit = async (vals: IObject) => {
        if(updatePasswordAsync) {
            const res = await updatePasswordAsync(vals.password);
            console.log(res);
            setError(res.message || '');
            if(res.success) {
                navigate(urls.profile.info);
            }
        }
    }
    
    return (
        <div>
            <FormHeader title='Change Password' />
            <AwError error={error} />
            <Form 
                fields={userAccountFields.filter(x => x.name !== 'email')} 
                formName='change-password' 
                onSubmit={handleSubmit}
                settings={{btnLabel: 'Update Password'}}
                 />
        </div>
    );
}
import { where } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppSettings, useAuth } from "../../context";
import { ALink, fsPaths, IPlayer, urls, useLocation, usePlayer } from "../../shared"
import { FourContentLayout } from "../../shared/components/layouts/FourContentLayout"
import { useSingleData } from "../../shared/cus-hook/useLiveData";


export const Player = () => {
    const asidelinks = [
        {route: urls.player.dashboard, name: 'Dashboard'},
        {route: urls.player.teams, name: 'Teams'},
        {route: urls.player.games, name: 'Games'},
        {route: urls.player.scoreboard, name: 'ScoreBoard'},
        {route: urls.player.standings, name: 'Standings'},
        {route: urls.player.leaderboard, name: 'LeaderBoard'}
    ]
    const location = useLocation();
    const [header, setHeader] = useState<string>(asidelinks.filter(a => location.pathname === urls.player.base ? 'Dashboard' : a.route === location.pathname)[0].name);
    // const [player, setPlayer] = useState<IPlayer>(null as any);
    const {tournament, handleLoading} = useAppSettings();
    const {user} = useAuth();
    const { readPlayerAsync } = usePlayer();
       
    const query = useMemo(() => {
        // Uisng the live update because 
        // when a player calls or text that they have made the payment they should see the changes
        // as soon as I do
        if(!tournament) return [];

        return [
            where('uid', '==', `${user.uid}`),
            where('tournamentId', '==', `${tournament?.id}`)
        ];
    }, [tournament])

    const {data: player} = useSingleData<IPlayer>(fsPaths.players, query);

    const filteredLinks = useMemo(() => {
        if(!player || (!player.isPaid && !player.isWaived)) {
            return asidelinks.filter(link => ['dashboard', 'teams', 'games'].includes(link.name.toLowerCase()));
        }
        return asidelinks;
    }, [player])

    const handleClick = (name: string) => {
        setHeader(name);
    }


    // useEffect(() => {
    //    if(tournament) {
    //         (async function() {
    //             // console.log(user.uid);
    //             // console.log(tournament.id);
    //             const res = await readPlayerAsync(tournament.id as string);
    //             // console.log(res);
    //             if(res.success && res.data) {
    //                 // setPlayer(res.data as IPlayer);
    //             }
    //         }())
    //    }
    // }, [player])

    return (
        <FourContentLayout>
            {/* top left content  */}
            <div>Top Left</div>
            {/* top right content  */}
            <h1>{header}</h1>
            {/* links content  */}
            <>
                {filteredLinks.map((item, idx) => {
                    return (
                        <div key={idx} onClick={() => handleClick(item.name)}>
                            {location.pathname === urls.player.base && item.route === urls.player.dashboard ? <ALink  className="active" url={item.route}>{item.name}</ALink>
                            : <ALink  url={item.route}>{item.name}</ALink>}
                        </div>
                    )
                })}
            </>
            {/* <div>Outlet Right</div> */}
            <Outlet context={{ player }} />
        </FourContentLayout>
    )

}
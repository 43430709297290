import { useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Form, FormHeader, IField, ITeam, ITournament, urls, useAlert, useLocation } from '../../../../shared';
// import './PlayerPasscode.scss'

const fields: IField[] = [{
   name: 'passcode'
}];

export const PlayerPasscode = () => {
   const {team, tournament, handlePasscode} = useOutletContext<{team: ITeam, tournament: ITournament, handlePasscode: () => void}>();
   const wrongPasscode = useRef<number>(0);


   const navigate = useNavigate();

   const {alertUser} = useAlert();


   const handler = (v: {passcode: string;}) => {
      const originalCode = team.passcode;
      if(originalCode ===  v.passcode) {
         handlePasscode();
         return navigate(urls.playerregistration.waiver, {state: {data: {team, tournament}}});
      } else {
         wrongPasscode.current += 1;
         const count = 3 - wrongPasscode.current;
         if(count) {
            alertUser(`You only have ${count} attempts left!`)
         } else {
            navigate(urls.home);
         }
      }
   }
   
   return (
      <div>
         <FormHeader title='Team Passcode' />
         <small style={{color: '#A42040'}}>You can ask your coach/team lead if you don't know what the code is!</small>
         <Form fields={fields} formName={'Team Passcode'} onSubmit={handler} />
      </div>
   );
}

import React from 'react';
import { DanButton, useFirestore } from '../../../shared';
// import './DeleteAccount.scss'

export const DeleteAccount = () => {
    const {deleteAllUserRecordsAsync} = useFirestore()
    const handleDeleteAccount = () => {
        
    }
    
    return (
        <div>
            Are you sure you want to delete your account?
            <div>
                <DanButton label={'Delete Account'} clicked={handleDeleteAccount} />
            </div>
        </div>
    );
}
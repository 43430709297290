import './AdminDashboard.scss';



export const AdminDashboard = () => {
    // const { tournament, registeredTeams } = useTeamsRepo();
    // const [games, setGames] = useState<IGame[]>(fakeGames);
    // const [scores, setScores] = useState<IScore[]>(fakeScores);
    // const [arrangedGames, setArrangedGames] = useState<IGame[]>([]);
    // const [liveGames, setLiveGames] = useState<IGame[]>([]);
    // const [games, setGames] = useState<IGame[]>([]);
    
    // const setupcounters = (teams: ITeam[]) => {
    //     const output:IObject = {}
    //     teams.forEach(team => {
    //         output[team.name] = 0;
    //     })
    //     return output;
    // }
 


    // useEffect(() => {
    //     // allPlayAllSetup();
    //     twoGamesFourTeams();
    // }, [registeredTeams])

    // useEffect(() => {
    //     // tournament needs teams - Many to many - TournamentTeams
    //     // team needs players - 
    //     // Tournament need games - IGame should have tournamentId
    //     // 
    //     (async function () {
    //         // group scores according to teamName
    //         let teamNames: string[] = [];
    //         scores.forEach(s => {
    //             if(!teamNames.includes(s.teamName)){
    //                 teamNames.push(s.teamName);
    //             }
    //         })
    //         if(teamNames.length > 0) {
    //             const grouped:any[] = [];
    //             teamNames.forEach(team => {
    //                 grouped.push(scores.filter(s => s.teamName == team).reduce((prev, cur, idx) => {
    //                         return {...prev, 
    //                             goalsScored: prev.goalsScored + cur.goalsScored, 
    //                             goalsConcede: prev.goalsConcede + cur.goalsConcede, 
    //                             points: prev.points + cur.points,
    //                             gamesPlayed: idx + 1
    //                         }
    //                     })
    //                 )
    //             })
    //             // grouped has a cumulative scores
    //             // convert to standings by adding gd
    //             let nStandings: IStanding[] = grouped.map((s: IScore) => {
    //                 return ({...s, goalDifferentials: s.goalsScored - s.goalsConcede } as IStanding)
    //             })

    //             // Sort standings 
    //             nStandings = nStandings.sort((a, b) => {
    //                 return b.points - a.points || ((b.goalDifferentials || 0) - (a.goalDifferentials || 0))
    //             })
    //             setStandings(nStandings);
    //         }

    //     }())
    // }, [])

    // const onSelectAddGame = (g: IGame) => {
    //     console.log(g);
    //     setArrangedGames(state => ([...state, g]));
    // }
    // const clearArrangedGames = () => {
    //     setArrangedGames([]);
    // }
    // const selectClicked = (g: IGame) => {
    //     onSelectAddGame(g);
    // }
    // const handleSubmit = () => {

    // }

    
    // const selectAGame = () => {
    //     setLiveGames(state => ([...state, games[0]]))
    // }
    
    // const adjustScores = (game: any, gameIndex:number, scoreIndex: number, isPos: boolean = true) => {
    //     const nGame = game;
    //     if(!isPos && nGame.scores[scoreIndex].goalsScored || isPos) {
    //         !isPos ? nGame.scores[scoreIndex].goalsScored -= 1 : nGame.scores[scoreIndex].goalsScored += 1;
    //         const nGames = games;
    //         if(scoreIndex) {
    //             // this means the second score is what was changed
    //             // need to adjust the score for the other team

    //             if(nGame.scores[scoreIndex].goalsScored > nGame.scores[scoreIndex - 1].goalsScored) {
    //                 nGame.scores[scoreIndex].points = 3;
    //                 nGame.scores[scoreIndex - 1].points = 0;
    //             } else if(nGame.scores[scoreIndex].goalsScored === nGame.scores[scoreIndex - 1].goalsScored) {
    //                 nGame.scores[scoreIndex].points = 1;
    //                 nGame.scores[scoreIndex - 1].points = 1;
    //             } else {
    //                 nGame.scores[scoreIndex - 1].points = 3;
    //                 nGame.scores[scoreIndex].points = 0;
    //             }
    //             nGame.scores[scoreIndex - 1].goalsConcede += 1;
    //         } else {
    //             if(nGame.scores[scoreIndex].goalsScored < nGame.scores[scoreIndex + 1].goalsScored) {
    //                 nGame.scores[scoreIndex + 1].points = 3;
    //                 nGame.scores[scoreIndex].points = 0;
    //             } else if(nGame.scores[scoreIndex].goalsScored === nGame.scores[scoreIndex + 1].goalsScored) {
    //                 nGame.scores[scoreIndex].points = 1;
    //                 nGame.scores[scoreIndex + 1].points = 1;
    //             } else {
    //                 nGame.scores[scoreIndex].points = 3;
    //                 nGame.scores[scoreIndex + 1].points = 0;
    //             }
    //             !isPos ? nGame.scores[scoreIndex + 1].goalsConcede -= 1 :  nGame.scores[scoreIndex + 1].goalsConcede += 1;
    //         }
    //         nGame.scores = nGame.scores.sort((a:IScore, b:IScore) => b.points - a.points);
    //         nGames[gameIndex] = nGame;
    //         // console.log(nGames);
    //         setGames(state => ([...nGames]));
    //     }
    // }
    return (
        <div>
            <h2>Upcoming Game</h2>
            

        </div>
    )
}




import { forwardRef, useRef } from 'react';
import ReactDOM  from 'react-dom';
import { IObject } from '../../InterfacesOrTypes';
import { Pbutton, SecButton } from '../btns';
import { Close } from '../icons';
import { Modal } from './Modal';
import styles from "./modal.module.scss";
import { IModalSettings, useModalFunctions } from './useModalFunctions';



export interface IAwModal {
    children?: any;
    settings?: IModalSettings;
    title: string;
    btnLabel: string;
    onSubmit: (v: IObject, close: ()=> void) => void;
}
export const AwModal: React.FC<IAwModal> = ({title, btnLabel = "Submit", onSubmit, children}) => {
    const ref = useRef();
    const openModal = () => {
        (ref.current as any).open();
    }


    
    // const [error, setError] = useState('');
    // const [values, setValues] = useState<IObject>({});

    const handleSubmit = async () => {
        // setError('');
        // const results = checkFormRequiredValidity(values, fields);
        // if(results.length > 0) return setError(results[0]);
        // onSubmit(values, () => { setValues({}); return (ref.current as any).close()});
        (ref.current as any).close();
    }
    

    
    return (
        <div>
            <Pbutton clicked={openModal} label={btnLabel} />
            <Modal ref={ref} title={title} onSubmit={handleSubmit}>
                {children}
            </Modal>
        </div>
    )
}

import { insertHTMLPositions } from "../../../utils";
import { FaQuestionCircle } from 'react-icons/fa';

export const useConfirm = () => {
    const root = document.getElementById('content-root');
   

    const getConfirmationAsync =  (msg = 'This change will be made Permanently!') => {
        let popup: any;
        // ${<FaQuestionCircle></FaQuestionCircle>}

        // <label>Are you sure you want to proceed?</label>
        root?.insertAdjacentHTML(insertHTMLPositions.firstChildOfAnEle, `
            <div class='alert-wrapper'>
                <div class='alert confirm'>
                    
                    <h2>${msg}</h2>
                    <label>Click 'Proceed' to continue.</label>
                    <div class="btns">
                        <button class='btn-secondary'>Cancel</button>
                        <button class='btn-danger'>Proceed</button>
                    </div>
                </div>

                <style>
                    .alert-wrapper {
                        position: fixed;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0, 0.3);
                        z-index: 1000;
                    }
                    .alert {
                        user-select: none;
                        min-width: 350px;
                        max-width: 400px;
                        background: #fff;
                        border-radius: 6px;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        padding: 30px 30px;
                        box-shadow: 0 2px 5px rgba(0,0,0, 0.2);


                        z-index: 1000;
                    }
                    .alert img {
                        width: 100px;
                        margin-top: -80px;
                        box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
                        border-radius: 50%;
                    }
                    
                    .alert .btns {
                        display: flex;
                    }
                    .alert button {
                        width: 100%;
                        margin-top: 50px;
                        border: 0;
                        outline: none;
                        color: #fff;
                        // background: #6fd649;
                        padding: 10px 0;
                        cursor: pointer;
                
                        box-shadow: 0 5px 5px rgba(0,0,0, 0.09);
                    }

                    .alert button:first-child {
                        margin-right: 20px;
                        color: black;
                    }
                </style>
            </div>
           
        `)
        popup = root?.firstElementChild;
        const btnsWrapper = popup.querySelector('.btns');
        const cancel = btnsWrapper.firstElementChild;
        const proceed = btnsWrapper.lastElementChild;
        return new Promise((resolve, _) => {
            cancel.addEventListener('click', () => {
                resolve(false);
                popup.remove();
            });
            proceed.addEventListener('click', () => {
                resolve(true);
                popup.remove();
            });
        })
    }
   
    return { getConfirmationAsync }
}
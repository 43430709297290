import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IUserAccount, useAppSettings, useAuth } from '../../../../context';
import { Form, FormHeader, IObject, IPlayer, ISegmentedField, ITeam, ITournament, urls, usePlayer } from '../../../../shared';
import { userFields, userAccountFields, emergencyFields } from '../../../../shared/forms/form-fields';
// import './PlayerPasscode.scss'


export const PlayerInfo = () => {
 const {team, tournament, handleInfo} = useOutletContext<{team: ITeam; tournament: ITournament; handleInfo: (values: IObject, player?: IPlayer) => void}>();
 const navigate = useNavigate();
 const {signUpAsync} = useAuth();
 const {handleLoading} = useAppSettings();
 const {postPlayerAsync} = usePlayer();
 
 const handleSubmit = async (values: IUserAccount) => {
    if(signUpAsync) {
        handleLoading(true);
        const res = await signUpAsync(values);
       if(res.success && res.data) {
            const pRes = await postPlayerAsync(tournament.id || "", team.id || "", res.data);
            if(pRes.success) {
                console.log('signedup user', res);
                handleInfo(values, pRes.data);
            } else {
                handleInfo(values);
            }
            navigate(urls.playerregistration.payment, {state: {data: {team, tournament}}});
       } else {
            console.log(res);
            // signup failed
       }

       handleLoading(false);

    }
 }


//  console.log(team);
//  console.log(tournament);

 return (
    <UserForm onSubmit={handleSubmit} />
 );
}

interface UserFormProps {
    onSubmit: (v: IUserAccount) => void;
}
export const UserForm: React.FC<UserFormProps> = ({onSubmit}) => {
    const segFields: ISegmentedField[] = [
        {header: 'Account',fields: userAccountFields},
        {
            header: 'Personal Info',
            fields: userFields
        },
        {
         header: 'Emergency Contact Info',
         fields: emergencyFields
        }
     ]
     
    return (
        <div>
            <FormHeader title='Player Info' />
            <Form fields={[]} segmentFields={segFields} formName={'player'} onSubmit={onSubmit} /> 
        </div>
    )
}
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ScoreBoard } from '../../../shared';
// import './ScoreBorad.scss'

export const AdminScoreBoard = () => {
    const {gameid} = useParams();
    // if there is no gameId then query for all the scores for the tournament
    return (
        <div>
            <Outlet />
            { !gameid && <ScoreBoard /> }
        </div>
    );
}
import { createUserWithEmailAndPassword, EmailAuthProvider, GoogleAuthProvider, onAuthStateChanged, reauthenticateWithCredential, reauthenticateWithPopup, sendPasswordResetEmail, signInWithCredential, signInWithEmailAndPassword, signInWithPopup, signOut, updateCurrentUser, updateEmail, updatePassword, updateProfile, User, UserCredential, UserInfo } from "firebase/auth";
import { createContext, useEffect, useContext, useRef, useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { auth } from "../../firebase-config";
import { BouncyLoader, fbStoragePaths, fsPaths, IObject, IResponse, urls, useAlert, useFbStorage, useFirestore } from "../shared";
import { useInitialAuthState } from "./useInitialAuthState";

interface IRoles {
    player: string;
    coach: string;
    manager: string;
    admin: string;
    ref: string;
    ar: string;
}
export const userRoles: IRoles = {player: 'player', coach: 'coach', manager: 'manager', admin: 'admin', ref: "referee", ar: "assistant referee"};
export interface IUser {
    uid: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    dob: Date;
    roles: string[];
    isAdmin: boolean;
    createdBy: string;
    waiverSigned: boolean;
    preferredName?: string;
    profileImageUrl?: string;
    waiverSignedDate?: Date;
    isCoach?: boolean;
    defaultPss?: string;
    isAuthenticated?: boolean;
    lastLoggedIn?: Date;
    providerData?: UserInfo[];// just for UI purposes (Not for db)
    refId?: string; // just for UI purpose (Not for db)
    emergencyContactName?: string;
    emergencyContactMobile?: string;
}

export interface IUserUpdateObject {
    userName?: string;
    displayName?: string;
    photoURL?: string;
    phoneNumber?: string;
}

export interface ILoginFields {
    email: string;
    password: string;
}

export interface IUserAccount extends ILoginFields, IUser {

}

interface IAuthSettings {
    user: IUser | null;
}

export interface IAuthContext {
    navigate: NavigateFunction;
    user: IUser;
    updateFirestoreUserAsync: (v: IObject, user?: IUser) => Promise<IResponse<boolean>>;
    rootUrl: string;
    fbUser: User;
    setGoogleCredentials: (uc: UserCredential) => void;
    logoutAsync?: () => void;//() => Promise<void>,
    loginAsync?: (x: ILoginFields, isFirstTime?: boolean) => Promise<IResponse<IUser>>;
    loginWithGoogleAsync?: () => Promise<IResponse<IUser>>;
    registerUserAsync?: (v: IUserAccount) => Promise<IResponse<IUser>>;
    signUpAsync?: (v: IUserAccount) => Promise<IResponse<IUser>>;
    createStoreUserAsync?: (user: IUser) => Promise<IResponse<IUser>>;
    createAuthUserAsync?: (email: string, password: string, isPassword: boolean, signInData: any) => Promise<User>;
    updateEmailAsync?: (email: string) => Promise<IResponse<boolean>>;
    updatePasswordAsync?: (password: string) => Promise<IResponse<boolean>>;
    sendForgotPasswordEmailAsync?: (email: string) => Promise<IResponse<boolean>>;
    updateProfileAsync?: (v: IUserUpdateObject) => Promise<void>;
    // saveAndUpdateUserImageAsync?: (photo: any, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => Promise<IResponse<boolean>>;
    saveAndUpdateUserImageAsync?: (photo: any, setLoading: (v: boolean) => void, oldImagePath?: string) => Promise<IResponse<boolean>>;
    deleteProfileImageAsync?: (url: string) => Promise<IResponse<boolean>>;
}

const AuthContext = createContext<IAuthContext>({
    navigate: () => '',
    user: {isAuthenticated: false} as IUser, 
    updateFirestoreUserAsync: (null as any),
    rootUrl: '',
    fbUser: {} as User,
    setGoogleCredentials: (null as any),
    // logoutAsync: () => Promise<void>, 
    // loginAsync: () => Promise<void>,
    // registerUserAsync: (v: IObject) => Promise<void>,
    // signUpAsync: (v: IObject) => Promise<void>,
    // updateProfileAsync: () => Promise<void>
});

export const AuthProvider = ({...props}) => {
    const {children} = props;

    const { initUser, user, fbUserRef, rootUrl, loading, readUser, setRootUrl, handleSetLoggedInUser } = useInitialAuthState();
    const { navigate, postWithCustomIDAsync, updateDocAsync} = useFirestore();
    const {uploadAsync, deleteFileAsync} = useFbStorage();
    const {alertUser} = useAlert();
    const googleCredRef = useRef<any>();
    
    const registerWithEmailAndPassword = async (email: string, password: string) => {
        return await createUserWithEmailAndPassword(auth, email, password);
    }


    const createAuthUserAsync = async (email: string, password: string, isPassword: boolean, signInData: any) => {
        const uCred: UserCredential = await registerWithEmailAndPassword(email, password);
        // const uCred = {user: {}};
        if(uCred.user) {
            try {
                // Using this signout because
                // I dont want to navigate to sign in page yet
                await signOut(auth);
                await sendPasswordResetEmail(auth, email);
                if(isPassword) {
                    const credentials = EmailAuthProvider.credential(signInData.user.email, signInData.password);
                    await reauthenticateWithCredential(signInData.user, credentials);
                } else {
                    await signInWithCredential(auth, googleCredRef.current);
                }
            } catch (error) {
                // console.log((error as any).message);
                return null as any;
            }
        }
        return uCred.user;
    }

    const createStoreUserAsync = async (user:IUser) => {
       return await postWithCustomIDAsync<IUser>(fsPaths.users, user.uid, user);
    }



    const signUpAsync = async (v: IUserAccount): Promise<IResponse<IUser>> => {
        const output: IResponse<IUser> = {success: false, exists: true};
        try {
            const uCred: UserCredential = await registerWithEmailAndPassword(v.email, v.password);

            const user: IUser = {
                uid: uCred.user.uid,
                firstName: v.firstName,
                lastName: v.lastName,
                emergencyContactName: v.emergencyContactName || "",
                emergencyContactMobile: v.emergencyContactMobile || "",
                preferredName: v.preferredName || "",
                dob: v.dob,
                mobile: v.mobile,
                email: v.email,
                isAdmin: false,
                waiverSigned: true,
                waiverSignedDate: new Date(),
                createdBy: 'self',
                isCoach: false,
                roles: ['player']
            }

            const res = await postWithCustomIDAsync<IUser>(fsPaths.users, user.uid, user);
            output.success = res.success;
            output.data = (res.data as any);
            output.message = res.message;
            return output;
            
        } catch (error) {
            const fbMsg = (error as any).message;
            const msg = fbAuthErrors[fbMsg];
            output.message = msg || fbMsg;
            output.success = false;
            return output;
        }
    }

   
    
    // This runs for FBAuthenticationUser
    const saveAndUpdateUserImageAsync = async (photo: any, setLoading: (v: boolean) => void, currentProfileImgPath?: string): Promise<IResponse<boolean>> => {
        // const saveAndUpdateUserImageAsync = async (photo: any, setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<IResponse<boolean>> => {
        const res: IResponse<boolean> = {success: false, exists: false}
        try {
            const x = await uploadAsync(photo, fbStoragePaths.profiles, fbUserRef.current, setLoading);
            // returns a response of a string if the image is saved in storage
            if(x.success) {
                // get reference to the storage image and save it for retrieval in future
                // await updateProfileAsync({photoURL: x.data}); 
                await updateDocAsync(fsPaths.users, user.uid, {profileImageUrl: x.data});
                // reaching here means it uploaded successfully
                // now delete the old image if any
                if(currentProfileImgPath) {
                    await deleteFileAsync(currentProfileImgPath);
                }
                // setting it here because when the page reload the system will update the store image/ not
                handleSetLoggedInUser({profileImageUrl: x.data});
            }
            res.data = true;
            res.success = true;
            res.exists = true;

            if(!x.success) {
                res.data = false;
                res.success = false;
                res.exists = false;
                res.message = x.message;
            }
        } catch (error) {
            const msg = (error as any).message;
            res.message = msg;
            res.data = false;
        }
        return res;
    }

    const validateIUser = (v: IObject) => {
        delete v.providerData;
        delete v.isAuthenticated;
        delete v.refId;
        return v;
    }
    // This runs for FSUser
    // Whenever FBAuth values change, this func gets called to change
    // FBStore values according
    const updateFirestoreUserAsync = async (v: IObject, user?: IUser) => {
        const uid = !!user ? user.uid : (fbUserRef.current as User).uid;
        const res = await updateDocAsync(fsPaths.users, uid, validateIUser(v));
        
        if(res.success) {
            handleSetLoggedInUser(v);
        }
        return res;
    }

    const updateProfileAsync = async (v: IUserUpdateObject) => {
        const fbUser = fbUserRef.current;
        if(fbUser) await updateProfile(fbUser, {...v});
    }
    
    const sendForgotPasswordEmailAsync = async (email: string): Promise<IResponse<boolean>> => {
        const output: IResponse<boolean> = {success: false, exists: false}
        try {
            await sendPasswordResetEmail(auth, email);
            
            output.data = output.success = output.exists = true;
        } catch (error) {
            const msg = (error as any).message;

            output.message = msg;
        }
        return output;
    }

    const updateEmailAsync = async (email: string): Promise<IResponse<boolean>> => {
        const output: IResponse<boolean> = {success: false, exists: false}
        try {
            const fbUser = (fbUserRef.current as User);
            await updateEmail(fbUser, email);
            
            output.data = output.success = output.exists = true;
        } catch (error) {
            const msg = (error as any).message;
            output.message = msg;
        }
        return output;
    }

    const updatePasswordAsync = async (password: string): Promise<IResponse<boolean>> => {
        const output: IResponse<boolean> = {success: false, exists: false}
        try {
            const fbUser = (fbUserRef.current as User);
            await updatePassword(fbUser, password);
            output.data = output.success = output.exists = true;
        } catch (error) {
            const msg = fbAuthErrors[(error as any).message];
            output.message = msg;
        }
        return output;
    }


    
    const setGoogleCredentials = (uc: UserCredential) => {
        const credentials = GoogleAuthProvider.credentialFromResult(uc);
                
        googleCredRef.current = credentials;
    }
    const funcsWithAuthAsync = async (isGoogle: boolean, method: any, ...props: any[]) => {
        const output: IResponse<IUser> = {success: false, exists: false};
        try {
           
            const res: UserCredential = await method(auth, ...props);
            if(isGoogle) {
                setGoogleCredentials(res);
            }

            const cu = (res.user as User);
            if(cu) {
                // if(!cu.emailVerified) {
                //     (cu as any).emailVerified = true;
                //     await updateCurrentUser(auth, cu);
                // }
                fbUserRef.current = cu;


                const iUser = await readUser(cu);
                
                if(iUser) {
                    await updateDocAsync(
                        fsPaths.users, 
                        iUser.uid, 
                        {lastLoggedIn: new Date()}
                    );
                    output.data = iUser;
                    output.success = true;
                    output.exists = true;
                } else {
                    // no iUser means user needs to be logged out
                    // their account has been deleted by an admin
                    await logoutAsync();
                    output.success = false;
                    output.exists = false;
                }
            } else {
                output.message = 'Log in failed! Please try again later!';
            }
        } catch (error) {
            const fbMsg = (error as any).message;
            const msg = fbAuthErrors[fbMsg];
            output.message = msg || fbMsg;
        }
        return output;
    }

    const loginWithGoogleAsync = async () => {
        const provider = new GoogleAuthProvider();
        return await funcsWithAuthAsync(true, signInWithPopup, provider);
    }



    const loginAsync = async (form: ILoginFields, isFirstTime = false): Promise<IResponse<IUser>> => {
        const res = await funcsWithAuthAsync(false, signInWithEmailAndPassword, form.email, form.password);

        // console.log(fbUserRef.current)
        // if(res.success && isFirstTime) {
        //     console.log(res);
        //     console.log(fbUserRef.current)
        //     const x = fbUserRef.current as any;
        //     x.emailVerified = true;
        //     await updateCurrentUser(auth,  x);
        // }
        return res;
    }

    const logoutAsync = async () => {
        try {
            setRootUrl(urls.home);
            await signOut(auth);
            handleSetLoggedInUser({isAuthenticated: false} as IUser, false);
        } catch (error) {
            alertUser((error as any).message);
        }
    }

    
    
    // if(!user.isAuthenticated) {
    //     throw initAuthPromise;
    // }


    const value = { 
        navigate,
        pageLoading: loading, 
        user,
        updateFirestoreUserAsync,
        rootUrl,
        fbUser: fbUserRef.current as User,
        setGoogleCredentials,
        updateProfileAsync, 
        loginAsync, 
        loginWithGoogleAsync,
        logoutAsync, 
        signUpAsync, 
        createAuthUserAsync,
        createStoreUserAsync,
        updateEmailAsync,
        updatePasswordAsync,
        sendForgotPasswordEmailAsync,
        saveAndUpdateUserImageAsync 
    };
    return (
        <AuthContext.Provider value={value}>
            {loading ? <BouncyLoader /> : <>{children}</>}
        </AuthContext.Provider>
    )
}


export const useAuth = () => {
    return useContext<IAuthContext>(AuthContext)
}

const refreshAuthAsync = async (callback: any) => {
    return new Promise((resolve) => {
        const unsubcribed = onAuthStateChanged(auth, async (currentUser) => {
            if(currentUser) {
                console.log(currentUser);
                callback(currentUser);
                resolve(currentUser);
            }
            resolve(null);
            unsubcribed();
        })
    })
}
const useAuthState = async (callback: any) => await refreshAuthAsync(callback);


const fbAuthErrors:any = {
    "Firebase: Error (auth/requires-recent-login).": "Cannot Change password because you logged in with google.",
    "Firebase: Error (auth/user-not-found).": "Incorrect username or password!",
    "Firebase: Error (auth/wrong-password).": "Incorrect password!",
    "Firebase: Error (auth/email-already-in-use).": "Email is already taken!",
    "Firebase: Password should be at least 6 characters (auth/weak-password).": "Password should be at least 6 characters!",
}



// Thanks! If there's an account associated with this email, we'll send the password reset instructions immediately.
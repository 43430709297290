// Dependencies
import * as React from 'react';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

export const SquarePaymentForm = () => (
  <PaymentForm
    /**
     * Identifies the calling form with a verified application ID generated from
     * the Square Application Dashboard.
     */
    applicationId={process.env.REACT_APP_SQUARE_APPID || ''}
    /**
     * Invoked when payment form receives the result of a tokenize generation
     * request. The result will be a valid credit card or wallet token, or an error.
     */
    cardTokenizeResponseReceived={(token, buyer) => {
        console.log('This is the function that gets called');
        console.info({ token, buyer });
    }}
    /**
     * This function enable the Strong Customer Authentication (SCA) flow
     *
     * We strongly recommend use this function to verify the buyer and reduce
     * the chance of fraudulent transactions.
     */
    createVerificationDetails={() => ({
      amount: '1.00',
      /* collected from the buyer */
      billingContact: {
        addressLines: ['123 Main Street', 'Apartment 1'],
        familyName: 'Doe',
        givenName: 'John',
        countryCode: 'USD',
        city: 'Salt Lake City',
      },
      currencyCode: 'USD',
      intent: 'CHARGE',
    })}
    /**
     * Identifies the location of the merchant that is taking the payment.
     * Obtained from the Square Application Dashboard - Locations tab.
     */
    locationId={process.env.REACT_APP_SQUARE_LOCATIONID || ""}
    
  >
    <CreditCard />
  </PaymentForm>
);

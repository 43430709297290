import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSettings } from '../../../context';
import { ITeam, ITournament, Pbutton, TeamsPresentation, urls, useTeams } from '../../../shared';
import './AdminTeams.scss'

export const AdminTeams = () => {
    const navigate = useNavigate();
    const {tournament, registeredTeams, loading, handleLoading } = useAppSettings();
    const [unRegisteredTeams, setUnRegisteredTeams] = useState<ITeam[]>([]);
    const { toggleJoinTournamentAsync, getUnregisteredTeamsAsync  } = useTeams();
   
    const navigateTo = () => {
        navigate(urls.admin.teams.create, {state: {data: tournament}});
    }

    const joinTournament = async (team: ITeam) => {
        await toggleJoinTournamentAsync(team, (tournament as ITournament), true);
        // 
    }
    const unJoinTournament = async (team: ITeam, index?:number) => {
        await toggleJoinTournamentAsync(team, (tournament as ITournament), false);
    }
    useEffect(() => {
        // (async function() {
        //     const res = await readAllAsync<ITeam>(fsPaths.teams);
        //     if(res.success) setTeams(res.data || []);
        // }())

    },[])

    useEffect(() => {
        (async function() {
            handleLoading(true);
            const urt = await getUnregisteredTeamsAsync(registeredTeams);
            setUnRegisteredTeams(state => urt);
            handleLoading(false);
        }())
    },[registeredTeams])



    return (
        <div className='teams'>
            <div className='pg-header'>
                {/* <h1>Teams</h1> */}
                <div></div>
                <Pbutton label='Create' clicked={navigateTo} />
            </div>
            
            {unRegisteredTeams.length > 0 && !loading && 
                <div>
                    <h2>Unregistered Teams</h2>
                    <TeamsPresentation teams={unRegisteredTeams} showDetails={true} action={{label: 'Join tournament', clicked: joinTournament }} /> 
                </div>
            }
            
            {registeredTeams.length > 0 && 
                <div>
                    <h2>Registered Teams</h2>
                    <TeamsPresentation teams={registeredTeams} showDetails={true} action={{label: 'Remove 4rm tournament', clicked: unJoinTournament }} /> 
                </div>
            }

        </div>
    );
}


import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ALink, urls } from '../../shared';
import { FourContentLayout } from '../../shared/components/layouts';
// import './Admin.scss'

export const Admin = () => {
   const links = [
      {route: urls.admin.dashboard, name: 'Dashboard'},
      {route: urls.admin.teams.base, name: 'Team'},
      {route: urls.admin.players, name: 'Players'},
      {route: urls.admin.games.base, name: 'Games'},
      // {route: urls.admin.games.create, name: 'Games'},
      {route: urls.admin.referees, name: 'Referees'},
      {route: urls.admin.scoremgt, name: 'Score Mgt'},
      {route: urls.admin.scoreboard, name: 'ScoreBoard'},
      {route: urls.admin.standings, name: 'Standings'},
      {route: urls.admin.leaderboard, name: 'LeaderBoard'},
      {route: urls.admin.tournaments.base, name: 'Tournaments'},
      {route: urls.admin.addresses.base, name: 'Addresses'}
   ]
   const location = useLocation();
   const link = links.filter(l => l.route === location.pathname)[0];
   const [header, setHeader] = useState(
      location.pathname === urls.admin.base 
      ? "Dashboard" 
      : link ? link.name : ""
   );
   
   return (
      <FourContentLayout >
         <div>Left Header</div>
         <h1>{header}</h1>
         <>
            {links.map((item, idx) => {
               return (
                  <div key={idx} onClick={() => setHeader(item.name)}>
                     {location.pathname === urls.admin.base && item.route === urls.admin.dashboard 
                     ? <ALink className='active' url={item.route}>{item.name}</ALink>
                     : <ALink url={item.route}>{item.name}</ALink>}
                  </div>
               )
            })}
         </>
      </FourContentLayout>
   );
}







export const AdminLayout = () => {
   return (
      <div className='admin-layout'>
         <Outlet />
      </div>
   )
}
import { Logo } from "../../logo";
import { MobileMenu, urls } from "../../menus";
import { ALink } from "../../components/link";
import "./MobileHeader.scss";
import { ProfileImg } from "../../../pages";
import { useAuth } from "../../../context";

export const MobileHeader = ({}) => {
    const {user, rootUrl} = useAuth();
    return (
        // <PageScroll>
            <div className={`mobile-header bg`}>
                <ALink className="logo-area" url={rootUrl}
                    >
                    <Logo />
                    <h1>Management</h1>
                </ALink>
                <div className="btn-area">
                    <ProfileImg />
                    <MobileMenu />
                </div>
            </div>
    )
}

// import './GameReschedule.scss';

import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notificationTypes, useAppSettings } from "../../../../../context";
import { convertTimestampToDateTimeString, IGame, useGames, useToastNotify, WarnButton } from "../../../../../shared";
import { GamesScheduler } from "../../../../admin/games/new/games-scheduler";

export const GameRescheduling = () => {
    const {getGameByIdAsync, updateGameAsync} = useGames();
    const {handleLoading} = useAppSettings();
    const { handleToaster } = useToastNotify();
    const [game, setGame] = useState<IGame | null>(null);
    const navigate = useNavigate();
    // const {gameid} = useParams();
    const location = useLocation();

    const pGame = useMemo(() => {
        const {data} = (location.state || {}) as {data: IGame;};
        if(data) return data;
        return null;
    }, []);

    // useEffect(() => {
    //     (async function() {
    //         if(gameid && game) {
    //             console.log('useeffect runs before game', game)
    //             // const gRes = await getGameByIdAsync(gameid);
    //             // if(gRes.success) setGame(gRes.data as IGame);
    //         }
    //     }())
    // }, [])

    const handleRescheduling = async (games: IGame[]) => {
        // change date and or venue on the game
        // set cancelled to false
        // add notes to the game notes property
        const orig = convertTimestampToDateTimeString(pGame?.gameDate);
        const newVa = convertTimestampToDateTimeString(games[0].gameDate);
        if(orig !== newVa) {
            // get date and get time string and compare the 
            const nGame = {...games[0]};
            nGame.isCancelled = false;
            let notes = nGame.notes || "";
            notes += `${new Date().toDateString()} - Game rescheduled!`;
            nGame.notes = notes;
            setGame(nGame);
        }
    }

    const handleSubmit = async () => {
        if(game) {
            handleLoading(true);
            const res = await updateGameAsync(game.id as string, game);
            handleLoading(false);
            if(res.success) {
                handleToaster({msg: "The game has been rescheduled successfully!", type: notificationTypes.success});
                navigate(-1);
            } else {
                handleToaster({msg: res.message as string, type: notificationTypes.error});
            }
        }
    }

    return (
        <div>
            <GamesScheduler games={pGame ? [{...pGame}] : []} onScheduleChange={handleRescheduling}>
                <WarnButton label="Submit Changes" clicked={handleSubmit} />
            </GamesScheduler>
        </div>
    );
}
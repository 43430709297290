import { collection, query, where } from 'firebase/firestore';
import { firestore } from '../../../../../firebase-config';
import { IResponse, ITeam, ITournament, ITournamentTeam } from '../../../InterfacesOrTypes';
import { generateRandomNumber } from '../../../utils';
import { fsPaths, useFirestore } from '../../firestore-repos';

export const useTeams = () => {
  const { readAllAsync, readAsync, postAsync, deleteDocAsync, getByQueryAsync, getDocsFromSnapshot } = useFirestore();
  const getTeamsAsync = async () => await readAllAsync<ITeam>(fsPaths.teams);


  const getTournamentTeamByTeamId = async (team: ITeam) => {
      const ttColRef = collection(firestore, fsPaths.tournamentTeams);
      const q = query(ttColRef, where('teamId', '==', (team as any).id));
      return await getByQueryAsync<ITournamentTeam>(q, false);
  }

  const toggleJoinTournamentAsync = async (team: ITeam, tourna: ITournament, isJoin: boolean = true) => {
      if(isJoin) {
          const tteam: ITournamentTeam = {
              teamId: team.id || '', 
              tournamentId: tourna.id || '',
              eliminated: false,
              passcode: (generateRandomNumber() as any).toString(),
              name_normalized: `${tourna.name}${team.name}`.normalize()
          }
          console.log(tteam);
          const tRes = await postAsync(fsPaths.tournamentTeams, tteam);
      } else {
          const res = await getTournamentTeamByTeamId(team);
          if(res.success) {
              await deleteDocAsync(fsPaths.tournamentTeams, (res.data as any).id);
          }
      }
  }

  const getUnregisteredTeamsAsync = async (registeredTeams: ITeam[]) => {
    // getting unregistered teams from registered teams
    if(registeredTeams && registeredTeams.length > 0) {
        const ttIds = registeredTeams.map(tt => tt.id);
        if(ttIds && ttIds.length > 0) {
            const colRef = collection(firestore, fsPaths.teams);
            const q = query(colRef, where('__name__', 'not-in', ttIds))
            const tRes = await getByQueryAsync<ITeam[]>(q);
            if(tRes.success) {
                return (tRes.data as ITeam[]);
            }
        }
    } else {
        // this means no team has registered team
        const teeRes = await getTeamsAsync();
        if(teeRes.success) {
            return (teeRes.data as ITeam[]);
        }
    }
    return [];
}
 


  async function getTeamByIdAsync<ITeam>(id: string): Promise<IResponse<ITeam | null>> {
      return await readAsync<ITeam>(fsPaths.teams, id);
  }


  return {getTeamsAsync, getByQueryAsync, getDocsFromSnapshot, toggleJoinTournamentAsync, getUnregisteredTeamsAsync }
};